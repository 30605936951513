import styled from "@emotion/styled";
import { color } from "metabase/lib/colors";
import { Icon } from "metabase/core/components/Icon";

export const InfoBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InfoIcon = styled(Icon)`
  display: block;
  color: ${color("brand")};
  width: 1.5rem;
  height: 1.5rem;
`;

export const InfoIconContainer = styled.div`
  padding: 1.25rem;
  border-radius: 50%;
  background-color: ${color("brand-light")};
  margin-bottom: 1.5rem;
`;

export const InfoMessage = styled.div`
  color: ${color("text-dark")};
  text-align: center;
  margin-bottom: 1rem;
  color: #4c5773;
  width: 27rem;
  text-align: center;
  word-wrap: break-word;
`;
