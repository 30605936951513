/* eslint-disable react/prop-types */
import { useCallback, useMemo } from "react";
import cx from "classnames";

import ExternalLink from "metabase/core/components/ExternalLink";
import Ellipsified from "metabase/core/components/Ellipsified";
import { formatValue } from "metabase/lib/formatting";
import {
  getTableCellClickedObject,
  getTableClickedObjectRowData,
  isColumnRightAligned,
} from "metabase/visualizations/lib/table";
import { getColumnExtent } from "metabase/visualizations/lib/utils";
import { columnLineBreak } from "metabase/visualizations/visualizations/Table";
import { isID, isFK } from "metabase-lib/types/utils/isa";

import MiniBar from "../MiniBar";
import { CELL_DEFAULT_INDENT } from "../TableInteractive/TableInteractive";
import { CellRoot, CellContent } from "./TableCell.styled";

function getCellData({
  value,
  clicked,
  extraData,
  cols,
  rows,
  columnIndex,
  columnSettings,
}) {
  if (value == null) {
    return "-";
  }
  if (columnSettings["show_mini_bar"]) {
    return (
      <MiniBar
        value={value}
        options={columnSettings}
        extent={getColumnExtent(cols, rows, columnIndex)}
      />
    );
  }
  return formatValue(value, {
    ...columnSettings,
    clicked: { ...clicked, extraData },
    type: "cell",
    jsx: true,
    rich: true,
  });
}

function TableCell({
  value,
  data,
  series,
  settings,
  rowIndex,
  columnIndex,
  isPivoted,
  getCellBackgroundColor,
  getCellTextColor,
  getExtraDataForClick,
  checkIsVisualizationClickable,
  onVisualizationClick,
  lineBreak,
  style,
  cellSetClickedRowHandler,
  clickedRow,
  isTableWithVirtualization,
  isHaveBigCell,
}) {
  const { rows, cols } = data;
  const column = cols[columnIndex];
  const columnSettings = settings.column(column);
  const isCellAutoHeiht = settings["table.cell_auto_height"];

  const cellIndentVertical = settings["table.cell_auto_indent"]
    ? CELL_DEFAULT_INDENT
    : settings["table.cell_indent_vertical"];
  const cellIndentHorizontal = settings["table.cell_auto_indent"]
    ? CELL_DEFAULT_INDENT
    : settings["table.cell_indent_left"];
  const cellFontSize = settings["table.cell_font_size"];

  const isHighlightClickedRow = settings["table.toggle_clicked_row_higlight"];
  const clickedRowColor = settings["table.row_clicked_color"];
  const backgroundColorOnHover = settings["table.cell_background_color_hover"];
  const isTransparent = settings["table.cell_transparent"];

  const cellHorizontalAligment = settings["table.cell_horizontal_alignment"];
  const cellVerticalAligment = settings["table.cell_vertical_alignment"];

  const cellBorderColor = settings["table.grid_color"];

  const clickedRowData = useMemo(
    () =>
      getTableClickedObjectRowData(
        series,
        rowIndex,
        columnIndex,
        isPivoted,
        data,
      ),
    [data, series, rowIndex, columnIndex, isPivoted],
  );

  const clicked = useMemo(
    () =>
      getTableCellClickedObject(
        data,
        settings,
        rowIndex,
        columnIndex,
        isPivoted,
        clickedRowData,
      ),
    [data, settings, rowIndex, columnIndex, isPivoted, clickedRowData],
  );

  const extraData = useMemo(
    () => getExtraDataForClick?.(clicked) ?? {},
    [clicked, getExtraDataForClick],
  );

  const cellData = useMemo(() => {
    const comp = getCellData({
      value,
      clicked,
      extraData,
      cols,
      rows,
      columnIndex,
      columnSettings,
    });
    return lineBreak === columnLineBreak.show ? (
      comp
    ) : (
      <Ellipsified style={{ overflow: "visible", lineHeight: 1 }}>
        {comp}
      </Ellipsified>
    );
  }, [
    value,
    clicked,
    extraData,
    cols,
    rows,
    columnIndex,
    columnSettings,
    lineBreak,
  ]);

  const isLink = cellData && cellData.type === ExternalLink;
  const isClickable = !isLink;

  const onClick = useCallback(
    e => {
      if (checkIsVisualizationClickable(clicked)) {
        onVisualizationClick({
          ...clicked,
          element: e.currentTarget,
          extraData,
        });
      }
    },
    [checkIsVisualizationClickable, clicked, onVisualizationClick, extraData],
  );

  const cellRootOnClickHandler = useCallback(
    e => {
      cellSetClickedRowHandler(rowIndex);
    },
    [cellSetClickedRowHandler, rowIndex],
  );

  const backgroundColor = useMemo(() => {
    const isOddRow = rowIndex % 2 === 0;

    const oddRowsBackgroundColor = settings["table.cell_odd_background_color"];
    const evenRowsBackgroundColor =
      settings["table.cell_even_background_color"];

    const backgroundColorConditional = getCellBackgroundColor?.(
      value,
      rowIndex,
      column.name,
    );

    if (rowIndex === clickedRow && isHighlightClickedRow) {
      return clickedRowColor;
    } else if (backgroundColorConditional) {
      return backgroundColorConditional;
    } else if (isTransparent) {
      return "transparent";
    } else {
      return isOddRow ? oddRowsBackgroundColor : evenRowsBackgroundColor;
    }
  }, [
    rowIndex,
    clickedRow,
    isHighlightClickedRow,
    isTransparent,
    clickedRowColor,
    getCellBackgroundColor,
    value,
    column,
    settings,
  ]);

  const isCellTableId = value != null && isID(column);
  const isCellTableFK = value != null && isFK(column);
  const isCellLink = isClickable && isID(column);

  const textColor = useMemo(() => {
    const linkColor = settings["table.cell_link_color"];
    const cellTextColor = settings["table.cell_text_color"];
    const conditionalTextColor = getCellTextColor?.(
      value,
      rowIndex,
      column.name,
    );

    const isLinkType =
      isCellTableId ||
      isCellTableFK ||
      isCellLink ||
      column.display_name === "Email";

    const settingsTextColor = isLinkType ? linkColor : cellTextColor;

    return conditionalTextColor || settingsTextColor;
  }, [
    settings,
    getCellTextColor,
    value,
    rowIndex,
    column,
    isCellTableId,
    isCellTableFK,
    isCellLink,
  ]);

  const classNames = useMemo(
    () =>
      cx("fullscreen-normal-text fullscreen-night-text", {
        "Table-ID": isCellTableId,
        "Table-FK": isCellTableFK,
        link: isCellLink,
      }),
    [isCellTableId, isCellTableFK, isCellLink],
  );

  const cellContentComponent = useMemo(() => {
    const cellFontStyle = settings["table.cell_font_italic"]
      ? "italic"
      : "normal";
    const cellFontWeight = settings["table.cell_font_bold"] ? "bold" : "normal";
    const fontStyle = {
      fontSize: cellFontSize,
      fontStyle: cellFontStyle,
      fontWeight: cellFontWeight,
    };

    return (
      <CellContent
        className="cellData"
        isClickable={isClickable}
        onClick={isClickable ? onClick : undefined}
        data-testid="cell-data"
        lineBreak={lineBreak}
        isOverflowVisible
        textColor={textColor}
        style={{
          ...fontStyle,
          ...(!isCellTableId ? { paddingRight: "0.08em" } : {}),
          ...style,
          color: textColor,
          height: isCellTableId ? cellFontSize * 1.5 : cellFontSize,
        }}
      >
        {cellData}
      </CellContent>
    );
  }, [
    cellData,
    cellFontSize,
    isCellTableId,
    isClickable,
    lineBreak,
    onClick,
    settings,
    style,
    textColor,
  ]);

  return isTableWithVirtualization ? (
    <CellRoot
      className={classNames}
      backgroundColor={backgroundColor}
      onHoverBackgroundColor={backgroundColorOnHover}
      isRightAligned={isColumnRightAligned(column)}
      lineBreak={lineBreak}
      borderColor={cellBorderColor}
      style={{
        ...style,
        height: "100%",
        display: "flex",
        alignItems: cellVerticalAligment,
        paddingTop: isCellAutoHeiht ? 0 : cellIndentVertical,
        paddingLeft: cellIndentHorizontal,
        justifyContent: cellHorizontalAligment,
      }}
      onClick={cellRootOnClickHandler}
      htmlElementType={"div"}
    >
      {cellContentComponent}
    </CellRoot>
  ) : (
    <CellRoot
      className={classNames}
      backgroundColor={backgroundColor}
      onHoverBackgroundColor={backgroundColorOnHover}
      isRightAligned={isColumnRightAligned(column)}
      lineBreak={lineBreak}
      style={{
        ...style,
      }}
      onClick={cellRootOnClickHandler}
      htmlElementType={"td"}
    >
      <div
        style={{
          display: "flex",
          alignItems: cellVerticalAligment,
          paddingTop: isCellAutoHeiht ? 0 : cellIndentVertical,
          paddingLeft: cellIndentHorizontal,
          height: "100%",
          justifyContent: cellHorizontalAligment,
        }}
      >
        {cellContentComponent}
      </div>
    </CellRoot>
  );
}

export default TableCell;
