import { t } from "ttag";
import { columnsAreValid } from "metabase/visualizations/lib/utils";
import { getOptionFromColumn } from "metabase/visualizations/lib/settings/utils";
import {
  getDefaultColumns,
  getDefaultDimensionLabel,
} from "metabase/visualizations/lib/settings/graph";

import * as DataGrid from "metabase/lib/data_grid";
import ChartSettingsTableFormatting, {
  isFormattable,
} from "metabase/visualizations/components/settings/ChartSettingsTableFormatting";
import { makeCellBackgroundGetter } from "metabase/visualizations/lib/table_format";
import { GRAPH_GOAL_SETTINGS } from "metabase/visualizations/lib/settings/goal";

const uniqueArray = arr =>
  arr.filter(function (item, pos) {
    // eslint-disable-next-line eqeqeq
    return arr.indexOf(item) == pos;
  });

export const ROW_CHART_ADDITIONALLY = {
  "row.show_legend": {
    section: t`Display`,
    title: t`Show legends`,
    widget: "toggle",
    default: true,
  },

  "graph.show_heights_dimensions": {
    section: t`Data`,
    title: t`Show heights_dimensions`,
    widget: "toggle",
    default: false,
    // getHidden: (series, vizSettings) => {
    //   // const { insights } = series[0].data;
    //   console.log('toggle show_heights_dimensions', {series, vizSettings })
    //   // return !insights || insights.length === 0;
    // },
  },

  "graph.heights_dimensions": {
    section: t`Data`,
    title: t`Heights dimensions`, // original looks here as t`Y-axis`,
    widget: "fields",
    getHidden: (_series, vizSettings) =>
      vizSettings["graph.show_heights_dimensions"] !== true,
    isValid: (series, vizSettings) =>
      series.some(
        ({ card, data }) =>
          columnsAreValid(
            card.visualization_settings["graph.dimensions"],
            data,
            vizSettings["graph._dimension_filter"],
          ) &&
          columnsAreValid(
            card.visualization_settings["graph.metrics"],
            data,
            vizSettings["graph._metric_filter"],
          ),
      ),
    getDefault: series => getDefaultColumns(series).metrics,
    persistDefault: true,
    getProps: ([{ card, data }], vizSettings) => {
      const options = data.cols
        .filter(vizSettings["graph._metric_filter"])
        .map(getOptionFromColumn);

      return {
        options,
      };
    },
    dashboard: false,
    useRawSeries: true,
  },

  [DataGrid.COLUMN_FORMATTING_SETTING]: {
    section: t`Conditional Formatting`,
    widget: ChartSettingsTableFormatting,
    default: [],
    getProps: (series, settings) => {
      const [{ data }] = series;
      let uniqSetting = [];

      if (
        data.conditionalFormatting &&
        data?.conditionalFormatting?.length > 0
      ) {
        uniqSetting = uniqueArray(
          data.conditionalFormatting.filter(isFormattable),
        );
      } else {
        uniqSetting = uniqueArray(series[0].data.cols.filter(isFormattable));
      }

      const result = {
        cols: uniqSetting,
        isPivoted: settings["table.pivot"],
      };

      return result;
    },

    getHidden: (
      [
        {
          data: { cols },
        },
      ],
      settings,
    ) => cols.filter(isFormattable).length === 0,
    readDependencies: ["table.pivot"],
  },
  "table._cell_color_getter": {
    getValue(
      [
        {
          data: { rows, cols },
        },
      ],
      settings,
    ) {
      return makeCellBackgroundGetter(
        rows,
        cols,
        settings[DataGrid.COLUMN_FORMATTING_SETTING] ?? [],
        settings["table.pivot"],
      );
    },
    readDependencies: [DataGrid.COLUMN_FORMATTING_SETTING, "table.pivot"],
  },

  "row.chart.label_dimension": {
    section: t`Data`,
    getHidden: (_series, vizSettings) =>
      vizSettings["graph.show_heights_dimensions"] !== true,
    title: t`Label dimension`,
    widget: "select",
    default: null,
    getProps: (series, settings) => {
      const [{ data }] = series;
      let uniqSetting = [];
      const emptyOption = { name: t`Don't show label`, value: null };
      if (
        data.conditionalFormatting &&
        data?.conditionalFormatting?.length > 0
      ) {
        uniqSetting = uniqueArray(
          data.conditionalFormatting.filter(isFormattable),
        );
      } else {
        uniqSetting = uniqueArray(series[0].data.cols.filter(isFormattable));
      }

      const options = uniqSetting.map(({ display_name, name }) => ({
        name: display_name,
        value: name,
      }));

      return { options: [emptyOption, ...options] };
    },
  },
};

export const ROW_CHART_SETTINGS = {
  "stackable.stack_type": {
    section: t`Display`,
    title: t`Stacking`,
    index: 1,
    widget: "radio",
    default: null,
    props: {
      options: [
        { name: t`Don't stack`, value: null },
        { name: t`Stack`, value: "stacked" },
        { name: t`Stack - 100%`, value: "normalized" },
      ],
    },
  },
  ...GRAPH_GOAL_SETTINGS,
  "graph.x_axis.scale": {
    section: t`Axes`,
    group: t`Y-axis`,
    title: t`Scale`,
    index: 4,
    widget: "select",
    default: "ordinal",
    getProps: () => {
      return { options: [{ name: t`Ordinal`, value: "ordinal" }] };
    },
  },
  "graph.y_axis.scale": {
    section: t`Axes`,
    title: t`Scale`,
    index: 7,
    group: t`X-axis`,
    widget: "select",
    default: "linear",
    getProps: () => ({
      options: [
        { name: t`Linear`, value: "linear" },
        { name: t`Power`, value: "pow" },
        { name: t`Log`, value: "log" },
      ],
    }),
  },
  "graph.x_axis.axis_enabled": {
    section: t`Axes`,
    group: t`Y-axis`,
    title: t`Show lines and marks`,
    index: 3,
    widget: "select",
    props: {
      options: [
        { name: t`Hide`, value: false },
        { name: t`Show`, value: true },
      ],
    },
    default: true,
  },
  "graph.y_axis.axis_enabled": {
    section: t`Axes`,
    title: t`Show lines and marks`,
    index: 8,
    group: t`X-axis`,
    widget: "select",
    props: {
      options: [
        { name: t`Hide`, value: false },
        { name: t`Show`, value: true },
      ],
    },
    default: true,
  },
  "graph.y_axis.axis_tick_max_length": {
    section: t`Axes`,
    title: t`Max tick length`,
    index: 9,
    group: t`Y-axis`,
    widget: "number",
    placeholder: "6 - 100",
    isValid: (series, vizSettings) =>
      vizSettings["graph.y_axis.axis_tick_max_length"] >= 6 &&
      vizSettings["graph.y_axis.axis_tick_max_length"] <= 100,
  },
  "graph.y_axis.auto_range": {
    section: t`Axes`,
    group: t`X-axis`,
    index: 4,
    title: t`Auto x-axis range`,
    inline: true,
    widget: "toggle",
    default: true,
  },
  "graph.y_axis.min": {
    section: t`Axes`,
    group: t`X-axis`,
    index: 5,
    title: t`Min`,
    widget: "number",
    default: 0,
    getHidden: (_series, vizSettings) =>
      vizSettings["graph.y_axis.auto_range"] !== false,
  },
  "graph.y_axis.max": {
    section: t`Axes`,
    group: t`X-axis`,
    index: 6,
    title: t`Max`,
    widget: "number",
    default: 100,
    getHidden: (_series, vizSettings) =>
      vizSettings["graph.y_axis.auto_range"] !== false,
  },
  "graph.x_axis.labels_enabled": {
    section: t`Axes`,
    group: t`Y-axis`,
    index: 1,
    title: t`Show label`,
    inline: true,
    widget: "toggle",
    default: true,
  },
  "graph.x_axis.title_text": {
    section: t`Axes`,
    title: t`Label`,
    index: 2,
    group: t`Y-axis`,
    widget: "input",
    getHidden: (series, vizSettings) =>
      vizSettings["graph.x_axis.labels_enabled"] === false,
    getDefault: getDefaultDimensionLabel,
    getProps: series => ({
      placeholder: getDefaultDimensionLabel(series),
    }),
  },
  "graph.y_axis.labels_enabled": {
    section: t`Axes`,
    title: t`Show label`,
    index: 1,
    group: t`X-axis`,
    widget: "toggle",
    inline: true,
    default: true,
  },
  "graph.y_axis.title_text": {
    section: t`Axes`,
    title: t`Label`,
    index: 2,
    group: t`X-axis`,
    widget: "input",
    getHidden: (_series, vizSettings) =>
      vizSettings["graph.y_axis.labels_enabled"] === false,
    getDefault: (series, vizSettings) => {
      // If there are multiple series, we check if the metric names match.
      // If they do, we use that as the default y axis label.
      const [metric] = vizSettings["graph.metrics"];
      const metricNames = Array.from(
        new Set(
          series.map(({ data: { cols } }) => {
            const metricCol = cols.find(c => c.name === metric);
            return metricCol && metricCol.display_name;
          }),
        ),
      );
      return metricNames.length === 1 ? metricNames[0] : null;
    },
    readDependencies: ["series", "graph.metrics"],
  },
  "graph.show_values": {
    section: t`Display`,
    title: t`Show values on data points`,
    widget: "toggle",
    inline: true,
    getHidden: (_series, vizSettings) =>
      vizSettings["stackable.stack_type"] === "normalized",
    default: false,
  },
  "graph.label_value_formatting": {
    section: t`Display`,
    title: t`Value labels formatting`,
    widget: "segmentedControl",
    getHidden: (_series, vizSettings) =>
      vizSettings["graph.show_values"] !== true ||
      vizSettings["stackable.stack_type"] === "normalized",
    props: {
      options: [
        { name: t`Compact`, value: "compact" },
        { name: t`Full`, value: "full" },
      ],
    },
    default: "full",
    readDependencies: ["graph.show_values"],
  },
};
