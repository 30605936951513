import { t } from "ttag";
import { connect } from "react-redux";
import { Dispatch, State } from "metabase-types/store";
import { updateSettings } from "metabase/admin/settings/settings";
import { getSetting } from "metabase/selectors/settings";
import AuthCard, { AuthCardProps } from "../../components/AuthCard";
import { OPENID_SCHEMA } from "../../constants";

type StateProps = Omit<AuthCardProps, "setting" | "onChange" | "onDeactivate">;
type DispatchProps = Pick<AuthCardProps, "onDeactivate">;

const mapStateToProps = (state: State): StateProps => ({
  type: "openid",
  name: t`OpenID Sing-in`,
  title: t`Sing in with OpenID`,
  description: t`Allows users with existing Glarus BI accounts to login with a OpenID account that matches their email address in addition to their Glarus BI username and password.`,
  isConfigured: getSetting(state, "openid-auth-configured"),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  onDeactivate: () => dispatch(updateSettings(OPENID_SCHEMA.getDefault())),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthCard);
