import { t } from "ttag";
import { WidgetSettings } from "metabase-types/api/widgets/widget";

type ScalarSettingsKeys = "scalar.switch_positive_negative";

export type ScalarSettings = WidgetSettings<ScalarSettingsKeys>;

export const SCALAR_SETTINGS: ScalarSettings = {
  "scalar.switch_positive_negative": {
    title: t`Switch positive / negative colors?`,
    widget: "toggle",
    inline: true,
  },
};
