var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var xW,yW,Sla,AW,Tla,Ula,Vla,BW,zW;$CLJS.wW=function(a,b){return $CLJS.Sb(function(c,d){return $CLJS.m(a.g?a.g(d):a.call(null,d))?$CLJS.Id(d):null},null,b)};xW=function(a,b){$CLJS.J(a,0,null);$CLJS.J(a,1,null);$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);b=$CLJS.PH(b,$CLJS.PI)?$CLJS.KV:$CLJS.PH(b,$CLJS.YK)?$CLJS.GV:$CLJS.PH(b,$CLJS.TJ)?$CLJS.oL:null;return $CLJS.m(b)?$CLJS.pV(b,a):!0};
yW=function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);a=$CLJS.fj(function(d){return $CLJS.PH($CLJS.DL(d),$CLJS.mJ)},a);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,!1);b=$CLJS.M.h(b,!0);if($CLJS.ki.h($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.DL($CLJS.C(a));return $CLJS.Xf(function(d){$CLJS.J(d,0,null);$CLJS.J(d,1,null);$CLJS.J(d,2,null);
var e=$CLJS.J(d,3,null);return $CLJS.m(xW(d,c))?null:["Cannot add a ",$CLJS.p.g(e)," interval to a ",$CLJS.p.g(c)," expression"].join("")},b)};
Sla=function(a){return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lj,new $CLJS.k(null,1,[$CLJS.il,[$CLJS.p.g(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.rl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.yL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Bt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,zW],
null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.JL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Bt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,zW],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Jt,new $CLJS.k(null,1,[$CLJS.Yu,function(b){b=$CLJS.Qf(b);b=$CLJS.M.h(b,$CLJS.ol);return["Invalid ",$CLJS.p.g(a)," clause: ",$CLJS.p.g(yW(b))].join("")}],null),$CLJS.$f(yW)],null)],null)};
AW=function(a){var b=$CLJS.T,c=Sla(a);a=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.rl,new $CLJS.k(null,1,[$CLJS.il,[$CLJS.p.g(a)," clause with numeric args"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.yL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bt,new $CLJS.k(null,1,[$CLJS.ok,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null)],null)],
null);return new $CLJS.S(null,3,5,b,[$CLJS.zk,c,a],null)};Tla=function(a){return $CLJS.Ve($CLJS.mV,$CLJS.rg.h(function(b){var c=$CLJS.DL(b),d=$CLJS.PH(c,$CLJS.ML);b=d?$CLJS.pV($CLJS.DV,b):d;return $CLJS.m(b)?$CLJS.Bl:c},a))};Ula=function(a){a=$CLJS.wW(function(b){return!$CLJS.PH(b,$CLJS.mJ)},$CLJS.rg.h($CLJS.DL,a));return $CLJS.PH(a,$CLJS.ML)?$CLJS.Il:a};Vla=function(a){return $CLJS.m($CLJS.Xf(function(b){return $CLJS.PH($CLJS.DL(b),$CLJS.mJ)},a))?Ula(a):Tla(a)};
BW=new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.CW=new $CLJS.N("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);zW=new $CLJS.N("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.kL(new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.HL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.JL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.N(null,"intervals","intervals",2096054013),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.im,zW],
null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Jt,new $CLJS.k(null,1,[$CLJS.il,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=$CLJS.DL(a);return $CLJS.Wf(function(d){return xW(d,c)},b)}],null)],null));
$CLJS.kL(BW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bt,new $CLJS.k(null,1,[$CLJS.ok,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null)],null));$CLJS.uV($CLJS.im,AW($CLJS.im));$CLJS.uV($CLJS.yu,AW($CLJS.yu));$CLJS.AV($CLJS.jm,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hl,BW],null)]));$CLJS.AV($CLJS.QK,$CLJS.G([$CLJS.yu,$CLJS.LK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.hl,BW],null)]));
for(var DW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.im,$CLJS.yu,$CLJS.jm],null)),EW=null,FW=0,GW=0;;)if(GW<FW){var Wla=EW.$(null,GW);$CLJS.nV(Wla,$CLJS.CW);GW+=1}else{var HW=$CLJS.A(DW);if(HW){var IW=HW;if($CLJS.re(IW)){var JW=$CLJS.$c(IW),Xla=$CLJS.ad(IW),Yla=JW,Zla=$CLJS.E(JW);DW=Xla;EW=Yla;FW=Zla}else{var $la=$CLJS.C(IW);$CLJS.nV($la,$CLJS.CW);DW=$CLJS.D(IW);EW=null;FW=0}GW=0}else break}$CLJS.CL.o(null,$CLJS.CW,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);return Vla(a)});
$CLJS.yV($CLJS.uI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null)]));$CLJS.nV($CLJS.uI,$CLJS.PL);
for(var KW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.SK,$CLJS.$H,$CLJS.pJ],null)),LW=null,MW=0,NW=0;;)if(NW<MW){var ama=LW.$(null,NW);$CLJS.yV(ama,$CLJS.G([$CLJS.yu,$CLJS.LK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null)]));NW+=1}else{var OW=$CLJS.A(KW);if(OW){var PW=OW;if($CLJS.re(PW)){var QW=$CLJS.$c(PW),bma=$CLJS.ad(PW),cma=QW,dma=$CLJS.E(QW);KW=bma;LW=cma;MW=dma}else{var ema=$CLJS.C(PW);$CLJS.yV(ema,$CLJS.G([$CLJS.yu,$CLJS.LK,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null)]));KW=$CLJS.D(PW);LW=null;MW=0}NW=0}else break}
for(var RW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kI,$CLJS.HJ,$CLJS.WJ],null)),SW=null,TW=0,UW=0;;)if(UW<TW){var fma=SW.$(null,UW);$CLJS.yV(fma,$CLJS.G([$CLJS.yu,$CLJS.fl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null)]));UW+=1}else{var VW=$CLJS.A(RW);if(VW){var WW=VW;if($CLJS.re(WW)){var XW=$CLJS.$c(WW),gma=$CLJS.ad(WW),hma=XW,ima=$CLJS.E(XW);RW=gma;SW=hma;TW=ima}else{var jma=$CLJS.C(WW);$CLJS.yV(jma,$CLJS.G([$CLJS.yu,$CLJS.fl,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null)]));RW=$CLJS.D(WW);SW=null;TW=0}UW=0}else break}$CLJS.yV($CLJS.nI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null)]));
$CLJS.CL.o(null,$CLJS.nI,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.PH($CLJS.DL(b),$CLJS.fl)&&$CLJS.PH($CLJS.DL(a),$CLJS.fl)?$CLJS.fl:$CLJS.LK});