/* eslint-disable react/prop-types */
import { t, jt } from "ttag";
import cx from "classnames";
import Button from "metabase/core/components/Button";
import { Icon } from "metabase/core/components/Icon";
import ColorRange from "metabase/core/components/ColorRange";
import {
  SortableContainer,
  SortableElement,
} from "metabase/components/sortable";
import { ALL_OPERATOR_NAMES } from "./operators";

const SortableRuleItem = SortableElement(
  ({ rule, options, onEdit, onRemove }) => (
    <RulePreview
      rule={rule}
      options={options}
      onClick={onEdit}
      onRemove={onRemove}
    />
  ),
);

const getValueForDescription = rule =>
  ["is-null", "not-null"].includes(rule.operator) ? "" : ` ${rule.value}`;

const RulePreview = ({ rule, options, onClick, onRemove }) => {
  const { type } = rule;

  const renderColumns = () => {
    return (
      rule.option ?? (
        <span style={{ fontStyle: "oblique" }}>{t`No option selected`}</span>
      )
    );
  };

  return (
    <div
      className="my2 bordered rounded shadowed cursor-pointer bg-white"
      onClick={onClick}
    >
      <div className="p1 border-bottom relative bg-light">
        <div className="px1 flex align-center relative">
          <span className="h4 flex-auto text-dark text-wrap">
            {renderColumns()}
          </span>
          <Icon
            name="close"
            className="cursor-pointer text-light text-medium-hover"
            onClick={e => {
              e.stopPropagation();
              onRemove();
            }}
          />
        </div>
      </div>
      <div className="p2 flex align-center">
        <RuleBackground
          rule={rule}
          className={cx("mr2 flex-no-shrink rounded", {
            bordered: type === "range",
          })}
          style={{ width: 40, height: 40 }}
        />
        <RuleDescription rule={rule} />
      </div>
    </div>
  );
};

const SortableRuleList = SortableContainer(
  ({ rules, options, onEdit, onRemove }) => {
    return (
      <div>
        {rules.map((rule, index) => (
          <SortableRuleItem
            key={`item-${index}`}
            index={index}
            rule={rule}
            options={options}
            onEdit={() => onEdit(index)}
            onRemove={() => onRemove(index)}
          />
        ))}
      </div>
    );
  },
);

export const RuleListing = ({
  rules,
  options,
  onEdit,
  onAdd,
  onRemove,
  onMove,
}) => (
  <div>
    <h3>{t`Conditional formatting`}</h3>
    <div className="mt2">
      {t`You can add rules to make the bubbles in this table change color if they meet certain conditions.`}
    </div>
    <div className="mt2">
      <Button
        borderless
        icon="add"
        onClick={onAdd}
        data-metabase-event="Chart Settings;Scatter Formatting;Add Rule"
      >
        {t`Add a rule`}
      </Button>
    </div>
    {rules.length > 0 && (
      <div className="mt2">
        <h3>{t`Rules will be applied in this order`}</h3>
        <div className="mt2">{t`Click and drag to reorder.`}</div>
        <SortableRuleList
          rules={rules}
          options={options}
          onEdit={onEdit}
          onRemove={onRemove}
          onSortEnd={({ oldIndex, newIndex }) => onMove(oldIndex, newIndex)}
          distance={10}
        />
      </div>
    )}
  </div>
);

const RuleBackground = ({ rule, className, style }) => {
  const { type, colors } = rule;
  return (
    <>
      {type === "range" && (
        <ColorRange colors={colors} className={className} style={style} />
      )}
      {type === "single" && (
        <SinglePreview color={rule.color} className={className} style={style} />
      )}
    </>
  );
};

const SinglePreview = ({ color, className, style }) => (
  <div className={className} style={{ ...style, background: color }} />
);

const RuleDescription = ({ rule }) => {
  const { type, operator } = rule;

  if (type === "range") {
    return (
      <span>{t`Bubbles in this option will be tinted based on their values.`}</span>
    );
  } else if (type === "single") {
    return (
      <span>
        {jt`When a bubble in these columns `}
        <span className="text-bold">
          {ALL_OPERATOR_NAMES[operator]}
          {getValueForDescription(rule)}
        </span>
        {jt` it will be tinted this color.`}
      </span>
    );
  } else {
    return null;
  }
};
