import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { columnLineBreak } from "metabase/visualizations/visualizations/Table";
import { color } from "metabase/lib/colors";

const styles = {
  "word-wrap": "break-word",
  "white-space": "normal",
};

type TLineBreak = "show" | "hidden";
export const getThLineBreakStyles = (lineBreak: TLineBreak) => {
  return lineBreak === "show" ? styles : {};
};

export const CellRoot = styled(
  ({
    htmlElementType,
    ...props
  }: {
    htmlElementType: keyof JSX.IntrinsicElements;
  }) => {
    const Element = htmlElementType || "td";
    return <Element {...props} />;
  },
)<{
  isRightAligned: boolean;
  backgroundColor?: string;
  onHoverBackgroundColor?: string;
  isTransparent?: boolean;
  lineBreak: TLineBreak;
  htmlElementType: string;
  borderColor: string;
}>`
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  color: ${color("text-dark")};
  font-weight: bold;
  text-align: ${props => (props.isRightAligned ? "right" : "unset")};
  //white-space: nowrap;

  white-space: ${props =>
    props.lineBreak === columnLineBreak.show ? "normal" : "nowrap"};
  word-wrap: ${props =>
    props.lineBreak === columnLineBreak.show ? "break-word" : "normal"};
  text-overflow: ${props =>
    props.lineBreak === columnLineBreak.show ? "inherit" : "ellipsis"};
  overflow: ${props =>
    props.lineBreak === columnLineBreak.show ? "inherit" : "hidden"};

  border-bottom: 1px solid ${props => props.borderColor || color("border")};

  background-color: ${props =>
    props.isTransparent ? "transparent" : props.backgroundColor ?? "unset"};

  &:hover {
    background-color: ${props => props.onHoverBackgroundColor};
  }
`;

export const CellContent = styled.span<{
  isClickable: boolean;
  lineBreak: TLineBreak;
  isOverflowVisible: boolean;
  textColor: string;
}>`
  display: inline-block;
  color: ${props => props.textColor || color("text-dark")};
  white-space: ${props =>
    props.lineBreak === columnLineBreak.show ? "normal" : "nowrap"};
  word-wrap: ${props =>
    props.lineBreak === columnLineBreak.show ? "break-word" : "inherit"};
  text-overflow: ${props =>
    props.lineBreak === columnLineBreak.show ? "inherit" : "ellipsis"};
  overflow: ${props => {
    if (props.isOverflowVisible) {
      return "visible";
    }
    return props.lineBreak === columnLineBreak.show ? "inherit" : "hidden";
  }};

  ${props =>
    props.isClickable &&
    css`
      cursor: pointer;
      &:hover {
        color: ${color("brand")};
      }
    `}

  .link {
    color: ${props => props.textColor || color("brand")};
  }
`;
