import { connect } from "react-redux";
import { State } from "metabase-types/store";
import { openNavbar } from "metabase/redux/app";
import FileUpload from "../../components/FileUpload";

const mapStateToProps = (state: State) => ({
  currentUser: state.currentUser,
});

const mapDispatchToProps = {
  onOpenNavbar: openNavbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
