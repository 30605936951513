var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");require("./clojure.set.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./schema.core.js");
'use strict';var QN,xha,TN,UN,VN,WN,YN,ZN,$N,aO,dO,eO,fO,gO,hO,iO,jO,lO,mO,yha,zha,Aha,Bha,Cha,Dha,oO,Eha,Fha,Gha,Hha,qO,rO,sO,tO,Iha,uO,vO,Jha,Kha,Lha,xO,zO,Mha,AO,BO,Nha,Oha,CO,DO,Pha,EO,FO,HO,IO,Qha,JO,Rha,LO,Sha,Tha,MO,OO,Uha,Vha,QO,RO,Wha,Xha,UO,Yha,VO,Zha,YO,ZO,$O,$ha,aia,bia,aP,bP,cP,cia,dia,dP,eia,fia,gia,eP,gP,hP,hia,iia,jia,kia,lia,iP,jP,kP,mia,lP,nia,oia,pia,mP,qia,ria,sia,tia,nP,uia,oP,via,qP,rP,sP,tP,uP,wia,yP,xia,yia,zP,AP,zia,BP,CP,Aia,EP,FP,GP,IP,JP,Bia,KP,Cia,Dia,Eia,LP,MP,NP,QP,
Fia,SP,TP,UP,VP,Gia,Hia,Iia,XP,YP,Jia,ZP,Kia,Lia,Mia,Nia,cQ,Oia,Pia,dQ,eQ,fQ,Qia,gQ,hQ,iQ,Ria,jQ,Sia,Tia,kQ,lQ,mQ,oQ,pQ,Uia,rQ,sQ,tQ,uQ,Via,wQ,xQ,Wia,zQ,AQ,BQ,CQ,DQ,EQ,Xia,Yia,Zia,$ia,aja,GQ,bja,cja,HQ,IQ,JQ,dja,KQ,eja,LQ,fja,gja,MQ,hja,ija,jja,kja,OQ,PQ,lja,mja,nja,SQ,oja,TQ,pja,UQ,qja,WQ,XQ,rja,YQ,ZQ,$Q,aR,bR,sja,cR,tja,dR,uja,vja,eR,fR,gR,wja,xja,hR,yja,zja,iR,Aja,Bja,Cja,Dja,lR,Eja,Fja,Gja,Hja,Ija,Jja,Kja,pR,qR,rR,sR,tR,Lja,uR,vR,wR,xR,Mja,Nja,Oja,Pja,yR,zR,AR,Qja,BR,CR,Rja,Sja,DR,Tja,Uja,ER,
Vja,GR,HR,Wja,IR,Xja,KR,LR,Yja,MR,NR,Zja,OR,$ja,PR,aka,RR,SR,TR,UR,bka,VR,cka,WR,dka,YR,ZR,aS,eka,fka,gka,cS,dS,hka,eS,ika,jka,kka,fS,lka,gS,mka,iS,jS,nka,oka,nS,oS,pka,pS,qS,rS,sS,qka,rka;QN=function(a,b){if(null!=a&&null!=a.Td)a=a.Td(a,b);else{var c=QN[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=QN._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("CoreSpec.checker",a);}return a};
xha=function(a,b){var c=new $CLJS.k(null,3,[$CLJS.nM,a,$CLJS.qM,!1,$CLJS.oM,$CLJS.cj.g($CLJS.P)],null);return a.h?a.h(b,c):a.call(null,b,c)};$CLJS.RN=function(a,b){return $CLJS.Sb(function(c,d){var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.Ie(a,e)?$CLJS.U.j(c,d,$CLJS.M.h(a,e)):c},$CLJS.R.j($CLJS.Am,a,$CLJS.gi(b)),b)};$CLJS.SN=function(a,b){return new $CLJS.iN(a,!1,b,null,null,null)};TN=function(a){return new $CLJS.$M(a,null,null,null)};
UN=function(a,b,c,d){this.Nc=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};VN=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return VN.l(0<b.length?new $CLJS.z(b.slice(0),0,null):null)};WN=function(a,b,c,d,e){this.kc=a;this.pc=b;this.F=c;this.m=d;this.G=e;this.C=2230716170;this.K=139264};YN=function(a,b,c){return XN.l($CLJS.G([a,b,$CLJS.ag(!0),c]))};ZN=function(a,b,c,d){this.zc=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};
$N=function(a){if(!(null!=a?a.C&32768||$CLJS.t===a.Zf||(a.C?0:$CLJS.Nb($CLJS.Kz,a)):$CLJS.Nb($CLJS.Kz,a)))throw Error($CLJS.WL("Not an IDeref: %s",$CLJS.G([a])));return new ZN(a,null,null,null)};aO=function(a,b,c,d){this.X=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};$CLJS.bO=function(a){return new aO(a,null,null,null)};$CLJS.cO=function(a){return $CLJS.Im.h($CLJS.jM,xha(function(b,c){return QN($CLJS.OM(b),c)},a))};
dO=function(a,b){if($CLJS.qe(b)){var c=$CLJS.J(b,0,null),d=$CLJS.J(b,1,null);switch(c instanceof $CLJS.N?c.S:null){case "optional":return new $CLJS.iN($CLJS.bO(d),!0,a,null,null,null);case "rest":return $CLJS.WM(d,a);default:return $CLJS.SN(b,a)}}else return $CLJS.SN(b,a)};
eO=function(a,b){return $CLJS.Mg($CLJS.nf($CLJS.SN($CLJS.SM(a),a),function(){return function e(d){return new $CLJS.yf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);v=dO(v,u);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.nf(dO(n,g),
e($CLJS.zd(f)))}return null}},null,null)}($CLJS.wu(2,2,b))}()))};fO=function(a,b){var c=$CLJS.ne(b);return c?(c=$CLJS.C(b)instanceof $CLJS.N)?$CLJS.ke(a)?(b=$CLJS.C(b),a=$CLJS.si(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,$CLJS.C(b)):c:c};
gO=function(a){return $CLJS.WM($CLJS.R.h(XN,$CLJS.Ve($CLJS.qg,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l.add(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bg(fO,u),q],null));n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,
1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bg(fO,l),f],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())),["Must be a valid instance of one of these clauses: ",$CLJS.dv.h(", ",$CLJS.rg.h($CLJS.C,a))].join(""))};hO=function(a){return $CLJS.YM(a,$CLJS.A,"Non-empty")};iO=function(a){return $CLJS.A(a)?$CLJS.R.h($CLJS.Ru,a):!0};jO=function(a){return"string"===typeof a?$CLJS.ki.h(Date.parse(a),NaN):null};
$CLJS.sM.prototype.Td=$CLJS.Ma(56,function(){var a=this;return function(b){var c=a.na.g?a.na.g(b):a.na.call(null,b);return $CLJS.m(c)?c:b}});
$CLJS.xM.prototype.Td=$CLJS.Ma(55,function(a,b){var c=this,d=this,e=$CLJS.Sb(function(f,g){return $CLJS.Hga(g,b,f)},function(f){return $CLJS.hM($CLJS.cM(d,f,new $CLJS.Yi(function(){var g=$CLJS.YL(f);return c.bc.g?c.bc.g(g):c.bc.call(null,g)}),null))},$CLJS.lf(c.options));return $CLJS.m(c.Ub)?function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);if($CLJS.m(g))return g;g=e.g?e.g(f):e.call(null,f);if($CLJS.iM(g))return g;f=$CLJS.m($CLJS.qM.g(b))?g:f;f=c.Ub.g?c.Ub.g(f):c.Ub.call(null,f);return $CLJS.m(f)?
f:g}:function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);return $CLJS.m(g)?g:e.g?e.g(f):e.call(null,f)}});
$CLJS.FM.prototype.Td=$CLJS.Ma(54,function(a,b){var c=this,d=$CLJS.m($CLJS.qM.g(b))?c.sc:function(){return null},e=$CLJS.DM(c.elements,b,function(f,g){return g});return function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);if($CLJS.m(g))return g;var l=$CLJS.cj.g($CLJS.Lg);g=e.h?e.h(l,f):e.call(null,l,f);l=$CLJS.r(l);var n=(n=$CLJS.A(g))?n:$CLJS.Xf($CLJS.iM,l);return $CLJS.m(n)?$CLJS.hM(c.dc.j?c.dc.j(f,l,g):c.dc.call(null,f,l,g)):d.g?d.g(l):d.call(null,l)}});$CLJS.kO={};lO={};mO={};$CLJS.h=UN.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "vs":return this.Nc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.EnumSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rN,this.Nc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.rN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=2002701468^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Nc,b.Nc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.rN,null],null),null),b)?$CLJS.Am.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new UN(this.Nc,this.F,$CLJS.Rf($CLJS.Am.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "vs":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.rN,b):$CLJS.O.call(null,$CLJS.rN,b))?new UN(c,this.F,this.m,null):new UN(this.Nc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.rN,this.Nc)],null),this.m))};$CLJS.h.O=function(a,b){return new UN(this.Nc,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){var a=this;return $CLJS.tM($CLJS.lM(this,function(b){return $CLJS.Ie(a.Nc,b)},function(b){return new $CLJS.gf(null,a.Nc,new $CLJS.gf(null,b,null,1,null),2,null)}))};$CLJS.h.Fa=function(){return $CLJS.nf($CLJS.qha,this.Nc)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};VN.l=function(a){return new UN($CLJS.si(a),null,null,null)};VN.v=0;
VN.B=function(a){return this.l($CLJS.A(a))};$CLJS.h=WN.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "preds-and-schemas":return this.kc;case "error-symbol":return this.pc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.ConditionalSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tN,this.kc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sN,this.pc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,2,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tN,$CLJS.sN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};
$CLJS.h.ea=function(){return 2+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1418435858^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.kc,b.kc)&&$CLJS.F.h(this.pc,b.pc)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.tN,null,$CLJS.sN,null],null),null),b)?$CLJS.Am.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new WN(this.kc,this.pc,this.F,$CLJS.Rf($CLJS.Am.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "preds-and-schemas":case "error-symbol":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.tN,b):$CLJS.O.call(null,$CLJS.tN,b))?new WN(c,this.pc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.sN,b):$CLJS.O.call(null,$CLJS.sN,b))?new WN(this.kc,c,this.F,this.m,null):new WN(this.kc,this.pc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.Je($CLJS.tN,this.kc),new $CLJS.Je($CLJS.sN,this.pc)],null),this.m))};
$CLJS.h.O=function(a,b){return new WN(this.kc,this.pc,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
$CLJS.h.Db=function(){var a=this;return $CLJS.yM($CLJS.NM,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l.add(new $CLJS.k(null,2,[$CLJS.uM,u,$CLJS.$l,q],null));n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,
null);return $CLJS.nf(new $CLJS.k(null,2,[$CLJS.uM,l,$CLJS.$l,f],null),d($CLJS.zd(e)))}return null}},null,null)}(a.kc)}(),function(b){var c=a.pc;c=$CLJS.m(c)?c:$CLJS.F.h(1,$CLJS.E(a.kc))?$CLJS.Ui.g($CLJS.ZL($CLJS.C($CLJS.C(a.kc)))):$CLJS.Tga;return new $CLJS.gf(null,c,new $CLJS.gf(null,b,null,1,null),2,null)})};
$CLJS.h.Fa=function(){return $CLJS.nf($CLJS.Uga,$CLJS.qg.h($CLJS.sg(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ui.g($CLJS.ZL(b)),$CLJS.PM(a)],null)},$CLJS.G([this.kc])),$CLJS.m(this.pc)?new $CLJS.S(null,1,5,$CLJS.T,[this.pc],null):null))};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};
var XN=function XN(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return XN.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)};
XN.l=function(a){if(!$CLJS.A(a)||!($CLJS.Zf($CLJS.E(a))||$CLJS.$d(a)instanceof $CLJS.w))throw Error($CLJS.WL("Expected even, nonzero number of args (with optional trailing symbol); got %s",$CLJS.G([$CLJS.E(a)])));return new WN($CLJS.Mg(function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null),v=$CLJS.J(q,1,null);q=l;if(!$CLJS.ze(u))throw Error($CLJS.XL(["Conditional predicate ",
$CLJS.p.g(u)," must be a function"].join("")));u=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.F.h(u,$CLJS.Uu)?$CLJS.ag(!0):u,v],null);q.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);if(!$CLJS.ze(l))throw Error($CLJS.XL(["Conditional predicate ",$CLJS.p.g(l)," must be a function"].join("")));l=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.F.h(l,$CLJS.Uu)?$CLJS.ag(!0):l,f],null);return $CLJS.nf(l,d($CLJS.zd(e)))}return null}},
null,null)}($CLJS.wu(2,2,a))}()),$CLJS.Zf($CLJS.E(a))?null:$CLJS.$d(a),null,null,null)};XN.v=0;XN.B=function(a){return this.l($CLJS.A(a))};$CLJS.h=ZN.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "derefable":return this.zc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.Recursive{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vN,this.zc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.vN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1521498755^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.zc,b.zc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.vN,null],null),null),b)?$CLJS.Am.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new ZN(this.zc,this.F,$CLJS.Rf($CLJS.Am.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "derefable":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.vN,b):$CLJS.O.call(null,$CLJS.vN,b))?new ZN(c,this.F,this.m,null):new ZN(this.zc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.vN,this.zc)],null),this.m))};$CLJS.h.O=function(a,b){return new ZN(this.zc,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){return $CLJS.zM($CLJS.NM,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,1,[$CLJS.$l,$CLJS.r(this.zc)],null)],null))};
$CLJS.h.Fa=function(){if(this.zc instanceof $CLJS.ud){var a=$CLJS.fe(this.zc);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.Bk);b=$CLJS.M.h(b,$CLJS.V);a=$CLJS.Ui.g([$CLJS.p.g(a),"/",$CLJS.p.g(b)].join(""));a=new $CLJS.gf(null,$CLJS.Gl,new $CLJS.gf(null,a,null,1,null),2,null)}else a=$CLJS.rha;return new $CLJS.gf(null,$CLJS.hha,new $CLJS.gf(null,a,null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};$CLJS.h=aO.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "schema":return this.X;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.Maybe{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,this.X],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.$l],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-805411239^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.X,b.X)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.$l,null],null),null),b)?$CLJS.Am.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new aO(this.X,this.F,$CLJS.Rf($CLJS.Am.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "schema":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.$l,b):$CLJS.O.call(null,$CLJS.$l,b))?new aO(c,this.F,this.m,null):new aO(this.X,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.$l,this.X)],null),this.m))};$CLJS.h.O=function(a,b){return new aO(this.X,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){return $CLJS.zM($CLJS.NM,new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.uM,$CLJS.Cb,$CLJS.$l,$CLJS.SM(null)],null),new $CLJS.k(null,1,[$CLJS.$l,this.X],null)],null))};$CLJS.h.Fa=function(){return new $CLJS.gf(null,$CLJS.iha,new $CLJS.gf(null,$CLJS.PM(this.X),null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};
yha=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);zha=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);$CLJS.nO=new $CLJS.N(null,"right-join","right-join",-56349359);Aha=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);Bha=new $CLJS.N(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);
Cha=new $CLJS.N(null,"query-hash","query-hash",-1524484965);Dha=new $CLJS.N(null,"from","from",1815293044);oO=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);Eha=new $CLJS.N(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);Fha=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Gha=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);
$CLJS.pO=new $CLJS.N(null,"aggregation-options","aggregation-options",-1904917550);Hha=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);qO=new $CLJS.N("location","zip_code","location/zip_code",1641155222);rO=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);sO=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);tO=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);Iha=new $CLJS.N(null,"lon-max","lon-max",1590224717);
uO=new $CLJS.w(null,"stddev","stddev",775056588,null);vO=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.wO=new $CLJS.N(null,"query","query",-1288509510);Jha=new $CLJS.N(null,"lat-field","lat-field",-830652957);Kha=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);Lha=new $CLJS.w(null,"FieldOptions","FieldOptions",-1536320891,null);xO=new $CLJS.N("location","country","location/country",1666636202);
$CLJS.yO=new $CLJS.N(null,"fields","fields",-1932066230);zO=new $CLJS.w("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",-1908264889,null);Mha=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);AO=new $CLJS.N(null,"unary","unary",-989314568);BO=new $CLJS.w(null,"ceil","ceil",-184398425,null);Nha=new $CLJS.N(null,"lon-min","lon-min",-787291357);Oha=new $CLJS.N(null,"match","match",1220059550);
CO=new $CLJS.w(null,"count-where","count-where",2025939247,null);DO=new $CLJS.N(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Pha=new $CLJS.N(null,"embedded-question","embedded-question",-2146473954);EO=new $CLJS.w(null,"sum","sum",1777518341,null);FO=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);$CLJS.GO=new $CLJS.N("date","range","date/range",1647265776);HO=new $CLJS.w(null,"between","between",-1523336493,null);
IO=new $CLJS.N(null,"clause-form","clause-form",1820463737);Qha=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);JO=new $CLJS.w(null,"field","field",338095027,null);Rha=new $CLJS.N(null,"segment-id","segment-id",1810133590);$CLJS.KO=new $CLJS.N(null,"filter","filter",-948537934);LO=new $CLJS.w(null,"not-null","not-null",313812992,null);Sha=new $CLJS.N(null,"template-tag","template-tag",310841038);
Tha=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);MO=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.NO=new $CLJS.N(null,"context","context",-830191113);OO=new $CLJS.w(null,"get-year","get-year",704520253,null);Uha=new $CLJS.N(null,"format-rows?","format-rows?",992129486);$CLJS.PO=new $CLJS.N(null,"parameters","parameters",-1229919748);Vha=new $CLJS.N(null,"json-download","json-download",-971130133);
QO=new $CLJS.N(null,"string-or-field","string-or-field",-1970678542);RO=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);Wha=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Xha=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);$CLJS.SO=new $CLJS.w(null,"expression","expression",1842843403,null);$CLJS.TO=new $CLJS.N("date","month-year","date/month-year",1948031290);
UO=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);Yha=new $CLJS.w("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-469303836,null);VO=new $CLJS.w("s","recursive","s/recursive",-1935549792,null);$CLJS.WO=new $CLJS.N(null,"joins","joins",1033962699);Zha=new $CLJS.N(null,"slug","slug",2029314850);$CLJS.XO=new $CLJS.N(null,"source-field","source-field",933829534);YO=new $CLJS.w(null,"Field","Field",430385967,null);
ZO=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);$O=new $CLJS.w("helpers","IntGreaterThanZero","helpers/IntGreaterThanZero",1743901629,null);$ha=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);aia=new $CLJS.N(null,"disable-max-results?","disable-max-results?",857693204);bia=new $CLJS.N(null,"items","items",1031954938);aP=new $CLJS.N(null,"more","more",-2058821800);
bP=new $CLJS.N(null,"first-clause","first-clause",-20953491);cP=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);cia=new $CLJS.w("metabase.mbql.schema","NumericExpression*","metabase.mbql.schema/NumericExpression*",-254856038,null);dia=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);dP=new $CLJS.w(null,"contains","contains",-1977535957,null);eia=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);
fia=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);gia=new $CLJS.w(null,"BooleanExpression","BooleanExpression",1027887421,null);eP=new $CLJS.N("number","\x3d","number/\x3d",-2094581309);$CLJS.fP=new $CLJS.N(null,"fingerprint","fingerprint",598613022);gP=new $CLJS.w(null,"is-null","is-null",-356519403,null);hP=new $CLJS.N(null,"other-clauses","other-clauses",1570511021);hia=new $CLJS.N(null,"map-tiles","map-tiles",1961865797);
iia=new $CLJS.N(null,"required","required",1807647006);jia=new $CLJS.w(null,"Filter*","Filter*",-1193205185,null);kia=new $CLJS.N(null,"datetime-x","datetime-x",1519265947);lia=new $CLJS.N(null,"datetime-y","datetime-y",-1666955771);iP=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);jP=new $CLJS.N(null,"date-arithmetics","date-arithmetics",-1832808309);kP=new $CLJS.N("string","contains","string/contains",1602423827);
mia=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);lP=new $CLJS.N("string","!\x3d","string/!\x3d",-1083772573);nia=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);oia=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);pia=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);
mP=new $CLJS.w(null,"share","share",1051097594,null);qia=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);ria=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);sia=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);tia=new $CLJS.N(null,"xlsx-download","xlsx-download",-1622892009);
nP=new $CLJS.w(null,"FieldOrAggregationReference","FieldOrAggregationReference",85477856,null);uia=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);oP=new $CLJS.N(null,"collection","collection",-683361892);$CLJS.pP=new $CLJS.N("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);via=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);qP=new $CLJS.N("number","\x3c\x3d","number/\x3c\x3d",-1499316353);
rP=new $CLJS.w(null,"Aggregation","Aggregation",-955933538,null);sP=new $CLJS.w(null,"metric","metric",2049329604,null);tP=new $CLJS.w(null,"concat","concat",-467652465,null);uP=new $CLJS.N(null,"variable","variable",-281346492);$CLJS.vP=new $CLJS.N("date","quarter-year","date/quarter-year",-1453950150);wia=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.wP=new $CLJS.N("date","relative","date/relative",25987732);$CLJS.xP=new $CLJS.N(null,"alias","alias",-2039751630);
yP=new $CLJS.N("location","city","location/city",-1746973325);xia=new $CLJS.N(null,"public-dashboard","public-dashboard",645968405);yia=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);zP=new $CLJS.N("number","\x3e\x3d","number/\x3e\x3d",-1670691032);AP=new $CLJS.N("number","between","number/between",97700581);zia=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);BP=new $CLJS.w(null,"sqrt","sqrt",370479598,null);
CP=new $CLJS.w("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",-1440012226,null);$CLJS.DP=new $CLJS.N(null,"semantic_type","semantic_type",272485089);Aia=new $CLJS.N(null,"metric-id","metric-id",-686486942);EP=new $CLJS.w(null,"*","*",345799209,null);FP=new $CLJS.w(null,"+","+",-740910886,null);GP=new $CLJS.w(null,"-","-",-471816912,null);$CLJS.HP=new $CLJS.N(null,"breakout","breakout",-732419050);IP=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);
JP=new $CLJS.N(null,"allowed-for","allowed-for",122724334);Bia=new $CLJS.N(null,"question","question",-1411720117);KP=new $CLJS.w(null,"asc","asc",1997386096,null);Cia=new $CLJS.w("metabase.mbql.schema","DatetimeExpression*","metabase.mbql.schema/DatetimeExpression*",-1766428848,null);Dia=new $CLJS.N(null,"pulse-id","pulse-id",1331432237);Eia=new $CLJS.w("metabase.mbql.schema","BooleanExpression*","metabase.mbql.schema/BooleanExpression*",-630544184,null);
LP=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);MP=new $CLJS.w(null,"\x3c","\x3c",993667236,null);NP=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);$CLJS.OP=new $CLJS.N(null,"aggregation","aggregation",1597476696);$CLJS.PP=new $CLJS.N(null,"source-metadata","source-metadata",-477816085);QP=new $CLJS.N("string","ends-with","string/ends-with",302681156);Fia=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);
$CLJS.RP=new $CLJS.w("s","Str","s/Str",907974338,null);SP=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);TP=new $CLJS.w(null,"and","and",668631710,null);UP=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);VP=new $CLJS.w(null,"round","round",-645002441,null);Gia=new $CLJS.N(null,"to","to",192099007);$CLJS.WP=new $CLJS.N("date","single","date/single",1554682003);Hia=new $CLJS.w(null,"DatetimeExpression*","DatetimeExpression*",1609939947,null);
Iia=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);XP=new $CLJS.w(null,"exp","exp",1378825265,null);YP=new $CLJS.w(null,"Filter","Filter",-424893332,null);Jia=new $CLJS.w("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",1964355302,null);ZP=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);Kia=new $CLJS.N(null,"dashboard-id","dashboard-id",1965414288);
Lia=new $CLJS.w(null,"BooleanExpression*","BooleanExpression*",623370113,null);$CLJS.$P=new $CLJS.N(null,"source-table","source-table",-225307692);Mia=new $CLJS.N(null,"embedded-dashboard","embedded-dashboard",-485078014);$CLJS.aQ=new $CLJS.N("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);Nia=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);$CLJS.bQ=new $CLJS.N(null,"metric","metric",408798077);
cQ=new $CLJS.w(null,"floor","floor",-772394748,null);Oia=new $CLJS.N(null,"middleware","middleware",1462115504);Pia=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);dQ=new $CLJS.N(null,"requires-features","requires-features",-101116256);eQ=new $CLJS.N(null,"clause-name","clause-name",-996419059);fQ=new $CLJS.w(null,"now","now",-9994004,null);Qia=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);
gQ=new $CLJS.w(null,"avg","avg",1837937727,null);hQ=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);iQ=new $CLJS.N(null,"max-results","max-results",-32858165);Ria=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);jQ=new $CLJS.w(null,"case","case",-1510733573,null);Sia=new $CLJS.w("js","Date","js/Date",946858373,null);Tia=new $CLJS.w(null,"StringExpression*","StringExpression*",1110382599,null);
kQ=new $CLJS.w(null,"distinct","distinct",-148347594,null);lQ=new $CLJS.w(null,"get-second","get-second",-425414791,null);mQ=new $CLJS.w(null,"DatetimeExpression","DatetimeExpression",1944354145,null);$CLJS.nQ=new $CLJS.N(null,"join-alias","join-alias",1454206794);oQ=new $CLJS.w(null,"is-empty","is-empty",600228619,null);pQ=new $CLJS.w(null,"StringExpression","StringExpression",-233088561,null);Uia=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
$CLJS.qQ=new $CLJS.w("s","pred","s/pred",-727014287,null);rQ=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);sQ=new $CLJS.N("number","!\x3d","number/!\x3d",-673025509);tQ=new $CLJS.w(null,"abs","abs",1394505050,null);uQ=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);Via=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);$CLJS.vQ=new $CLJS.w("s","cond-pre","s/cond-pre",-923707600,null);
wQ=new $CLJS.N(null,"date","date",-1463434462);xQ=new $CLJS.N(null,"second-clause","second-clause",-461435645);$CLJS.yQ=new $CLJS.N(null,"left-join","left-join",-672831855);Wia=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);zQ=new $CLJS.w(null,"rtrim","rtrim",979195078,null);AQ=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);BQ=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);
CQ=new $CLJS.w("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",-1444327113,null);DQ=new $CLJS.w(null,"or","or",1876275696,null);EQ=new $CLJS.w("s","Int","s/Int",-2116888657,null);$CLJS.FQ=new $CLJS.N(null,"field","field",-1302436500);Xia=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Yia=new $CLJS.w(null,"TemporalExtractUnits","TemporalExtractUnits",-1308527018,null);Zia=new $CLJS.N(null,"constraints","constraints",422775616);
$ia=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);aja=new $CLJS.N(null,"csv-download","csv-download",2141432084);GQ=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);bja=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);cja=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);
HQ=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);IQ=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);JQ=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);dja=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);KQ=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);
eja=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);LQ=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);fja=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);gja=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);MQ=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);
hja=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);ija=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);jja=new $CLJS.N(null,"display_name","display_name",-1494335013);kja=new $CLJS.N(null,"snippet-id","snippet-id",1987785841);$CLJS.NQ=new $CLJS.N(null,"database","database",1849087575);OQ=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);
PQ=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);lja=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);$CLJS.QQ=new $CLJS.N(null,"expressions","expressions",255689909);mja=new $CLJS.N(null,"ad-hoc","ad-hoc",-2033634036);nja=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);$CLJS.RQ=new $CLJS.N(null,"temporal-unit","temporal-unit",-1892306358);
SQ=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);oja=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);TQ=new $CLJS.w(null,"get-day","get-day",1768100384,null);pja=new $CLJS.w(null,"Fields","Fields",430617156,null);UQ=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.VQ=new $CLJS.N(null,"native","native",-613060878);qja=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);
WQ=new $CLJS.N(null,"page","page",849072397);XQ=new $CLJS.w(null,"length","length",-2065447907,null);rja=new $CLJS.N(null,"dashboard","dashboard",-631747508);YQ=new $CLJS.w(null,"get-week","get-week",752472178,null);ZQ=new $CLJS.w(null,"get-month","get-month",1271156796,null);$Q=new $CLJS.w(null,"dimension","dimension",-2111181571,null);aR=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);
bR=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);sja=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);cR=new $CLJS.w(null,"substring","substring",-1513569493,null);tja=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);dR=new $CLJS.N(null,"internal","internal",-854870097);uja=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);
vja=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);eR=new $CLJS.N(null,"declared","declared",92336021);fR=new $CLJS.N(null,"more-values-or-fields","more-values-or-fields",-886177554);gR=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);wja=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);xja=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);
hR=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);yja=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);zja=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);iR=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.jR=new $CLJS.N(null,"template-tags","template-tags",1853115685);Aja=new $CLJS.N(null,"public-question","public-question",629369976);
Bja=new $CLJS.w("metabase.mbql.schema","Field*","metabase.mbql.schema/Field*",-1168278623,null);$CLJS.kR=new $CLJS.N(null,"fk-field-id","fk-field-id",1124127357);Cja=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);Dja=new $CLJS.N(null,"binary","binary",-1802232288);lR=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);Eja=new $CLJS.N(null,"userland-query?","userland-query?",-123699383);
$CLJS.mR=new $CLJS.N(null,"source-query","source-query",198004422);$CLJS.nR=new $CLJS.N(null,"settings","settings",1556144875);Fja=new $CLJS.w("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",-781973827,null);$CLJS.oR=new $CLJS.N(null,"inner-join","inner-join",659431740);Gja=new $CLJS.w(null,"Field*","Field*",-2104480026,null);Hja=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);Ija=new $CLJS.w("s","eq","s/eq",1021992833,null);
Jja=new $CLJS.N(null,"executed-by","executed-by",-739811161);Kja=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);pR=new $CLJS.N(null,"amount","amount",364489504);qR=new $CLJS.w(null,"percentile","percentile",1039342775,null);rR=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);sR=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);tR=new $CLJS.w(null,"trim","trim",-1880116002,null);
Lja=new $CLJS.w(null,"MBQLQuery","MBQLQuery",-1395590243,null);uR=new $CLJS.w(null,"NumericExpression","NumericExpression",-2082340941,null);vR=new $CLJS.w("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",-859895030,null);wR=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);xR=new $CLJS.N("string","\x3d","string/\x3d",983744235);Mja=new $CLJS.N(null,"skip-results-metadata?","skip-results-metadata?",251010463);
Nja=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Oja=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);Pja=new $CLJS.w(null,"DatetimeDiffUnits","DatetimeDiffUnits",1899101514,null);yR=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);zR=new $CLJS.N(null,"value-or-field","value-or-field",-1387286309);AR=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);
Qja=new $CLJS.N(null,"lat-min","lat-min",1630784161);BR=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);CR=new $CLJS.w(null,"inside","inside",-681932758,null);Rja=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);Sja=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);DR=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);
Tja=new $CLJS.N(null,"process-viz-settings?","process-viz-settings?",-173592315);Uja=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);ER=new $CLJS.N(null,"variadic","variadic",882626057);$CLJS.FR=new $CLJS.N(null,"category","category",-593092832);Vja=new $CLJS.w(null,"NumericExpression*","NumericExpression*",-1459070895,null);GR=new $CLJS.w(null,"upper","upper",1886775433,null);HR=new $CLJS.N(null,"field-or-expression","field-or-expression",-1409494368);
Wja=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);IR=new $CLJS.N(null,"sugar","sugar",-73788488);Xja=new $CLJS.N(null,"lat-max","lat-max",841568226);$CLJS.JR=new $CLJS.N(null,"full-join","full-join",1305476385);KR=new $CLJS.w(null,"power","power",702679448,null);LR=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);Yja=new $CLJS.w("metabase.mbql.schema","StringExpression*","metabase.mbql.schema/StringExpression*",224546636,null);
MR=new $CLJS.w(null,"median","median",-2084869638,null);NR=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);Zja=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);OR=new $CLJS.N(null,"y","y",-1757859776);$ja=new $CLJS.w("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",-823028985,null);PR=new $CLJS.w("helpers","NonBlankString","helpers/NonBlankString",-2060904596,null);
aka=new $CLJS.w("metabase.mbql.schema","UnnamedAggregation*","metabase.mbql.schema/UnnamedAggregation*",652628682,null);$CLJS.QR=new $CLJS.N(null,"binning","binning",1709835866);RR=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);SR=new $CLJS.N(null,"b","b",1482224470);TR=new $CLJS.N(null,"a","a",-2123407586);UR=new $CLJS.w(null,"replace","replace",853943757,null);bka=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);
VR=new $CLJS.w(null,"segment","segment",675610331,null);cka=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);WR=new $CLJS.w(null,"ExtractWeekModes","ExtractWeekModes",687144014,null);dka=new $CLJS.w(null,"UnnamedAggregation*","UnnamedAggregation*",1873018243,null);YR=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);ZR=new $CLJS.N("string","starts-with","string/starts-with",1266861170);
$CLJS.$R=new $CLJS.N(null,"order-by","order-by",1527318070);aS=new $CLJS.N(null,"advanced-math-expressions","advanced-math-expressions",1076581757);eka=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);fka=new $CLJS.N("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);gka=new $CLJS.w("metabase.mbql.schema","Filter*","metabase.mbql.schema/Filter*",-140275836,null);$CLJS.bS=new $CLJS.N(null,"condition","condition",1668437652);
cS=new $CLJS.N("string","does-not-contain","string/does-not-contain",-1536178964);dS=new $CLJS.w("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",970196104,null);hka=new $CLJS.N(null,"report-timezone","report-timezone",-2053796389);eS=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);ika=new $CLJS.N(null,"card-name","card-name",-2035606807);
jka=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);kka=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);fS=new $CLJS.w(null,"log","log",45015523,null);lka=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);gS=new $CLJS.w(null,"time","time",-1268547887,null);
mka=new $CLJS.w("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-475072702,null);$CLJS.hS=new $CLJS.N(null,"database_type","database_type",-54700895);iS=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);jS=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.kS=new $CLJS.N(null,"clause","clause",1479668060);$CLJS.lS=new $CLJS.N(null,"expression-name","expression-name",-1798450709);
$CLJS.mS=new $CLJS.N("date","all-options","date/all-options",549325958);nka=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);oka=new $CLJS.w(null,"WidgetType","WidgetType",-1985035186,null);nS=new $CLJS.N("location","state","location/state",-114378652);oS=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);pka=new $CLJS.N(null,"lon-field","lon-field",517872067);
pS=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);qS=new $CLJS.N(null,"numeric","numeric",-1495594714);rS=new $CLJS.w(null,"variable","variable",1359185035,null);sS=new $CLJS.w(null,"lower","lower",-1534114948,null);$CLJS.tS=new $CLJS.N(null,"limit","limit",-1355822363);qka=new $CLJS.w("s","enum","s/enum",-975416934,null);rka=new $CLJS.N(null,"pulse","pulse",-244494476);var uS,vS,wS,ska,tka,uka,vka,zS,AS,wka,xka,yka,BS,zka,CS,DS,ES,Aka,FS,Bka,GS,HS,Cka,Dka,LS,Eka,OS,QS,RS,SS,TS,US,XS,ZS,aT,bT,Fka,cT,dT,eT,fT,gT,hT,iT,jT,kT,lT,mT,nT,oT,pT,qT,rT,sT,tT,uT,vT,wT,xT,yT,VS,AT,BT,CT,DT,ET,FT,GT,HT,IT,JT,KT,LT,MT,NT,OT,PT,QT,PS,TT,UT,VT,WT,XT,YT,ZT,$T,aU,bU,cU,dU,eU,fU,gU,hU,iU,jU,kU,lU,mU,nU,oU,Gka,pU,qU,$S,rU,sU,Hka,Ika,tU,zT,RT,vU,wU,xU,yU,zU,AU,BU,CU,DU,EU,FU,GU,HU,IU,JU,KU,LU,MU,Jka,OU,PU,RU,Kka,Lka,SU,Mka,Nka,Oka,Pka,Qka,UU,WU,XU,ZU,Rka,Ska,Tka,$U,aV,Uka,TU,bV,cV,
dV,Vka,Wka,Xka,Yka,Zka,$ka,ala,bla,cla;uS=new $CLJS.ni(null,new $CLJS.k(null,12,[$CLJS.Tj,null,$CLJS.xz,null,$CLJS.Vk,null,$CLJS.Pj,null,$CLJS.mn,null,$CLJS.gl,null,$CLJS.pn,null,$CLJS.on,null,$CLJS.Jj,null,$CLJS.ql,null,$CLJS.Uj,null,$CLJS.ln,null],null),null);vS=new $CLJS.ni(null,new $CLJS.k(null,7,[$CLJS.wz,null,$CLJS.sz,null,$CLJS.Pj,null,$CLJS.kn,null,$CLJS.tz,null,$CLJS.nn,null,$CLJS.uz,null],null),null);wS=$CLJS.yz.h(uS,vS);ska=$CLJS.WM($CLJS.R.h(VN,uS),"date-bucketing-unit");
tka=$CLJS.WM($CLJS.R.h(VN,vS),"time-bucketing-unit");$CLJS.xS=$CLJS.WM($CLJS.R.h(VN,wS),"datetime-bucketing-unit");$CLJS.yS=$CLJS.WM($CLJS.R.h(VN,$CLJS.VL.tz.names()),"timezone-id");uka=$CLJS.WM($CLJS.R.h(VN,new $CLJS.ni(null,new $CLJS.k(null,11,[$CLJS.$K,null,$CLJS.mn,null,$CLJS.kn,null,$CLJS.pn,null,$CLJS.on,null,$CLJS.gJ,null,$CLJS.JI,null,$CLJS.VK,null,$CLJS.YI,null,$CLJS.ln,null,$CLJS.nn,null],null),null)),"temporal-extract-units");
vka=$CLJS.WM($CLJS.R.h(VN,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.Tj,null,$CLJS.xz,null,$CLJS.wz,null,$CLJS.Vk,null,$CLJS.sz,null,$CLJS.gl,null,$CLJS.Jj,null,$CLJS.uz,null],null),null)),"datetime-diff-units");zS=$CLJS.WM($CLJS.R.h(VN,new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.JJ,null,$CLJS.wI,null,$CLJS.rJ,null],null),null)),"extract-week-modes");
AS=$CLJS.WM($CLJS.R.h(VN,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.Tj,null,$CLJS.xz,null,$CLJS.wz,null,$CLJS.Vk,null,$CLJS.Pj,null,$CLJS.gl,null,$CLJS.Jj,null,$CLJS.uz,null],null),null)),"relative-datetime-unit");wka=$CLJS.YM($CLJS.LN,jO,"valid ISO-8601 datetime string literal");xka=$CLJS.YM($CLJS.LN,jO,"valid ISO-8601 datetime string literal");yka=$CLJS.YM($CLJS.LN,jO,"valid ISO-8601 time string literal");
BS=eO($CLJS.sJ,$CLJS.G(["n",$CLJS.KN($CLJS.G([$CLJS.SM($CLJS.nB),$CLJS.GN])),"unit",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,AS],null)]));zka=eO($CLJS.jJ,$CLJS.G(["n",$CLJS.GN,"unit",AS]));CS=XN.l($CLJS.G([function(a){return $CLJS.Gb(fO($CLJS.GI,a))},eO($CLJS.GI,$CLJS.G(["t",Date,"unit",$CLJS.xS])),function(a){return $CLJS.Zd(a)instanceof Date},eO($CLJS.GI,$CLJS.G(["date",Date,"unit",ska])),$CLJS.Uu,eO($CLJS.GI,$CLJS.G(["datetime",Date,"unit",$CLJS.xS]))]));
DS=eO($CLJS.ZD,$CLJS.G(["time",Date,"unit",tka]));ES=XN.l($CLJS.G([$CLJS.bg(fO,$CLJS.GI),CS,jO,xka,jO,wka,$CLJS.Uu,$CLJS.KN($CLJS.G([Date]))]));Aka=XN.l($CLJS.G([$CLJS.bg(fO,$CLJS.ZD),DS,jO,yka,$CLJS.Uu,$CLJS.KN($CLJS.G([Date]))]));FS=$CLJS.KN($CLJS.G([Aka,ES]));
gO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof CS?new $CLJS.ud(function(){return CS},$CLJS.K($ha,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,dR,$CLJS.Uk,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SQ,"metabase/mbql/schema.cljc",69,$CLJS.GI,1,!0,192,192,$CLJS.yd,"Schema for an `:absolute-datetime` clause.",$CLJS.m(CS)?CS.J:null])):null));return $CLJS.m(a)?a:SQ}(),CS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof BS?new $CLJS.ud(function(){return BS},$CLJS.K(nka,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,
$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rQ,"metabase/mbql/schema.cljc",29,$CLJS.sJ,1,174,174,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.sJ,$CLJS.uC,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.vQ,$CLJS.X(Ija,$CLJS.nB),EQ)),$CLJS.jn,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,SP))],null),$CLJS.yd,"Schema for a valid relative-datetime clause.",$CLJS.m(BS)?BS.J:null])):null));return $CLJS.m(a)?
a:rQ}(),BS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof DS?new $CLJS.ud(function(){return DS},$CLJS.K(gja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,dR,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gS,"metabase/mbql/schema.cljc",27,$CLJS.ZD,1,!0,225,225,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ZD,$CLJS.ZD,$CLJS.X($CLJS.Cl,Sia),$CLJS.jn,$CLJS.X($CLJS.Cl,wia)],null),$CLJS.yd,"Schema for a valid time clause.",$CLJS.m(DS)?DS.J:null])):null));return $CLJS.m(a)?a:gS}(),DS],null)]));Bka=$CLJS.Pf([TN($CLJS.hS),$CLJS.bO($CLJS.LN),TN($CLJS.uJ),$CLJS.bO($CLJS.MN),TN($CLJS.DP),$CLJS.bO($CLJS.NN),TN($CLJS.jn),$CLJS.bO($CLJS.xS),TN($CLJS.V),$CLJS.bO($CLJS.LN),$CLJS.HN,$CLJS.CN]);
GS=eO($CLJS.ol,$CLJS.G(["value",$CLJS.CN,"type-info",$CLJS.bO(Bka)]));HS=eO($CLJS.AG,$CLJS.G(["expression-name",$CLJS.LN,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.UM($CLJS.oe,"map")],null)]));Cka=VN.l($CLJS.G([$CLJS.DI,$CLJS.UK,$CLJS.Pj]));
Dka=function(a){return $CLJS.YM(a,function(b){var c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.XK);c=$CLJS.M.h(c,$CLJS.DI);return $CLJS.F.h(b,$CLJS.DI)?c:!0},"You must specify :num-bins when using the :num-bins strategy.")}(function(a){return $CLJS.YM(a,function(b){var c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.XK);c=$CLJS.M.h(c,$CLJS.UK);return $CLJS.F.h(b,$CLJS.UK)?c:!0},"You must specify :bin-width when using the :bin-width strategy.")}($CLJS.Pf([$CLJS.XK,Cka,TN($CLJS.DI),$CLJS.ON,TN($CLJS.UK),$CLJS.YM($CLJS.FN,$CLJS.$f($CLJS.ef),
"bin width must be \x3e\x3d 0."),$CLJS.HN,$CLJS.CN])));$CLJS.IS=function IS(a){switch(arguments.length){case 1:return IS.g(arguments[0]);case 2:return IS.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.IS.g=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.DG);b=$CLJS.M.h(b,$CLJS.RQ);return $CLJS.IS.h(a,b)};
$CLJS.IS.h=function(a,b){a=$CLJS.m($CLJS.m(b)?a:b)?$CLJS.PH(a,$CLJS.PI)?uS:$CLJS.PH(a,$CLJS.YK)?vS:$CLJS.PH(a,$CLJS.TJ)?wS:null:null;return $CLJS.m(a)?$CLJS.Ie(a,b):!0};$CLJS.IS.v=2;
var JS=$CLJS.YM($CLJS.YM($CLJS.Pf([TN($CLJS.DG),$CLJS.bO($CLJS.MN),TN($CLJS.XO),$CLJS.bO($CLJS.KN($CLJS.G([$CLJS.ON,$CLJS.LN]))),TN($CLJS.RQ),$CLJS.bO($CLJS.xS),TN($CLJS.nQ),$CLJS.bO($CLJS.LN),TN($CLJS.QR),$CLJS.bO(Dka),$CLJS.HN,$CLJS.CN]),$CLJS.IS,"Invalid :temporal-unit for the specified :base-type."),$CLJS.$f($CLJS.XK),"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."),KS=function(a){return $CLJS.YM(a,function(b){$CLJS.J(b,0,null);
var c=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);b=$CLJS.Qf(b);b=$CLJS.M.h(b,$CLJS.DG);return"string"===typeof c?b:!0},":field clauses using a string field name must specify :base-type.")}(eO($CLJS.FQ,$CLJS.G(["id-or-name",$CLJS.KN($CLJS.G([$CLJS.ON,$CLJS.LN])),"options",$CLJS.bO($N(new $CLJS.ud(function(){return JS},mka,$CLJS.Sh([$CLJS.lm,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[!0,$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Lha,"metabase/mbql/schema.cljc",28,1,383,383,$CLJS.yd,null,$CLJS.m(JS)?JS.J:null]))))])));$CLJS.YM(KS,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.Ae(a)},"Must be a :field with an integer Field ID.");
LS=gO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof HS?new $CLJS.ud(function(){return HS},$CLJS.K(fia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.SO,"metabase/mbql/schema.cljc",60,$CLJS.AG,1,318,318,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.AG,$CLJS.lS,$CLJS.X($CLJS.Cl,PR),$CLJS.Lk,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,$CLJS.X($CLJS.qQ,$CLJS.dk,"map")))],null),$CLJS.yd,"Schema for a valid expression clause.",$CLJS.m(HS)?HS.J:null])):null));return $CLJS.m(a)?a:$CLJS.SO}(),HS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof KS?
new $CLJS.ud(function(){return KS},$CLJS.K(pia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Yk,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],["0.39.0",$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JO,"metabase/mbql/schema.cljc",51,$CLJS.FQ,1,429,429,$CLJS.yd,"Schema for a `:field` clause.",$CLJS.m(KS)?KS.J:null])):null));return $CLJS.m(a)?
a:JO}(),KS],null)]));$CLJS.MS=$N(new $CLJS.ud(function(){return LS},Bja,$CLJS.Sh([$CLJS.lm,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[!0,$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Gja,"metabase/mbql/schema.cljc",22,1,445,445,$CLJS.yd,null,$CLJS.m(LS)?LS.J:null])));
Eka=eO($CLJS.OP,$CLJS.G(["aggregation-clause-index",$CLJS.GN,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.UM($CLJS.oe,"map")],null)]));$CLJS.NS=YN($CLJS.bg(fO,$CLJS.OP),Eka,$CLJS.MS);OS=new $CLJS.ni(null,new $CLJS.k(null,11,[$CLJS.Su,null,$CLJS.HK,null,$CLJS.bI,null,$CLJS.WK,null,$CLJS.pI,null,$CLJS.RJ,null,$CLJS.$I,null,$CLJS.bv,null,$CLJS.CK,null,$CLJS.PJ,null,$CLJS.ZK,null],null),null);
QS=XN.l($CLJS.G([$CLJS.Jb,$CLJS.DN,$CLJS.bg(fO,OS),$N(new $CLJS.ud(function(){return PS},dS,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pQ,"metabase/mbql/schema.cljc",26,1,488,!0,488,$CLJS.yd,null,$CLJS.m(PS)?PS.J:null]))),$CLJS.bg(fO,$CLJS.ol),GS,$CLJS.Uu,$CLJS.MS]));
RS=new $CLJS.ni(null,new $CLJS.k(null,26,[$CLJS.kI,null,$CLJS.TK,null,$CLJS.jm,null,$CLJS.pK,null,$CLJS.hK,null,$CLJS.pJ,null,$CLJS.yu,null,$CLJS.$H,null,$CLJS.xK,null,$CLJS.dK,null,$CLJS.RJ,null,$CLJS.QK,null,$CLJS.kK,null,$CLJS.WJ,null,$CLJS.nI,null,$CLJS.EI,null,$CLJS.im,null,$CLJS.uI,null,$CLJS.$I,null,$CLJS.ZH,null,$CLJS.jD,null,$CLJS.FI,null,$CLJS.bK,null,$CLJS.iJ,null,$CLJS.SK,null,$CLJS.HJ,null],null),null);
SS=new $CLJS.ni(null,new $CLJS.k(null,9,[$CLJS.qm,null,$CLJS.gm,null,$CLJS.zk,null,$CLJS.Qk,null,$CLJS.pm,null,$CLJS.DK,null,$CLJS.Lj,null,$CLJS.dm,null,$CLJS.em,null],null),null);TS=new $CLJS.ni(null,new $CLJS.k(null,17,[$CLJS.ok,null,$CLJS.AK,null,$CLJS.fK,null,$CLJS.NJ,null,$CLJS.OK,null,$CLJS.RK,null,$CLJS.NI,null,$CLJS.nJ,null,$CLJS.pO,null,$CLJS.RI,null,$CLJS.vK,null,$CLJS.ZJ,null,$CLJS.Vl,null,$CLJS.lD,null,$CLJS.DJ,null,$CLJS.bQ,null,$CLJS.tK,null],null),null);
US=new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.EJ,null,$CLJS.vI,null,$CLJS.MK,null,$CLJS.im,null,$CLJS.eK,null],null),null);
XS=XN.l($CLJS.G([$CLJS.Eb,$CLJS.FN,$CLJS.bg(fO,RS),$N(new $CLJS.ud(function(){return VS},vR,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uR,"metabase/mbql/schema.cljc",27,1,522,!0,522,$CLJS.yd,null,$CLJS.m(VS)?VS.J:null]))),$CLJS.bg(fO,TS),$N(new $CLJS.ud(function(){return $CLJS.WS},zO,$CLJS.Sh([$CLJS.Bk,
$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rP,"metabase/mbql/schema.cljc",21,1,525,!0,525,$CLJS.yd,null,$CLJS.m($CLJS.WS)?$CLJS.WS.J:null]))),$CLJS.bg(fO,$CLJS.ol),GS,$CLJS.Uu,$CLJS.MS]));
ZS=XN.l($CLJS.G([$CLJS.bg(fO,TS),$N(new $CLJS.ud(function(){return $CLJS.WS},zO,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rP,"metabase/mbql/schema.cljc",21,1,525,!0,525,$CLJS.yd,null,$CLJS.m($CLJS.WS)?$CLJS.WS.J:null]))),$CLJS.bg(fO,$CLJS.ol),GS,$CLJS.bg(fO,US),$N(new $CLJS.ud(function(){return $CLJS.YS},
CP,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mQ,"metabase/mbql/schema.cljc",28,1,524,!0,524,$CLJS.yd,null,$CLJS.m($CLJS.YS)?$CLJS.YS.J:null]))),$CLJS.Uu,$CLJS.KN($CLJS.G([ES,$CLJS.MS]))]));
aT=XN.l($CLJS.G([$CLJS.Eb,$CLJS.FN,$CLJS.ve,$CLJS.EN,$CLJS.bg(fO,SS),$N(new $CLJS.ud(function(){return $S},Yha,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gia,"metabase/mbql/schema.cljc",27,1,523,!0,523,$CLJS.yd,null,$CLJS.m($S)?$S.J:null]))),$CLJS.bg(fO,RS),$N(new $CLJS.ud(function(){return VS},
vR,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uR,"metabase/mbql/schema.cljc",27,1,522,!0,522,$CLJS.yd,null,$CLJS.m(VS)?VS.J:null]))),$CLJS.bg(fO,US),$N(new $CLJS.ud(function(){return $CLJS.YS},CP,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],
[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mQ,"metabase/mbql/schema.cljc",28,1,524,!0,524,$CLJS.yd,null,$CLJS.m($CLJS.YS)?$CLJS.YS.J:null]))),$CLJS.Jb,$CLJS.DN,$CLJS.bg(fO,OS),$N(new $CLJS.ud(function(){return PS},dS,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pQ,"metabase/mbql/schema.cljc",26,1,488,!0,488,$CLJS.yd,null,$CLJS.m(PS)?PS.J:null]))),$CLJS.bg(fO,$CLJS.ol),GS,$CLJS.Uu,$CLJS.MS]));bT=YN($CLJS.bg(fO,$CLJS.jJ),zka,XS);Fka=YN($CLJS.Eb,$CLJS.ON,XS);cT=eO($CLJS.RJ,$CLJS.G(["a",aT,"b",aT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vk,aT],null)]));dT=eO($CLJS.bI,$CLJS.G(["s",QS,"start",Fka,"length",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,XS],null)]));eT=eO($CLJS.jD,$CLJS.G(["s",QS]));fT=eO($CLJS.CK,$CLJS.G(["s",QS]));
gT=eO($CLJS.ZK,$CLJS.G(["s",QS]));hT=eO($CLJS.HK,$CLJS.G(["s",QS]));iT=eO($CLJS.Su,$CLJS.G(["s",QS]));jT=eO($CLJS.bv,$CLJS.G(["s",QS]));kT=eO($CLJS.WK,$CLJS.G(["s",QS,"match",$CLJS.DN,"replacement",$CLJS.DN]));lT=eO($CLJS.pI,$CLJS.G(["a",QS,"b",QS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vk,QS],null)]));mT=eO($CLJS.PJ,$CLJS.G(["s",QS,"pattern",$CLJS.DN]));nT=eO($CLJS.im,$CLJS.G(["x",bT,"y",bT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vk,bT],null)]));
oT=eO($CLJS.yu,$CLJS.G(["x",XS,"y",bT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vk,bT],null)]));pT=eO($CLJS.QK,$CLJS.G(["x",XS,"y",XS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vk,XS],null)]));qT=eO($CLJS.jm,$CLJS.G(["x",XS,"y",XS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vk,XS],null)]));rT=eO($CLJS.HJ,$CLJS.G(["x",XS]));sT=eO($CLJS.kI,$CLJS.G(["x",XS]));tT=eO($CLJS.WJ,$CLJS.G(["x",XS]));uT=eO($CLJS.uI,$CLJS.G(["x",XS]));vT=eO($CLJS.nI,$CLJS.G(["x",XS,"y",XS]));wT=eO($CLJS.pJ,$CLJS.G(["x",XS]));
xT=eO($CLJS.$H,$CLJS.G(["x",XS]));yT=eO($CLJS.SK,$CLJS.G(["x",XS]));VS=$N(new $CLJS.ud(function(){return zT},cia,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Vja,"metabase/mbql/schema.cljc",28,1,664,!0,664,$CLJS.yd,null,$CLJS.m(zT)?zT.J:null])));
AT=eO($CLJS.iJ,$CLJS.G(["datetime-x",ZS,"datetime-y",ZS,"unit",vka]));BT=eO($CLJS.pK,$CLJS.G(["datetime",ZS,"unit",uka,"mode",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,zS],null)]));CT=eO($CLJS.FI,$CLJS.G(["date",ZS]));DT=eO($CLJS.TK,$CLJS.G(["date",ZS]));ET=eO($CLJS.ZH,$CLJS.G(["date",ZS]));FT=eO($CLJS.dK,$CLJS.G(["date",ZS,"mode",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,zS],null)]));GT=eO($CLJS.bK,$CLJS.G(["date",ZS]));HT=eO($CLJS.kK,$CLJS.G(["date",ZS]));IT=eO($CLJS.xK,$CLJS.G(["datetime",ZS]));
JT=eO($CLJS.hK,$CLJS.G(["datetime",ZS]));KT=eO($CLJS.EI,$CLJS.G(["datetime",ZS]));LT=eO($CLJS.vI,$CLJS.G(["datetime",ZS,"to",$CLJS.yS,"from",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.yS],null)]));MT=$CLJS.WM($CLJS.R.h(VN,new $CLJS.ni(null,new $CLJS.k(null,9,[$CLJS.Tj,null,$CLJS.xz,null,$CLJS.wz,null,$CLJS.Vk,null,$CLJS.sz,null,$CLJS.gl,null,$CLJS.Jj,null,$CLJS.tz,null,$CLJS.uz,null],null),null)),"arithmetic-datetime-unit");NT=eO($CLJS.eK,$CLJS.G(["datetime",ZS,"amount",XS,"unit",MT]));
OT=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return eO(arguments[0],1<b.length?new $CLJS.z(b.slice(1),0,null):null)}($CLJS.EJ);PT=eO($CLJS.MK,$CLJS.G(["datetime",ZS,"amount",XS,"unit",MT]));
QT=gO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof nT?new $CLJS.ud(function(){return nT},$CLJS.K(LQ,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FP,"metabase/mbql/schema.cljc",51,$CLJS.im,1,630,630,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.im,$CLJS.JG,$CLJS.X($CLJS.Cl,OQ),OR,$CLJS.X($CLJS.Cl,OQ),aP,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,OQ))],null),$CLJS.yd,"Schema for a valid + clause.",$CLJS.m(nT)?nT.J:null])):null));return $CLJS.m(a)?a:FP}(),nT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof NT?new $CLJS.ud(function(){return NT},
$CLJS.K(Qia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[jP,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GQ,"metabase/mbql/schema.cljc",67,$CLJS.eK,1,729,729,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.eK,$CLJS.CJ,$CLJS.X($CLJS.Cl,pS),pR,$CLJS.X($CLJS.Cl,
sR),$CLJS.jn,$CLJS.X($CLJS.Cl,BR)],null),$CLJS.yd,"Schema for a valid datetime-add clause.",$CLJS.m(NT)?NT.J:null])):null));return $CLJS.m(a)?a:GQ}(),NT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof PT?new $CLJS.ud(function(){return PT},$CLJS.K(yha,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,
$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[jP,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oS,"metabase/mbql/schema.cljc",72,$CLJS.MK,1,736,736,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.MK,$CLJS.CJ,$CLJS.X($CLJS.Cl,pS),pR,$CLJS.X($CLJS.Cl,sR),$CLJS.jn,$CLJS.X($CLJS.Cl,BR)],null),$CLJS.yd,"Schema for a valid datetime-subtract clause.",$CLJS.m(PT)?PT.J:null])):null));return $CLJS.m(a)?
a:oS}(),PT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof LT?new $CLJS.ud(function(){return LT},$CLJS.K(Sja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.vI,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZO,"metabase/mbql/schema.cljc",71,$CLJS.vI,1,719,719,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.vI,$CLJS.CJ,$CLJS.X($CLJS.Cl,pS),Gia,$CLJS.X($CLJS.Cl,RR),Dha,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,RR))],null),$CLJS.yd,"Schema for a valid convert-timezone clause.",$CLJS.m(LT)?LT.J:null])):null));return $CLJS.m(a)?a:ZO}(),LT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof OT?new $CLJS.ud(function(){return OT},
$CLJS.K(sja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.EJ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fQ,"metabase/mbql/schema.cljc",45,$CLJS.EJ,1,734,734,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.EJ],null),$CLJS.yd,"Schema for a valid now clause.",
$CLJS.m(OT)?OT.J:null])):null));return $CLJS.m(a)?a:fQ}(),OT],null)]));$CLJS.YS=$N(new $CLJS.ud(function(){return QT},Cia,$CLJS.Sh([$CLJS.lm,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[!0,$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Hia,"metabase/mbql/schema.cljc",35,1,741,741,$CLJS.yd,null,$CLJS.m(QT)?QT.J:null])));
PS=$N(new $CLJS.ud(function(){return RT},Yja,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Tia,"metabase/mbql/schema.cljc",27,1,748,!0,748,$CLJS.yd,null,$CLJS.m(RT)?RT.J:null])));
TT=eO($CLJS.Lj,$CLJS.G(["first-clause",$N(new $CLJS.ud(function(){return $CLJS.ST},CQ,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.ST)?$CLJS.ST.J:null]))),"second-clause",$N(new $CLJS.ud(function(){return $CLJS.ST},CQ,$CLJS.Sh([$CLJS.Bk,
$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.ST)?$CLJS.ST.J:null]))),"other-clauses",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vk,$N(new $CLJS.ud(function(){return $CLJS.ST},CQ,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,
$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.ST)?$CLJS.ST.J:null])))],null)]));
UT=eO($CLJS.zk,$CLJS.G(["first-clause",$N(new $CLJS.ud(function(){return $CLJS.ST},CQ,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.ST)?$CLJS.ST.J:null]))),"second-clause",$N(new $CLJS.ud(function(){return $CLJS.ST},CQ,$CLJS.Sh([$CLJS.Bk,
$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.ST)?$CLJS.ST.J:null]))),"other-clauses",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vk,$N(new $CLJS.ud(function(){return $CLJS.ST},CQ,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,
$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.ST)?$CLJS.ST.J:null])))],null)]));
VT=eO($CLJS.Qk,$CLJS.G(["clause",$N(new $CLJS.ud(function(){return $CLJS.ST},CQ,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.ST)?$CLJS.ST.J:null])))]));WT=YN($CLJS.bg(fO,$CLJS.sJ),BS,$CLJS.MS);
XT=$CLJS.bO($CLJS.KN($CLJS.G([$CLJS.EN,$CLJS.FN,$CLJS.DN,FS,WT,aT,GS])));YT=YN($CLJS.bg(fO,$CLJS.ol),GS,$CLJS.KN($CLJS.G([$CLJS.FN,$CLJS.DN,FS,aT,WT])));ZT=eO($CLJS.dm,$CLJS.G(["field",XT,"value-or-field",XT,"more-values-or-fields",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vk,XT],null)]));$T=eO($CLJS.DK,$CLJS.G(["field",XT,"value-or-field",XT,"more-values-or-fields",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vk,XT],null)]));aU=eO($CLJS.em,$CLJS.G(["field",YT,"value-or-field",YT]));
bU=eO($CLJS.gm,$CLJS.G(["field",YT,"value-or-field",YT]));cU=eO($CLJS.qm,$CLJS.G(["field",YT,"value-or-field",YT]));dU=eO($CLJS.pm,$CLJS.G(["field",YT,"value-or-field",YT]));eU=eO($CLJS.KI,$CLJS.G(["field",YT,"min",YT,"max",YT]));fU=eO($CLJS.VI,$CLJS.G(["lat-field",YT,"lon-field",YT,"lat-max",YT,"lon-min",YT,"lat-min",YT,"lon-max",YT]));gU=eO($CLJS.yI,$CLJS.G(["field",$CLJS.MS]));hU=eO($CLJS.oJ,$CLJS.G(["field",$CLJS.MS]));iU=eO($CLJS.LI,$CLJS.G(["field",$CLJS.MS]));
jU=eO($CLJS.aJ,$CLJS.G(["field",$CLJS.MS]));kU=$CLJS.Pf([TN($CLJS.aK),$CLJS.EN]);lU=eO($CLJS.vJ,$CLJS.G(["field",QS,"string-or-field",QS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,kU],null)]));mU=eO($CLJS.nK,$CLJS.G(["field",QS,"string-or-field",QS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,kU],null)]));nU=eO($CLJS.LJ,$CLJS.G(["field",QS,"string-or-field",QS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,kU],null)]));
oU=eO($CLJS.wJ,$CLJS.G(["field",QS,"string-or-field",QS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,kU],null)]));Gka=$CLJS.Pf([TN($CLJS.yK),$CLJS.EN]);pU=eO($CLJS.rK,$CLJS.G(["field",$CLJS.MS,"n",$CLJS.KN($CLJS.G([$CLJS.GN,VN.l($CLJS.G([$CLJS.nB,$CLJS.pD,$CLJS.SI]))])),"unit",AS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,Gka],null)]));qU=eO($CLJS.oI,$CLJS.G(["segment-id",$CLJS.KN($CLJS.G([$CLJS.ON,$CLJS.LN]))]));
$S=$N(new $CLJS.ud(function(){return rU},Eia,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Lia,"metabase/mbql/schema.cljc",28,1,883,!0,883,$CLJS.yd,null,$CLJS.m(rU)?rU.J:null])));
rU=gO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof TT?new $CLJS.ud(function(){return TT},$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TP,"metabase/mbql/schema.cljc",15,$CLJS.Lj,1,758,758,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Lj,bP,$CLJS.X($CLJS.Cl,$CLJS.X(VO,$CLJS.X($CLJS.Gl,YP))),xQ,$CLJS.X($CLJS.Cl,$CLJS.X(VO,$CLJS.X($CLJS.Gl,YP))),hP,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,$CLJS.X(VO,$CLJS.X($CLJS.Gl,YP))))],null),$CLJS.yd,"Schema for a valid and clause.",$CLJS.m(TT)?TT.J:null])):null));return $CLJS.m(a)?a:TP}(),TT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==
typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof UT?new $CLJS.ud(function(){return UT},$CLJS.K(AQ,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DQ,"metabase/mbql/schema.cljc",14,$CLJS.zk,1,763,763,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.zk,bP,$CLJS.X($CLJS.Cl,
$CLJS.X(VO,$CLJS.X($CLJS.Gl,YP))),xQ,$CLJS.X($CLJS.Cl,$CLJS.X(VO,$CLJS.X($CLJS.Gl,YP))),hP,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,$CLJS.X(VO,$CLJS.X($CLJS.Gl,YP))))],null),$CLJS.yd,"Schema for a valid or clause.",$CLJS.m(UT)?UT.J:null])):null));return $CLJS.m(a)?a:DQ}(),UT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof VT?new $CLJS.ud(function(){return VT},$CLJS.K(bR,new $CLJS.k(null,
1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.aM,"metabase/mbql/schema.cljc",15,$CLJS.Qk,1,768,768,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,$CLJS.kS,$CLJS.X($CLJS.Cl,$CLJS.X(VO,$CLJS.X($CLJS.Gl,YP)))],null),$CLJS.yd,"Schema for a valid not clause.",$CLJS.m(VT)?VT.J:
null])):null));return $CLJS.m(a)?a:$CLJS.aM}(),VT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof aU?new $CLJS.ud(function(){return aU},$CLJS.K(JQ,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MP,"metabase/mbql/schema.cljc",13,$CLJS.em,1,814,814,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.em,$CLJS.FQ,$CLJS.X($CLJS.Cl,cP),zR,$CLJS.X($CLJS.Cl,cP)],null),$CLJS.yd,"Schema for a valid \x3c clause.",$CLJS.m(aU)?aU.J:null])):null));return $CLJS.m(a)?a:MP}(),aU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof cU?new $CLJS.ud(function(){return cU},$CLJS.K(eS,new $CLJS.k(null,1,
[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LP,"metabase/mbql/schema.cljc",14,$CLJS.qm,1,816,816,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.qm,$CLJS.FQ,$CLJS.X($CLJS.Cl,cP),zR,$CLJS.X($CLJS.Cl,cP)],null),$CLJS.yd,"Schema for a valid \x3c\x3d clause.",$CLJS.m(cU)?cU.J:null])):
null));return $CLJS.m(a)?a:LP}(),cU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof bU?new $CLJS.ud(function(){return bU},$CLJS.K(HQ,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NP,"metabase/mbql/schema.cljc",13,$CLJS.gm,1,815,815,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.gm,$CLJS.FQ,$CLJS.X($CLJS.Cl,cP),zR,$CLJS.X($CLJS.Cl,cP)],null),$CLJS.yd,"Schema for a valid \x3e clause.",$CLJS.m(bU)?bU.J:null])):null));return $CLJS.m(a)?a:NP}(),bU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof dU?new $CLJS.ud(function(){return dU},$CLJS.K(YR,new $CLJS.k(null,1,
[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UP,"metabase/mbql/schema.cljc",14,$CLJS.pm,1,817,817,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.pm,$CLJS.FQ,$CLJS.X($CLJS.Cl,cP),zR,$CLJS.X($CLJS.Cl,cP)],null),$CLJS.yd,"Schema for a valid \x3e\x3d clause.",$CLJS.m(dU)?dU.J:null])):
null));return $CLJS.m(a)?a:UP}(),dU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof ZT?new $CLJS.ud(function(){return ZT},$CLJS.K(IQ,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.qN,"metabase/mbql/schema.cljc",13,$CLJS.dm,1,811,811,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.dm,$CLJS.FQ,$CLJS.X($CLJS.Cl,iS),zR,$CLJS.X($CLJS.Cl,iS),fR,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,iS))],null),$CLJS.yd,"Schema for a valid \x3d clause.",$CLJS.m(ZT)?ZT.J:null])):null));return $CLJS.m(a)?a:$CLJS.qN}(),ZT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof $T?new $CLJS.ud(function(){return $T},
$CLJS.K(FO,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",14,$CLJS.DK,1,812,812,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.DK,$CLJS.FQ,$CLJS.X($CLJS.Cl,iS),zR,$CLJS.X($CLJS.Cl,iS),fR,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,iS))],null),
$CLJS.yd,"Schema for a valid !\x3d clause.",$CLJS.m($T)?$T.J:null])):null));return $CLJS.m(a)?a:RO}(),$T],null)]));
sU=XN.l($CLJS.G([$CLJS.bg(fO,US),$CLJS.YS,$CLJS.bg(fO,RS),VS,$CLJS.bg(fO,OS),PS,$CLJS.bg(fO,SS),$S,$CLJS.Uu,gO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof TT?new $CLJS.ud(function(){return TT},$CLJS.K(LR,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TP,"metabase/mbql/schema.cljc",15,$CLJS.Lj,1,758,758,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Lj,bP,$CLJS.X($CLJS.Cl,$CLJS.X(VO,$CLJS.X($CLJS.Gl,YP))),xQ,$CLJS.X($CLJS.Cl,$CLJS.X(VO,$CLJS.X($CLJS.Gl,YP))),hP,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,$CLJS.X(VO,$CLJS.X($CLJS.Gl,YP))))],null),$CLJS.yd,"Schema for a valid and clause.",$CLJS.m(TT)?TT.J:null])):null));return $CLJS.m(a)?a:TP}(),TT],
null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof UT?new $CLJS.ud(function(){return UT},$CLJS.K(AQ,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DQ,"metabase/mbql/schema.cljc",14,$CLJS.zk,1,763,763,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.zk,bP,$CLJS.X($CLJS.Cl,$CLJS.X(VO,$CLJS.X($CLJS.Gl,YP))),xQ,$CLJS.X($CLJS.Cl,$CLJS.X(VO,$CLJS.X($CLJS.Gl,YP))),hP,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,$CLJS.X(VO,$CLJS.X($CLJS.Gl,YP))))],null),$CLJS.yd,"Schema for a valid or clause.",$CLJS.m(UT)?UT.J:null])):null));return $CLJS.m(a)?a:DQ}(),UT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==
typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof VT?new $CLJS.ud(function(){return VT},$CLJS.K(bR,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.aM,"metabase/mbql/schema.cljc",15,$CLJS.Qk,1,768,768,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Qk,$CLJS.kS,
$CLJS.X($CLJS.Cl,$CLJS.X(VO,$CLJS.X($CLJS.Gl,YP)))],null),$CLJS.yd,"Schema for a valid not clause.",$CLJS.m(VT)?VT.J:null])):null));return $CLJS.m(a)?a:$CLJS.aM}(),VT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof ZT?new $CLJS.ud(function(){return ZT},$CLJS.K(IQ,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,
IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.qN,"metabase/mbql/schema.cljc",13,$CLJS.dm,1,811,811,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.dm,$CLJS.FQ,$CLJS.X($CLJS.Cl,iS),zR,$CLJS.X($CLJS.Cl,iS),fR,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,iS))],null),$CLJS.yd,"Schema for a valid \x3d clause.",$CLJS.m(ZT)?ZT.J:null])):null));return $CLJS.m(a)?a:$CLJS.qN}(),ZT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof $T?new $CLJS.ud(function(){return $T},$CLJS.K(FO,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RO,"metabase/mbql/schema.cljc",
14,$CLJS.DK,1,812,812,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.DK,$CLJS.FQ,$CLJS.X($CLJS.Cl,iS),zR,$CLJS.X($CLJS.Cl,iS),fR,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,iS))],null),$CLJS.yd,"Schema for a valid !\x3d clause.",$CLJS.m($T)?$T.J:null])):null));return $CLJS.m(a)?a:RO}(),$T],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof aU?new $CLJS.ud(function(){return aU},$CLJS.K(JQ,new $CLJS.k(null,
1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MP,"metabase/mbql/schema.cljc",13,$CLJS.em,1,814,814,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.em,$CLJS.FQ,$CLJS.X($CLJS.Cl,cP),zR,$CLJS.X($CLJS.Cl,cP)],null),$CLJS.yd,"Schema for a valid \x3c clause.",$CLJS.m(aU)?aU.J:null])):null));
return $CLJS.m(a)?a:MP}(),aU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof bU?new $CLJS.ud(function(){return bU},$CLJS.K(HQ,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NP,"metabase/mbql/schema.cljc",13,$CLJS.gm,1,815,815,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.gm,$CLJS.FQ,$CLJS.X($CLJS.Cl,cP),zR,$CLJS.X($CLJS.Cl,cP)],null),$CLJS.yd,"Schema for a valid \x3e clause.",$CLJS.m(bU)?bU.J:null])):null));return $CLJS.m(a)?a:NP}(),bU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof cU?new $CLJS.ud(function(){return cU},$CLJS.K(eS,new $CLJS.k(null,1,
[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LP,"metabase/mbql/schema.cljc",14,$CLJS.qm,1,816,816,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.qm,$CLJS.FQ,$CLJS.X($CLJS.Cl,cP),zR,$CLJS.X($CLJS.Cl,cP)],null),$CLJS.yd,"Schema for a valid \x3c\x3d clause.",$CLJS.m(cU)?cU.J:null])):
null));return $CLJS.m(a)?a:LP}(),cU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof dU?new $CLJS.ud(function(){return dU},$CLJS.K(YR,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UP,"metabase/mbql/schema.cljc",14,$CLJS.pm,1,817,817,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.pm,$CLJS.FQ,$CLJS.X($CLJS.Cl,cP),zR,$CLJS.X($CLJS.Cl,cP)],null),$CLJS.yd,"Schema for a valid \x3e\x3d clause.",$CLJS.m(dU)?dU.J:null])):null));return $CLJS.m(a)?a:UP}(),dU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof eU?new $CLJS.ud(function(){return eU},$CLJS.K(ija,new $CLJS.k(null,
1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HO,"metabase/mbql/schema.cljc",19,$CLJS.KI,1,820,820,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.KI,$CLJS.FQ,$CLJS.X($CLJS.Cl,cP),$CLJS.ok,$CLJS.X($CLJS.Cl,cP),$CLJS.Vl,$CLJS.X($CLJS.Cl,cP)],null),$CLJS.yd,"Schema for a valid between clause.",
$CLJS.m(eU)?eU.J:null])):null));return $CLJS.m(a)?a:HO}(),eU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof lU?new $CLJS.ud(function(){return lU},$CLJS.K(lja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DR,"metabase/mbql/schema.cljc",23,$CLJS.vJ,1,843,843,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.vJ,$CLJS.FQ,$CLJS.X($CLJS.Cl,yR),QO,$CLJS.X($CLJS.Cl,yR),$CLJS.Lk,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,aR))],null),$CLJS.yd,"Schema for a valid starts-with clause.",$CLJS.m(lU)?lU.J:null])):null));return $CLJS.m(a)?a:DR}(),lU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof mU?new $CLJS.ud(function(){return mU},
$CLJS.K(dia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",21,$CLJS.nK,1,844,844,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.nK,$CLJS.FQ,$CLJS.X($CLJS.Cl,yR),QO,$CLJS.X($CLJS.Cl,yR),$CLJS.Lk,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,aR))],
null),$CLJS.yd,"Schema for a valid ends-with clause.",$CLJS.m(mU)?mU.J:null])):null));return $CLJS.m(a)?a:UO}(),mU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof nU?new $CLJS.ud(function(){return nU},$CLJS.K(uia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dP,"metabase/mbql/schema.cljc",20,$CLJS.LJ,1,845,845,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.LJ,$CLJS.FQ,$CLJS.X($CLJS.Cl,yR),QO,$CLJS.X($CLJS.Cl,yR),$CLJS.Lk,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,aR))],null),$CLJS.yd,"Schema for a valid contains clause.",$CLJS.m(nU)?nU.J:null])):null));return $CLJS.m(a)?a:dP}(),nU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==
typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof oU?new $CLJS.ud(function(){return oU},$CLJS.K(kka,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oO,"metabase/mbql/schema.cljc",36,$CLJS.wJ,1,848,848,!0,new $CLJS.S(null,
7,5,$CLJS.T,[$CLJS.wJ,$CLJS.FQ,$CLJS.X($CLJS.Cl,yR),QO,$CLJS.X($CLJS.Cl,yR),$CLJS.Lk,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,aR))],null),$CLJS.yd,"Schema for a valid does-not-contain clause.",$CLJS.m(oU)?oU.J:null])):null));return $CLJS.m(a)?a:oO}(),oU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof fU?new $CLJS.ud(function(){return fU},$CLJS.K(Oja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),
$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CR,"metabase/mbql/schema.cljc",26,$CLJS.VI,1,823,823,!0,new $CLJS.S(null,13,5,$CLJS.T,[$CLJS.VI,Jha,$CLJS.X($CLJS.Cl,cP),pka,$CLJS.X($CLJS.Cl,cP),Xja,$CLJS.X($CLJS.Cl,cP),Nha,$CLJS.X($CLJS.Cl,cP),Qja,$CLJS.X($CLJS.Cl,cP),Iha,$CLJS.X($CLJS.Cl,cP)],
null),$CLJS.yd,"Schema for a valid inside clause.",$CLJS.m(fU)?fU.J:null])):null));return $CLJS.m(a)?a:CR}(),fU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof iU?new $CLJS.ud(function(){return iU},$CLJS.K(via,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",28,$CLJS.LI,1,837,837,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.LI,$CLJS.FQ,$CLJS.X($CLJS.Cl,YO)],null),$CLJS.yd,"Schema for a valid is-empty clause.",$CLJS.m(iU)?iU.J:null])):null));return $CLJS.m(a)?a:oQ}(),iU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&
"undefined"!==typeof jU?new $CLJS.ud(function(){return jU},$CLJS.K(yja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UQ,"metabase/mbql/schema.cljc",29,$CLJS.aJ,1,838,838,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aJ,$CLJS.FQ,$CLJS.X($CLJS.Cl,YO)],null),
$CLJS.yd,"Schema for a valid not-empty clause.",$CLJS.m(jU)?jU.J:null])):null));return $CLJS.m(a)?a:UQ}(),jU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof gU?new $CLJS.ud(function(){return gU},$CLJS.K(eka,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gP,"metabase/mbql/schema.cljc",27,$CLJS.yI,1,832,832,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yI,$CLJS.FQ,$CLJS.X($CLJS.Cl,YO)],null),$CLJS.yd,"Schema for a valid is-null clause.",$CLJS.m(gU)?gU.J:null])):null));return $CLJS.m(a)?a:gP}(),gU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&
"undefined"!==typeof hU?new $CLJS.ud(function(){return hU},$CLJS.K(Iia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LO,"metabase/mbql/schema.cljc",28,$CLJS.oJ,1,833,833,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oJ,$CLJS.FQ,$CLJS.X($CLJS.Cl,YO)],null),
$CLJS.yd,"Schema for a valid not-null clause.",$CLJS.m(hU)?hU.J:null])):null));return $CLJS.m(a)?a:LO}(),hU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof pU?new $CLJS.ud(function(){return pU},$CLJS.K(zha,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lR,"metabase/mbql/schema.cljc",33,$CLJS.rK,1,868,868,!0,new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.rK,$CLJS.FQ,$CLJS.X($CLJS.Cl,YO),$CLJS.uC,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.vQ,EQ,$CLJS.X(qka,$CLJS.nB,$CLJS.pD,$CLJS.SI))),$CLJS.jn,$CLJS.X($CLJS.Cl,SP),$CLJS.Lk,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,oja))],null),$CLJS.yd,"Schema for a valid time-interval clause.",$CLJS.m(pU)?pU.J:null])):null));
return $CLJS.m(a)?a:lR}(),pU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof qU?new $CLJS.ud(function(){return qU},$CLJS.K(Uia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VR,"metabase/mbql/schema.cljc",27,$CLJS.oI,1,881,881,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oI,Rha,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.vQ,$O,PR))],null),$CLJS.yd,"Schema for a valid segment clause.",$CLJS.m(qU)?qU.J:null])):null));return $CLJS.m(a)?a:VR}(),qU],null)]))]));
$CLJS.ST=$N(new $CLJS.ud(function(){return sU},gka,$CLJS.Sh([$CLJS.lm,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[!0,$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jia,"metabase/mbql/schema.cljc",23,1,892,892,$CLJS.yd,null,$CLJS.m(sU)?sU.J:null])));
Hka=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.SN($CLJS.ST,"pred"),$CLJS.SN(aT,"expr")],null)],null);Ika=$CLJS.Pf([TN($CLJS.Pj),aT]);tU=eO($CLJS.$I,$CLJS.G(["clauses",Hka,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,Ika],null)]));
zT=gO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof nT?new $CLJS.ud(function(){return nT},$CLJS.K(LQ,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FP,"metabase/mbql/schema.cljc",51,$CLJS.im,1,630,630,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.im,$CLJS.JG,$CLJS.X($CLJS.Cl,OQ),OR,$CLJS.X($CLJS.Cl,OQ),aP,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,OQ))],null),$CLJS.yd,"Schema for a valid + clause.",$CLJS.m(nT)?nT.J:null])):null));return $CLJS.m(a)?a:FP}(),nT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof oT?new $CLJS.ud(function(){return oT},
$CLJS.K(eja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GP,"metabase/mbql/schema.cljc",51,$CLJS.yu,1,633,633,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.yu,$CLJS.JG,$CLJS.X($CLJS.Cl,sR),OR,
$CLJS.X($CLJS.Cl,OQ),aP,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,OQ))],null),$CLJS.yd,"Schema for a valid - clause.",$CLJS.m(oT)?oT.J:null])):null));return $CLJS.m(a)?a:GP}(),oT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO?new $CLJS.ud(function(){return pT},$CLJS.K(dja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,eQ,$CLJS.yk,$CLJS.Uk,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,
new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Vu,"metabase/mbql/schema.cljc",$CLJS.QK,1,636,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.QK,$CLJS.JG,$CLJS.X($CLJS.Cl,sR),OR,$CLJS.X($CLJS.Cl,sR),aP,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,sR))],null),$CLJS.yd,"Schema for a valid / clause.",$CLJS.m(pT)?pT.J:null])):null));return $CLJS.m(a)?a:$CLJS.Vu}(),pT],null),
new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof qT?new $CLJS.ud(function(){return qT},$CLJS.K(fja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EP,"metabase/mbql/schema.cljc",51,$CLJS.jm,1,638,638,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.jm,$CLJS.JG,$CLJS.X($CLJS.Cl,sR),OR,$CLJS.X($CLJS.Cl,sR),aP,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,sR))],null),$CLJS.yd,"Schema for a valid * clause.",$CLJS.m(qT)?qT.J:null])):null));return $CLJS.m(a)?a:EP}(),qT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof cT?new $CLJS.ud(function(){return cT},
$CLJS.K(hR,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wR,"metabase/mbql/schema.cljc",58,$CLJS.RJ,1,597,597,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.RJ,TR,$CLJS.X($CLJS.Cl,rO),SR,$CLJS.X($CLJS.Cl,
rO),aP,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,rO))],null),$CLJS.yd,"Schema for a valid coalesce clause.",$CLJS.m(cT)?cT.J:null])):null));return $CLJS.m(a)?a:wR}(),cT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof eT?new $CLJS.ud(function(){return eT},$CLJS.K(xja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,
$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XQ,"metabase/mbql/schema.cljc",56,$CLJS.jD,1,603,603,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jD,$CLJS.GD,$CLJS.X($CLJS.Cl,yR)],null),$CLJS.yd,"Schema for a valid length clause.",$CLJS.m(eT)?eT.J:null])):null));return $CLJS.m(a)?a:XQ}(),eT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof rT?new $CLJS.ud(function(){return rT},$CLJS.K(eia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),cQ,"metabase/mbql/schema.cljc",55,$CLJS.HJ,1,640,640,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.HJ,$CLJS.JG,$CLJS.X($CLJS.Cl,sR)],null),$CLJS.yd,"Schema for a valid floor clause.",$CLJS.m(rT)?rT.J:null])):null));return $CLJS.m(a)?a:cQ}(),rT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof sT?new $CLJS.ud(function(){return sT},$CLJS.K(Xha,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),
$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BO,"metabase/mbql/schema.cljc",54,$CLJS.kI,1,643,643,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kI,$CLJS.JG,$CLJS.X($CLJS.Cl,sR)],null),$CLJS.yd,"Schema for a valid ceil clause.",$CLJS.m(sT)?
sT.J:null])):null));return $CLJS.m(a)?a:BO}(),sT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof tT?new $CLJS.ud(function(){return tT},$CLJS.K(ria,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",55,$CLJS.WJ,1,646,646,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WJ,$CLJS.JG,$CLJS.X($CLJS.Cl,sR)],null),$CLJS.yd,"Schema for a valid round clause.",$CLJS.m(tT)?tT.J:null])):null));return $CLJS.m(a)?a:VP}(),tT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&
"undefined"!==typeof uT?new $CLJS.ud(function(){return uT},$CLJS.K(lka,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tQ,"metabase/mbql/schema.cljc",53,$CLJS.uI,1,649,649,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.uI,$CLJS.JG,$CLJS.X($CLJS.Cl,sR)],null),$CLJS.yd,"Schema for a valid abs clause.",$CLJS.m(uT)?uT.J:null])):null));return $CLJS.m(a)?a:tQ}(),uT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof vT?new $CLJS.ud(function(){return vT},$CLJS.K(Uja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,
$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[aS,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KR,"metabase/mbql/schema.cljc",69,$CLJS.nI,1,652,652,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.nI,$CLJS.JG,$CLJS.X($CLJS.Cl,sR),OR,$CLJS.X($CLJS.Cl,sR)],null),$CLJS.yd,"Schema for a valid power clause.",$CLJS.m(vT)?vT.J:null])):null));return $CLJS.m(a)?a:KR}(),vT],null),
new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof wT?new $CLJS.ud(function(){return wT},$CLJS.K(Nia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[aS,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BP,"metabase/mbql/schema.cljc",68,$CLJS.pJ,1,655,655,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pJ,$CLJS.JG,$CLJS.X($CLJS.Cl,sR)],null),$CLJS.yd,"Schema for a valid sqrt clause.",$CLJS.m(wT)?wT.J:null])):null));return $CLJS.m(a)?a:BP}(),wT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof xT?new $CLJS.ud(function(){return xT},$CLJS.K(Wja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,
$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[aS,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",67,$CLJS.$H,1,658,658,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$H,$CLJS.JG,$CLJS.X($CLJS.Cl,sR)],null),$CLJS.yd,"Schema for a valid exp clause.",$CLJS.m(xT)?xT.J:null])):
null));return $CLJS.m(a)?a:XP}(),xT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof yT?new $CLJS.ud(function(){return yT},$CLJS.K(Qha,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[aS,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fS,"metabase/mbql/schema.cljc",67,$CLJS.SK,1,661,661,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.SK,$CLJS.JG,$CLJS.X($CLJS.Cl,sR)],null),$CLJS.yd,"Schema for a valid log clause.",$CLJS.m(yT)?yT.J:null])):null));return $CLJS.m(a)?a:fS}(),yT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==
typeof tU?new $CLJS.ud(function(){return tU},$CLJS.K(vO,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jQ,"metabase/mbql/schema.cljc",61,$CLJS.$I,1,916,916,new $CLJS.S(null,5,5,$CLJS.T,
[$CLJS.$I,$CLJS.wC,$CLJS.X($CLJS.Cl,iP),$CLJS.Lk,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,BQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(tU)?tU.J:null])):null));return $CLJS.m(a)?a:jQ}(),tU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof AT?new $CLJS.ud(function(){return AT},$CLJS.K(Tha,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,
eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.iJ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uQ,"metabase/mbql/schema.cljc",65,$CLJS.iJ,1,680,680,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.iJ,kia,$CLJS.X($CLJS.Cl,pS),lia,$CLJS.X($CLJS.Cl,pS),$CLJS.jn,$CLJS.X($CLJS.Cl,Pja)],null),$CLJS.yd,"Schema for a valid datetime-diff clause.",
$CLJS.m(AT)?AT.J:null])):null));return $CLJS.m(a)?a:uQ}(),AT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof BT?new $CLJS.ud(function(){return BT},$CLJS.K(Cja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(rR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MQ,"metabase/mbql/schema.cljc",71,$CLJS.pK,1,685,685,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.pK,$CLJS.CJ,$CLJS.X($CLJS.Cl,pS),$CLJS.jn,$CLJS.X($CLJS.Cl,Yia),$CLJS.ck,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,WR))],null),$CLJS.yd,"Schema for a valid temporal-extract clause.",$CLJS.m(BT)?BT.J:null])):null));return $CLJS.m(a)?a:MQ}(),BT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof CT?new $CLJS.ud(function(){return CT},$CLJS.K(Fia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OO,"metabase/mbql/schema.cljc",71,$CLJS.FI,1,691,691,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FI,wQ,$CLJS.X($CLJS.Cl,pS)],null),$CLJS.yd,"Schema for a valid get-year clause.",$CLJS.m(CT)?CT.J:null])):null));return $CLJS.m(a)?a:OO}(),CT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof DT?new $CLJS.ud(function(){return DT},$CLJS.K(sia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),
$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KQ,"metabase/mbql/schema.cljc",74,$CLJS.TK,1,694,694,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.TK,wQ,$CLJS.X($CLJS.Cl,pS)],null),$CLJS.yd,"Schema for a valid get-quarter clause.",
$CLJS.m(DT)?DT.J:null])):null));return $CLJS.m(a)?a:KQ}(),DT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof ET?new $CLJS.ud(function(){return ET},$CLJS.K(cja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(rR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZQ,"metabase/mbql/schema.cljc",72,$CLJS.ZH,1,697,697,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZH,wQ,$CLJS.X($CLJS.Cl,pS)],null),$CLJS.yd,"Schema for a valid get-month clause.",$CLJS.m(ET)?ET.J:null])):null));return $CLJS.m(a)?a:ZQ}(),ET],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==
typeof mO&&"undefined"!==typeof FT?new $CLJS.ud(function(){return FT},$CLJS.K(cka,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YQ,"metabase/mbql/schema.cljc",71,$CLJS.dK,1,700,700,
!0,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.dK,wQ,$CLJS.X($CLJS.Cl,pS),$CLJS.ck,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,WR))],null),$CLJS.yd,"Schema for a valid get-week clause.",$CLJS.m(FT)?FT.J:null])):null));return $CLJS.m(a)?a:YQ}(),FT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof GT?new $CLJS.ud(function(){return GT},$CLJS.K(Gha,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,
$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",70,$CLJS.bK,1,704,704,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.bK,wQ,$CLJS.X($CLJS.Cl,pS)],null),$CLJS.yd,"Schema for a valid get-day clause.",$CLJS.m(GT)?GT.J:
null])):null));return $CLJS.m(a)?a:TQ}(),GT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof HT?new $CLJS.ud(function(){return HT},$CLJS.K(Wia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PQ,"metabase/mbql/schema.cljc",78,$CLJS.kK,1,707,707,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kK,wQ,$CLJS.X($CLJS.Cl,pS)],null),$CLJS.yd,"Schema for a valid get-day-of-week clause.",$CLJS.m(HT)?HT.J:null])):null));return $CLJS.m(a)?a:PQ}(),HT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&
"undefined"!==typeof IT?new $CLJS.ud(function(){return IT},$CLJS.K(Via,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sO,"metabase/mbql/schema.cljc",71,$CLJS.xK,1,710,710,!0,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.xK,$CLJS.CJ,$CLJS.X($CLJS.Cl,pS)],null),$CLJS.yd,"Schema for a valid get-hour clause.",$CLJS.m(IT)?IT.J:null])):null));return $CLJS.m(a)?a:sO}(),IT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof JT?new $CLJS.ud(function(){return JT},$CLJS.K(Hja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,
IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jS,"metabase/mbql/schema.cljc",73,$CLJS.hK,1,713,713,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hK,$CLJS.CJ,$CLJS.X($CLJS.Cl,pS)],null),$CLJS.yd,"Schema for a valid get-minute clause.",$CLJS.m(JT)?JT.J:null])):null));return $CLJS.m(a)?a:jS}(),JT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof KT?new $CLJS.ud(function(){return KT},$CLJS.K(Ria,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),lQ,"metabase/mbql/schema.cljc",73,$CLJS.EI,1,716,716,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EI,$CLJS.CJ,$CLJS.X($CLJS.Cl,pS)],null),$CLJS.yd,"Schema for a valid get-second clause.",$CLJS.m(KT)?KT.J:null])):null));return $CLJS.m(a)?a:lQ}(),KT],null)]));
RT=gO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof dT?new $CLJS.ud(function(){return dT},$CLJS.K(vja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),cR,"metabase/mbql/schema.cljc",59,$CLJS.bI,1,600,600,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.bI,$CLJS.GD,$CLJS.X($CLJS.Cl,yR),$CLJS.TA,$CLJS.X($CLJS.Cl,nia),$CLJS.jD,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,sR))],null),$CLJS.yd,"Schema for a valid substring clause.",$CLJS.m(dT)?dT.J:null])):null));return $CLJS.m(a)?a:cR}(),dT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof fT?new $CLJS.ud(function(){return fT},
$CLJS.K(Zja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tR,"metabase/mbql/schema.cljc",54,$CLJS.CK,1,606,606,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CK,$CLJS.GD,$CLJS.X($CLJS.Cl,yR)],null),
$CLJS.yd,"Schema for a valid trim clause.",$CLJS.m(fT)?fT.J:null])):null));return $CLJS.m(a)?a:tR}(),fT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof hT?new $CLJS.ud(function(){return hT},$CLJS.K(Xia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,
1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gR,"metabase/mbql/schema.cljc",55,$CLJS.HK,1,612,612,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.HK,$CLJS.GD,$CLJS.X($CLJS.Cl,yR)],null),$CLJS.yd,"Schema for a valid ltrim clause.",$CLJS.m(hT)?hT.J:null])):null));return $CLJS.m(a)?a:gR}(),hT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&
"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof gT?new $CLJS.ud(function(){return gT},$CLJS.K(wja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zQ,"metabase/mbql/schema.cljc",
55,$CLJS.ZK,1,609,609,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZK,$CLJS.GD,$CLJS.X($CLJS.Cl,yR)],null),$CLJS.yd,"Schema for a valid rtrim clause.",$CLJS.m(gT)?gT.J:null])):null));return $CLJS.m(a)?a:zQ}(),gT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof kT?new $CLJS.ud(function(){return kT},$CLJS.K(Aha,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,
$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UR,"metabase/mbql/schema.cljc",57,$CLJS.WK,1,621,621,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.WK,$CLJS.GD,$CLJS.X($CLJS.Cl,yR),Oha,$CLJS.X($CLJS.Cl,$CLJS.RP),$CLJS.BF,$CLJS.X($CLJS.Cl,$CLJS.RP)],null),$CLJS.yd,"Schema for a valid replace clause.",
$CLJS.m(kT)?kT.J:null])):null));return $CLJS.m(a)?a:UR}(),kT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof jT?new $CLJS.ud(function(){return jT},$CLJS.K(zja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sS,"metabase/mbql/schema.cljc",55,$CLJS.bv,1,618,618,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.bv,$CLJS.GD,$CLJS.X($CLJS.Cl,yR)],null),$CLJS.yd,"Schema for a valid lower clause.",$CLJS.m(jT)?jT.J:null])):null));return $CLJS.m(a)?a:sS}(),jT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==
typeof mO&&"undefined"!==typeof iT?new $CLJS.ud(function(){return iT},$CLJS.K(bka,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GR,"metabase/mbql/schema.cljc",55,$CLJS.Su,1,615,615,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.Su,$CLJS.GD,$CLJS.X($CLJS.Cl,yR)],null),$CLJS.yd,"Schema for a valid upper clause.",$CLJS.m(iT)?iT.J:null])):null));return $CLJS.m(a)?a:GR}(),iT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof lT?new $CLJS.ud(function(){return lT},$CLJS.K(nja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,
IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tP,"metabase/mbql/schema.cljc",56,$CLJS.pI,1,624,624,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.pI,TR,$CLJS.X($CLJS.Cl,yR),SR,$CLJS.X($CLJS.Cl,yR),aP,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,yR))],null),$CLJS.yd,"Schema for a valid concat clause.",$CLJS.m(lT)?lT.J:null])):
null));return $CLJS.m(a)?a:tP}(),lT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof mT?new $CLJS.ud(function(){return mT},$CLJS.K(uja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.QQ,"null",$CLJS.lfa,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",74,$CLJS.PJ,1,627,627,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.PJ,$CLJS.GD,$CLJS.X($CLJS.Cl,yR),$CLJS.AF,$CLJS.X($CLJS.Cl,$CLJS.RP)],null),$CLJS.yd,"Schema for a valid regex-match-first clause.",$CLJS.m(mT)?mT.J:null])):null));return $CLJS.m(a)?a:MO}(),mT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&
"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof cT?new $CLJS.ud(function(){return cT},$CLJS.K(hR,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.QQ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wR,"metabase/mbql/schema.cljc",
58,$CLJS.RJ,1,597,597,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.RJ,TR,$CLJS.X($CLJS.Cl,rO),SR,$CLJS.X($CLJS.Cl,rO),aP,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.rk,rO))],null),$CLJS.yd,"Schema for a valid coalesce clause.",$CLJS.m(cT)?cT.J:null])):null));return $CLJS.m(a)?a:wR}(),cT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof tU?new $CLJS.ud(function(){return tU},$CLJS.K(vO,new $CLJS.k(null,
1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jQ,"metabase/mbql/schema.cljc",61,$CLJS.$I,1,916,916,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.$I,$CLJS.wC,$CLJS.X($CLJS.Cl,iP),$CLJS.Lk,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,
BQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(tU)?tU.J:null])):null));return $CLJS.m(a)?a:jQ}(),tU],null)]));$CLJS.uU=XN.l($CLJS.G([$CLJS.bg(fO,RS),VS,$CLJS.bg(fO,OS),PS,$CLJS.bg(fO,SS),$S,$CLJS.bg(fO,US),$CLJS.YS,$CLJS.bg(fO,$CLJS.$I),tU,$CLJS.Uu,$CLJS.MS]));vU=eO($CLJS.lD,$CLJS.G(["field",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.MS],null)]));wU=eO($CLJS.NJ,$CLJS.G(["field",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.MS],null)]));
xU=eO($CLJS.DJ,$CLJS.G(["field-or-expression",$CLJS.uU]));yU=eO($CLJS.RK,$CLJS.G(["field-or-expression",$CLJS.uU]));zU=eO($CLJS.NI,$CLJS.G(["field-or-expression",$CLJS.uU]));AU=eO($CLJS.tK,$CLJS.G(["field-or-expression",$CLJS.uU]));BU=eO($CLJS.ok,$CLJS.G(["field-or-expression",$CLJS.uU]));CU=eO($CLJS.Vl,$CLJS.G(["field-or-expression",$CLJS.uU]));DU=eO($CLJS.OK,$CLJS.G(["field-or-expression",$CLJS.uU,"pred",$CLJS.ST]));EU=eO($CLJS.fK,$CLJS.G(["pred",$CLJS.ST]));FU=eO($CLJS.ZJ,$CLJS.G(["pred",$CLJS.ST]));
GU=eO($CLJS.AK,$CLJS.G(["field-or-expression",$CLJS.uU]));HU=eO($CLJS.RI,$CLJS.G(["field-or-expression",$CLJS.uU]));IU=eO($CLJS.vK,$CLJS.G(["field-or-expression",$CLJS.uU]));JU=eO($CLJS.nJ,$CLJS.G(["field-or-expression",$CLJS.uU,"percentile",XS]));KU=eO($CLJS.bQ,$CLJS.G(["metric-id",$CLJS.KN($CLJS.G([$CLJS.ON,$CLJS.LN]))]));
LU=YN($CLJS.bg(fO,RS),VS,gO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof xU?new $CLJS.ud(function(){return xU},$CLJS.K(tja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gQ,"metabase/mbql/schema.cljc",60,$CLJS.DJ,1,955,955,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DJ,HR,$CLJS.X($CLJS.Cl,AR)],null),$CLJS.yd,"Schema for a valid avg clause.",$CLJS.m(xU)?xU.J:null])):null));return $CLJS.m(a)?a:gQ}(),xU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof yU?
new $CLJS.ud(function(){return yU},$CLJS.K(zia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NR,"metabase/mbql/schema.cljc",64,$CLJS.RK,1,956,956,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RK,
HR,$CLJS.X($CLJS.Cl,AR)],null),$CLJS.yd,"Schema for a valid cum-sum clause.",$CLJS.m(yU)?yU.J:null])):null));return $CLJS.m(a)?a:NR}(),yU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof zU?new $CLJS.ud(function(){return zU},$CLJS.K(qia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],
[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",65,$CLJS.NI,1,957,957,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.NI,HR,$CLJS.X($CLJS.Cl,AR)],null),$CLJS.yd,"Schema for a valid distinct clause.",$CLJS.m(zU)?zU.J:null])):null));return $CLJS.m(a)?a:kQ}(),zU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof GU?new $CLJS.ud(function(){return GU},$CLJS.K(Rja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.lK,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),uO,"metabase/mbql/schema.cljc",76,$CLJS.AK,1,971,971,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.AK,HR,$CLJS.X($CLJS.Cl,AR)],null),$CLJS.yd,"Schema for a valid stddev clause.",$CLJS.m(GU)?GU.J:null])):null));return $CLJS.m(a)?a:uO}(),GU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof AU?new $CLJS.ud(function(){return AU},$CLJS.K(hja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,
$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EO,"metabase/mbql/schema.cljc",60,$CLJS.tK,1,958,958,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tK,HR,$CLJS.X($CLJS.Cl,AR)],null),$CLJS.yd,"Schema for a valid sum clause.",$CLJS.m(AU)?AU.J:null])):
null));return $CLJS.m(a)?a:EO}(),AU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof BU?new $CLJS.ud(function(){return BU},$CLJS.K(Kha,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,
[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.pl,"metabase/mbql/schema.cljc",60,$CLJS.ok,1,959,959,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ok,HR,$CLJS.X($CLJS.Cl,AR)],null),$CLJS.yd,"Schema for a valid min clause.",$CLJS.m(BU)?BU.J:null])):null));return $CLJS.m(a)?a:$CLJS.pl}(),BU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&
"undefined"!==typeof CU?new $CLJS.ud(function(){return CU},$CLJS.K(Fha,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Zl,"metabase/mbql/schema.cljc",60,$CLJS.Vl,1,960,960,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.Vl,HR,$CLJS.X($CLJS.Cl,AR)],null),$CLJS.yd,"Schema for a valid max clause.",$CLJS.m(CU)?CU.J:null])):null));return $CLJS.m(a)?a:$CLJS.Zl}(),CU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof KU?new $CLJS.ud(function(){return KU},$CLJS.K(mia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,
$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sP,"metabase/mbql/schema.cljc",26,$CLJS.bQ,1,989,989,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.bQ,Aia,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.vQ,$O,PR))],null),$CLJS.yd,"Schema for a valid metric clause.",$CLJS.m(KU)?KU.J:null])):null));return $CLJS.m(a)?a:sP}(),KU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==
typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof FU?new $CLJS.ud(function(){return FU},$CLJS.K(Pia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
mP,"metabase/mbql/schema.cljc",62,$CLJS.ZJ,1,968,968,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZJ,$CLJS.vl,$CLJS.X($CLJS.Cl,YP)],null),$CLJS.yd,"Schema for a valid share clause.",$CLJS.m(FU)?FU.J:null])):null));return $CLJS.m(a)?a:mP}(),FU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof EU?new $CLJS.ud(function(){return EU},$CLJS.K(Mha,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,
$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CO,"metabase/mbql/schema.cljc",68,$CLJS.fK,1,965,965,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fK,$CLJS.vl,$CLJS.X($CLJS.Cl,YP)],null),$CLJS.yd,"Schema for a valid count-where clause.",$CLJS.m(EU)?
EU.J:null])):null));return $CLJS.m(a)?a:CO}(),EU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof DU?new $CLJS.ud(function(){return DU},$CLJS.K(jka,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hQ,"metabase/mbql/schema.cljc",66,$CLJS.OK,1,962,962,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.OK,HR,$CLJS.X($CLJS.Cl,AR),$CLJS.vl,$CLJS.X($CLJS.Cl,YP)],null),$CLJS.yd,"Schema for a valid sum-where clause.",$CLJS.m(DU)?DU.J:null])):null));return $CLJS.m(a)?a:hQ}(),DU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&
"undefined"!==typeof mO&&"undefined"!==typeof tU?new $CLJS.ud(function(){return tU},$CLJS.K(vO,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jQ,"metabase/mbql/schema.cljc",61,$CLJS.$I,
1,916,916,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.$I,$CLJS.wC,$CLJS.X($CLJS.Cl,iP),$CLJS.Lk,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,BQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(tU)?tU.J:null])):null));return $CLJS.m(a)?a:jQ}(),tU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof IU?new $CLJS.ud(function(){return IU},$CLJS.K(Kja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,
$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MR,"metabase/mbql/schema.cljc",68,$CLJS.vK,1,977,977,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vK,HR,$CLJS.X($CLJS.Cl,AR)],null),$CLJS.yd,"Schema for a valid median clause.",$CLJS.m(IU)?IU.J:null])):
null));return $CLJS.m(a)?a:MR}(),IU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof JU?new $CLJS.ud(function(){return JU},$CLJS.K(bja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,
[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qR,"metabase/mbql/schema.cljc",72,$CLJS.nJ,1,980,980,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.nJ,HR,$CLJS.X($CLJS.Cl,AR),$CLJS.nJ,$CLJS.X($CLJS.Cl,sR)],null),$CLJS.yd,"Schema for a valid percentile clause.",$CLJS.m(JU)?JU.J:null])):null));return $CLJS.m(a)?a:qR}(),JU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&
"undefined"!==typeof mO&&"undefined"!==typeof HU?new $CLJS.ud(function(){return HU},$CLJS.K(Wha,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tO,"metabase/mbql/schema.cljc",77,$CLJS.RI,1,974,974,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RI,HR,$CLJS.X($CLJS.Cl,
AR)],null),$CLJS.yd,"Schema for a valid var clause.",$CLJS.m(HU)?HU.J:null])):null));return $CLJS.m(a)?a:tO}(),HU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof wU?new $CLJS.ud(function(){return wU},$CLJS.K(Hha,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,
new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZP,"metabase/mbql/schema.cljc",74,$CLJS.NJ,1,945,945,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.NJ,$CLJS.FQ,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,YO))],null),$CLJS.yd,"Schema for a valid cum-count clause.",$CLJS.m(wU)?wU.J:null])):null));return $CLJS.m(a)?a:ZP}(),wU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof vU?new $CLJS.ud(function(){return vU},$CLJS.K(oia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([dQ,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IR,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.ak,"metabase/mbql/schema.cljc",70,$CLJS.lD,1,944,944,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.lD,$CLJS.FQ,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.zN,YO))],null),$CLJS.yd,"Schema for a valid count clause.",$CLJS.m(vU)?vU.J:null])):null));return $CLJS.m(a)?a:$CLJS.ak}(),vU],null)])));
MU=$N(new $CLJS.ud(function(){return LU},aka,$CLJS.Sh([$CLJS.lm,$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[!0,$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dka,"metabase/mbql/schema.cljc",35,1,995,995,$CLJS.yd,null,$CLJS.m(LU)?LU.J:null])));Jka=$CLJS.Pf([TN($CLJS.V),$CLJS.LN,TN($CLJS.UI),$CLJS.LN,$CLJS.HN,$CLJS.CN]);
$CLJS.NU=eO($CLJS.pO,$CLJS.G(["aggregation",MU,"options",Jka]));$CLJS.WS=YN($CLJS.bg(fO,$CLJS.pO),$CLJS.NU,MU);OU=eO($CLJS.GJ,$CLJS.G(["field",$CLJS.NS]));PU=eO($CLJS.tJ,$CLJS.G(["field",$CLJS.NS]));
$CLJS.QU=gO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof OU?new $CLJS.ud(function(){return OU},$CLJS.K(Nja,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KP,"metabase/mbql/schema.cljc",15,$CLJS.GJ,1,1033,1033,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GJ,$CLJS.FQ,$CLJS.X($CLJS.Cl,nP)],null),$CLJS.yd,"Schema for a valid asc clause.",$CLJS.m(OU)?OU.J:null])):null));return $CLJS.m(a)?a:KP}(),OU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof PU?new $CLJS.ud(function(){return PU},$CLJS.K(yia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),
$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iR,"metabase/mbql/schema.cljc",16,$CLJS.tJ,1,1034,1034,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tJ,$CLJS.FQ,$CLJS.X($CLJS.Cl,nP)],null),$CLJS.yd,"Schema for a valid desc clause.",$CLJS.m(PU)?PU.J:null])):null));return $CLJS.m(a)?a:iR}(),PU],null)]));
VN.l($CLJS.G([$CLJS.mI,$CLJS.QI,$CLJS.dI,$CLJS.mz,$CLJS.HG,wQ]));RU=$CLJS.Pf([TN($CLJS.Ek),$CLJS.LN,$CLJS.V,$CLJS.LN,$CLJS.UI,$CLJS.LN,$CLJS.HN,$CLJS.CN]);Kka=$CLJS.cn.l($CLJS.G([RU,$CLJS.Pf([$CLJS.Tk,$CLJS.SM($CLJS.mI),$CLJS.fI,$CLJS.LN,kja,$CLJS.ON,TN($CLJS.NQ),$CLJS.ON])]));Lka=$CLJS.cn.l($CLJS.G([RU,new $CLJS.k(null,2,[$CLJS.Tk,$CLJS.SM($CLJS.QI),$CLJS.JK,$CLJS.ON],null)]));SU=$CLJS.cn.l($CLJS.G([RU,$CLJS.Pf([TN($CLJS.Pj),$CLJS.CN,TN(iia),$CLJS.EN])]));
Mka=$CLJS.cn.l($CLJS.G([SU,$CLJS.Pf([$CLJS.Tk,$CLJS.SM($CLJS.dI),$CLJS.dI,KS,$CLJS.dJ,$N(new $CLJS.ud(function(){return TU},$ja,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oka,"metabase/mbql/schema.cljc",34,1,1130,!0,1130,$CLJS.yd,null,$CLJS.m(TU)?TU.J:null]))),TN($CLJS.Lk),$CLJS.Pf([$CLJS.HN,
$CLJS.CN])])]));Nka=$CLJS.R.h(VN,new $CLJS.ni(null,new $CLJS.k(null,4,[wQ,null,$CLJS.mz,null,$CLJS.ik,null,$CLJS.HG,null],null),null));Oka=$CLJS.cn.l($CLJS.G([SU,new $CLJS.k(null,1,[$CLJS.Tk,Nka],null)]));Pka=XN.l($CLJS.G([function(a){return $CLJS.F.h($CLJS.Tk.g(a),$CLJS.dI)},Mka,function(a){return $CLJS.F.h($CLJS.Tk.g(a),$CLJS.mI)},Kka,function(a){return $CLJS.F.h($CLJS.Tk.g(a),$CLJS.QI)},Lka,$CLJS.Uu,Oka]));
Qka=$CLJS.YM($CLJS.Pf([$CLJS.LN,Pka]),function(a){return $CLJS.Wf(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.F.h(c,$CLJS.V.g(b))},a)},"keys in template tag map must match the :name of their values");UU=$CLJS.Pf([$CLJS.wO,$CLJS.CN,TN($CLJS.jR),Qka,TN(oP),$CLJS.bO($CLJS.LN),$CLJS.HN,$CLJS.CN]);
WU=YN($CLJS.Jm.h($CLJS.oe,$CLJS.VQ),$CLJS.RN(UU,new $CLJS.k(null,1,[$CLJS.wO,$CLJS.VQ],null)),$N(new $CLJS.ud(function(){return $CLJS.VU},Jia,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Lja,"metabase/mbql/schema.cljc",25,1,1212,!0,1212,$CLJS.yd,null,$CLJS.m($CLJS.VU)?$CLJS.VU.J:null]))));
XU=$CLJS.Pf([$CLJS.V,$CLJS.LN,$CLJS.uJ,$CLJS.MN,jja,$CLJS.LN,TN($CLJS.DP),$CLJS.bO($CLJS.NN),TN($CLJS.fP),$CLJS.bO($CLJS.vha),$CLJS.CN,$CLJS.CN]);$CLJS.YU=/^card__[1-9]\d*$/;ZU=$CLJS.KN($CLJS.G([$CLJS.ON,$CLJS.YU]));Rka=$CLJS.R.h(VN,new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.JR,null,$CLJS.nO,null,$CLJS.yQ,null,$CLJS.oR,null],null),null));
Ska=$CLJS.YM($CLJS.Sh([$CLJS.HN,TN($CLJS.PP),TN($CLJS.$P),TN($CLJS.xP),TN($CLJS.yO),$CLJS.bS,TN($CLJS.mR),TN($CLJS.kR),TN($CLJS.XK)],[$CLJS.CN,$CLJS.bO(new $CLJS.S(null,1,5,$CLJS.T,[XU],null)),ZU,$CLJS.LN,$CLJS.WM($CLJS.KN($CLJS.G([VN.l($CLJS.G([$CLJS.RD,$CLJS.wF])),$N(new $CLJS.ud(function(){return $U},Fja,$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,$CLJS.yk,$CLJS.Uk,eR,$CLJS.rm,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pja,"metabase/mbql/schema.cljc",16,1,1259,!0,1259,$CLJS.yd,null,$CLJS.m($U)?$U.J:null])))])),"Valid Join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."),$CLJS.ST,WU,$CLJS.bO($CLJS.ON),Rka]),$CLJS.Jm.h($CLJS.Km.h($CLJS.$P,$CLJS.mR),$CLJS.$f($CLJS.Jm.h($CLJS.$P,$CLJS.mR))),"Joins must have either a `source-table` or `source-query`, but not both.");
Tka=$CLJS.YM(hO(new $CLJS.S(null,1,5,$CLJS.T,[Ska],null)),function(a){return iO($CLJS.ug($CLJS.Hb,$CLJS.rg.h($CLJS.xP,a)))},"All join aliases must be unique.");$U=$CLJS.WM($CLJS.YM(hO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.MS],null)),iO,"distinct"),"Distinct, non-empty sequence of Field clauses");
$CLJS.VU=$CLJS.YM($CLJS.YM($CLJS.Sh([$CLJS.HN,TN($CLJS.$R),TN($CLJS.PP),TN($CLJS.WO),TN($CLJS.$P),TN($CLJS.KO),TN($CLJS.yO),TN($CLJS.OP),TN($CLJS.HP),TN($CLJS.mR),TN($CLJS.tS),TN(WQ),TN($CLJS.QQ)],[$CLJS.CN,$CLJS.YM(hO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.QU],null)),iO,"distinct"),$CLJS.bO(new $CLJS.S(null,1,5,$CLJS.T,[XU],null)),Tka,ZU,$CLJS.ST,$U,hO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.WS],null)),hO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.MS],null)),WU,$CLJS.PN,new $CLJS.k(null,2,[WQ,$CLJS.ON,bia,$CLJS.ON],
null),$CLJS.Pf([$CLJS.LN,$CLJS.uU])]),function(a){return $CLJS.F.h(1,$CLJS.E($CLJS.li(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.mR,$CLJS.$P],null))))},"Query must specify either `:source-table` or `:source-query`, but not both."),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.HP);b=$CLJS.M.h(b,$CLJS.yO);return $CLJS.je($CLJS.Fz.h($CLJS.si(a),$CLJS.si(b)))},"Fields specified in `:breakout` should not be specified in `:fields`; this is implied.");
aV=$CLJS.Sh([$CLJS.FR,wQ,lP,eP,QP,nS,AP,$CLJS.mS,$CLJS.mz,zP,xO,xR,cS,$CLJS.GO,ZR,kP,$CLJS.WP,yP,$CLJS.Ek,$CLJS.wP,qO,$CLJS.TO,$CLJS.vP,sQ,$CLJS.ik,$CLJS.HG,qP],[new $CLJS.k(null,1,[JP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.FR,null,wQ,null,$CLJS.mz,null,$CLJS.ik,null,$CLJS.HG,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Tk,wQ,JP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.FR,null,wQ,null,$CLJS.mS,null,$CLJS.WP,null,$CLJS.Ek,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Tk,$CLJS.Ll,
$CLJS.QJ,ER,JP,new $CLJS.ni(null,new $CLJS.k(null,1,[lP,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Tk,qS,$CLJS.QJ,ER,JP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.FR,null,eP,null,$CLJS.mz,null,$CLJS.Ek,null,qO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Tk,$CLJS.Ll,$CLJS.QJ,AO,JP,new $CLJS.ni(null,new $CLJS.k(null,1,[QP,null],null),null)],null),new $CLJS.k(null,1,[JP,new $CLJS.ni(null,new $CLJS.k(null,1,[nS,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Tk,qS,$CLJS.QJ,Dja,JP,new $CLJS.ni(null,
new $CLJS.k(null,1,[AP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Tk,wQ,JP,new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.mS,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Tk,qS,JP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.FR,null,eP,null,$CLJS.mz,null,$CLJS.Ek,null,qO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Tk,qS,$CLJS.QJ,AO,JP,new $CLJS.ni(null,new $CLJS.k(null,1,[zP,null],null),null)],null),new $CLJS.k(null,1,[JP,new $CLJS.ni(null,new $CLJS.k(null,1,[xO,null],null),null)],
null),new $CLJS.k(null,3,[$CLJS.Tk,$CLJS.Ll,$CLJS.QJ,ER,JP,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.FR,null,nS,null,xO,null,xR,null,yP,null,$CLJS.Ek,null,qO,null,$CLJS.HG,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Tk,$CLJS.Ll,$CLJS.QJ,AO,JP,new $CLJS.ni(null,new $CLJS.k(null,1,[cS,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Tk,wQ,JP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.mS,null,$CLJS.GO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Tk,$CLJS.Ll,$CLJS.QJ,AO,JP,new $CLJS.ni(null,
new $CLJS.k(null,1,[ZR,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Tk,$CLJS.Ll,$CLJS.QJ,AO,JP,new $CLJS.ni(null,new $CLJS.k(null,1,[kP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Tk,wQ,JP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.FR,null,wQ,null,$CLJS.mS,null,$CLJS.WP,null,$CLJS.Ek,null],null),null)],null),new $CLJS.k(null,1,[JP,new $CLJS.ni(null,new $CLJS.k(null,1,[yP,null],null),null)],null),new $CLJS.k(null,1,[JP,new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Ek,null],null),null)],
null),new $CLJS.k(null,2,[$CLJS.Tk,wQ,JP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.mS,null,$CLJS.wP,null],null),null)],null),new $CLJS.k(null,1,[JP,new $CLJS.ni(null,new $CLJS.k(null,1,[qO,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Tk,wQ,JP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.mS,null,$CLJS.TO,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Tk,wQ,JP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.mS,null,$CLJS.vP,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Tk,qS,$CLJS.QJ,ER,
JP,new $CLJS.ni(null,new $CLJS.k(null,1,[sQ,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Tk,$CLJS.ik,JP,new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.FR,null,$CLJS.Ek,null,$CLJS.ik,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Tk,$CLJS.Ll,JP,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.FR,null,nS,null,xO,null,xR,null,yP,null,$CLJS.Ek,null,qO,null,$CLJS.HG,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Tk,qS,$CLJS.QJ,AO,JP,new $CLJS.ni(null,new $CLJS.k(null,1,[qP,null],null),null)],null)]);
Uka=$CLJS.R.h(VN,$CLJS.gi(aV));TU=$CLJS.R.h(VN,$CLJS.nf($CLJS.wF,$CLJS.gi(aV)));bV=eO(Sha,$CLJS.G(["tag-name",$CLJS.KN($CLJS.G([$CLJS.LN,new $CLJS.k(null,1,[$CLJS.Ek,$CLJS.LN],null)]))]));cV=eO($CLJS.dI,$CLJS.G(["target",$CLJS.KN($CLJS.G([$CLJS.MS,bV]))]));dV=eO(uP,$CLJS.G(["target",bV]));
Vka=$CLJS.KN($CLJS.G([$CLJS.MS,gO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof cV?new $CLJS.ud(function(){return cV},$CLJS.K($ia,new $CLJS.k(null,1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$Q,"metabase/mbql/schema.cljc",21,$CLJS.dI,1,1557,1557,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.dI,$CLJS.aL,$CLJS.X($CLJS.Cl,$CLJS.X($CLJS.vQ,YO,IP))],null),$CLJS.yd,"Schema for a valid dimension clause.",$CLJS.m(cV)?cV.J:null])):null));return $CLJS.m(a)?a:$Q}(),cV],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=eQ.g($CLJS.fe("undefined"!==typeof $CLJS.kO&&"undefined"!==typeof lO&&"undefined"!==typeof mO&&"undefined"!==typeof dV?new $CLJS.ud(function(){return dV},$CLJS.K(qja,new $CLJS.k(null,
1,[$CLJS.Nl,!0],null)),$CLJS.Sh([$CLJS.Bk,$CLJS.V,$CLJS.bm,$CLJS.Zj,eQ,$CLJS.yk,$CLJS.Uk,$CLJS.rm,IO,$CLJS.Yj,$CLJS.W,$CLJS.am],[$CLJS.K(rR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rS,"metabase/mbql/schema.cljc",20,uP,1,1560,1560,new $CLJS.S(null,3,5,$CLJS.T,[uP,$CLJS.aL,$CLJS.X($CLJS.Cl,IP)],null),$CLJS.yd,"Schema for a valid variable clause.",$CLJS.m(dV)?dV.J:null])):null));return $CLJS.m(a)?a:rS}(),
dV],null)]))]));Wka=$CLJS.Pf([$CLJS.Tk,Uka,TN($CLJS.Ek),$CLJS.LN,TN($CLJS.aL),Vka,TN($CLJS.ol),$CLJS.CN,TN($CLJS.V),$CLJS.LN,TN(Zha),$CLJS.LN,TN($CLJS.Pj),$CLJS.CN,$CLJS.HN,$CLJS.CN]);Xka=new $CLJS.S(null,1,5,$CLJS.T,[Wka],null);Yka=$CLJS.Pf([TN(hka),$CLJS.LN,$CLJS.HN,$CLJS.CN]);Zka=$CLJS.YM($CLJS.Pf([TN(iQ),$CLJS.PN,TN(DO),$CLJS.PN,$CLJS.HN,$CLJS.CN]),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,iQ);b=$CLJS.M.h(b,DO);return $CLJS.Gb($CLJS.m(a)?b:a)?!0:a>=b},"max-results-bare-rows must be less or equal to than max-results");
$ka=$CLJS.Pf([TN(Mja),$CLJS.EN,TN(Uha),$CLJS.EN,TN(Bha),$CLJS.EN,TN(aia),$CLJS.EN,TN(Eja),$CLJS.bO($CLJS.EN),TN(Eha),$CLJS.bO($CLJS.EN),TN(Tja),$CLJS.bO($CLJS.EN),$CLJS.HN,$CLJS.CN]);ala=VN.l($CLJS.G([mja,oP,aja,rja,Mia,Pha,Vha,hia,xia,Aja,rka,Bia,tia]));
bla=$CLJS.Sh([TN(ika),TN(Dia),TN($CLJS.pP),TN(Jja),TN(Kia),TN(Cha),TN(fka),TN($CLJS.JK),TN($CLJS.NO)],[$CLJS.bO($CLJS.LN),$CLJS.bO($CLJS.ON),$CLJS.bO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Pf([$CLJS.CN,$CLJS.CN])],null)),$CLJS.bO($CLJS.ON),$CLJS.bO($CLJS.ON),$CLJS.bO($CLJS.CN),$CLJS.bO($CLJS.Pf([$CLJS.CN,$CLJS.CN])),$CLJS.bO($CLJS.ON),$CLJS.bO(ala)]);cla=$CLJS.KN($CLJS.G([$CLJS.SM(-1337),$CLJS.ON]));
$CLJS.eV=$CLJS.YM($CLJS.YM($CLJS.YM($CLJS.Sh([$CLJS.HN,TN($CLJS.VQ),TN($CLJS.nR),TN($CLJS.$D),TN(Zia),TN(Oia),$CLJS.Tk,TN($CLJS.wO),$CLJS.NQ,TN($CLJS.PO)],[$CLJS.CN,UU,$CLJS.bO(Yka),$CLJS.bO(bla),$CLJS.bO(Zka),$CLJS.bO($ka),VN.l($CLJS.G([$CLJS.wO,$CLJS.VQ])),$CLJS.VU,cla,Xka]),$CLJS.Jm.h($CLJS.Km.h($CLJS.VQ,$CLJS.wO),$CLJS.$f($CLJS.Jm.h($CLJS.VQ,$CLJS.wO))),"Query must specify either `:native` or `:query`, but not both."),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.VQ);var c=$CLJS.M.h(b,$CLJS.wO);
b=$CLJS.M.h(b,$CLJS.Tk);b=b instanceof $CLJS.N?b.S:null;switch(b){case "native":return a;case "query":return c;default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}},"Native queries must specify `:native`; MBQL queries must specify `:query`."),$CLJS.$f($CLJS.PP),"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)");
(function(a){var b=$CLJS.cO(a);return function(c){var d=b(c);if($CLJS.m(d))throw $CLJS.zj($CLJS.WL("Value does not match schema: %s",$CLJS.G([$CLJS.aj.l($CLJS.G([d]))])),new $CLJS.k(null,4,[$CLJS.Tk,$CLJS.dha,$CLJS.$l,a,$CLJS.ol,c,$CLJS.Xu,d],null));return c}})($CLJS.eV);