import React from 'react';
import {t} from "ttag";
import {Link} from "react-router";
import Button from "metabase/core/components/Button/Button";
import Modal from "metabase/components/Modal";
import LogoIcon from "metabase/components/LogoIcon";

export type TFileUploadModalProps = {
  handleModalClose: ()=> void;
  errorText?: string;
  redirectPage?: string;
}

const FileUploadModal: React.FC<TFileUploadModalProps> = ({
 handleModalClose = () => {},
 errorText = undefined,
 redirectPage= undefined,
}) => {
  return (
    <Modal onClose={handleModalClose}>
      <div className="px4 pt4 pb2 text-centered relative">
        <div className="text-brand pb2">
          <LogoIcon height={48} />
        </div>
        <h2
          style={{ fontSize: "1.75em" }}
          className="text-dark"
        >{t`Upload finished`}{errorText ? t` with error!` : t` successful!`}</h2>

        {errorText && (
          <div className="pt2">
            <h3 className="mb1 text-error">
              {errorText}
            </h3>
          </div>
        )}
      </div>
      <div style={{ display: 'flex', gap: '20px', justifyContent: 'center'}}>
        <Button
          small
          onClick={handleModalClose}
          style={{ height: '50px'}} >
          {t`Close`}
        </Button>

        {redirectPage && (
          <Link to={redirectPage}>
            <Button
              primary
              small
              style={{ height: '50px'}} >
              {t`Go to database's page`}
            </Button>
          </Link>
        )}
      </div>

      <div
        style={{ borderWidth: "2px" }}
        className="pt2 mt4 h5 text-centered text-medium border-top"
      >
        <span className="block">
          <span>
            {t`You also can upload more files`}
          </span>
         </span>
      </div>
    </Modal>
  );
};

export default FileUploadModal;
