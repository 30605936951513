import { t } from "ttag";
import { assocIn } from "icepick";
import {
  getDefaultSize,
  getMinSize,
} from "metabase/visualizations/shared/utils/sizes";
import LineAreaBarChart from "../components/LineAreaBarChart";
import { waterfallRenderer } from "../lib/LineAreaBarRenderer";

import {
  GRAPH_DATA_SETTINGS,
  GRAPH_AXIS_SETTINGS,
  GRAPH_DISPLAY_VALUES_SETTINGS,
  GRAPH_ZOOM,
} from "../lib/settings/graph";
import { WATERFALL_COMMON_SETTINGS } from "../lib/settings/waterfallChart";

export default class WaterfallChart extends LineAreaBarChart {
  static uiName = t`Waterfall`;
  static identifier = "waterfall";
  static iconName = "waterfall";
  static noun = t`waterfall chart`;

  static minSize = getMinSize("waterfall");
  static defaultSize = getDefaultSize("waterfall");

  static maxMetricsSupported = 1;
  static maxDimensionsSupported = 1;

  static settings = {
    ...GRAPH_AXIS_SETTINGS,
    ...WATERFALL_COMMON_SETTINGS,
    ...GRAPH_DISPLAY_VALUES_SETTINGS,
    ...GRAPH_DATA_SETTINGS,
    ...GRAPH_ZOOM,
  };

  static renderer = waterfallRenderer;

  static placeholderSeries = assocIn(
    LineAreaBarChart.placeholderSeries,
    [0, "card", "display"],
    "waterfall",
  );
}
