import { t } from "ttag";
import { MOBILE_DEFAULT_CARD_HEIGHT } from "metabase/visualizations/shared/utils/sizes";
import { WidgetSetting } from "metabase-types/api/widgets/widget";
import { VisualizationSettigsKeysOfAllWidgets } from "./widgetsSettings";

interface DashcardVisualizationSettingValues {
  "card.background_color": string;
  "card.table_virtualization": boolean;
  "card.mobile_view_height": number;
  "card.background_image": string;
  "card.background_image_custom_position": boolean;
  "card.background_image_two_axis_position": string;
  "card.background_image_position":
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "center";
  "card.background_image_custom_size": boolean;
  "card.background_image_two_axis_size": string;
  "card.background_image_size": "contain" | "cover" | "auto";
  "card.background_image_repeat":
    | "repeat-x"
    | "repeat-y"
    | "repeat"
    | "space"
    | "round"
    | "no-repeat";
  "card.background_image_attachment": "scroll" | "fixed" | "local";
  "card.text_color": string;
}

export type DashcardVisualizationSettings = Record<
  keyof DashcardVisualizationSettingValues,
  WidgetSetting
>;

export const DASHCARD_VISUALIZATION_SETTINGS: DashcardVisualizationSettings = {
  "card.background_color": {
    title: t`Card Background Color`,
    widget: "colorWithStoreSettingsBackgroundSync",
    dashboard: true,
    useRawSeries: true,
    section: t`Card`,
  },

  "card.text_color": {
    title: t`Text Color`,
    widget: "colorWithStoreSettingsBackgroundSyncTextColor",
    dashboard: true,
    useRawSeries: true,
    section: t`Card`,
  },

  "card.table_virtualization": {
    title: t`Table with scroll`,
    widget: "toggle",
    section: t`Card`,
    default: false,
    dashboard: true,
    useRawSeries: true,
    getHiddenOnDashboardPanel: ([{ card }]) => card.display !== "table",
  },

  "card.mobile_view_height": {
    section: t`Display`,
    title: t`Height of card (mobile view)`,
    widget: "number",
    isValid: (_, vizSettings) => vizSettings["card.mobile_view_height"] >= 1,
    getProps: (_, vizSettings) =>
      vizSettings["card.mobile_view_height"] < 1
        ? 1
        : vizSettings["card.mobile_view_height"],
    default: MOBILE_DEFAULT_CARD_HEIGHT,
    dashboard: true,
  },

  "card.background_image": {
    title: t`Card background image`,
    section: t`Background image`,
    widget: "imageInput",
    dashboard: true,
    useRawSeries: true,
  },

  "card.background_image_custom_position": {
    title: t`Custom background image position`,
    section: t`Background image`,
    widget: "toggle",
    dashboard: true,
    useRawSeries: true,
    default: false,
    getHidden: (_, vizSettings) => !vizSettings["card.background_image"],
  },

  "card.background_image_two_axis_position": {
    title: t`Background image position`,
    section: t`Background image`,
    widget: "backgrounImageDimensions",
    dashboard: true,
    useRawSeries: true,
    enabledAuto: false,
    getHidden: (_, vizSettings) =>
      !vizSettings["card.background_image_custom_position"] ||
      !vizSettings["card.background_image"],
  },

  "card.background_image_position": {
    title: t`Background image position`,
    section: t`Background image`,
    widget: "select",
    dashboard: true,
    useRawSeries: true,
    props: {
      options: [
        { name: t`Top`, value: "top" },
        { name: t`Bottom`, value: "bottom" },
        { name: t`Left`, value: "left" },
        { name: t`Right`, value: "right" },
        { name: t`Center`, value: "center" },
      ],
    },
    default: "center",
    getHidden: (_, vizSettings) =>
      vizSettings["card.background_image_custom_position"] ||
      !vizSettings["card.background_image"],
  },

  "card.background_image_custom_size": {
    title: t`Custom background image size`,
    section: t`Background image`,
    widget: "toggle",
    dashboard: true,
    useRawSeries: true,
    default: false,
    getHidden: (_, vizSettings) => !vizSettings["card.background_image"],
  },

  "card.background_image_two_axis_size": {
    title: t`Background image size`,
    section: t`Background image`,
    widget: "backgrounImageDimensions",
    dashboard: true,
    useRawSeries: true,
    enabledAuto: true,
    getHidden: (_, vizSettings) =>
      !vizSettings["card.background_image_custom_size"] ||
      !vizSettings["card.background_image"],
  },

  "card.background_image_size": {
    title: t`Background image size`,
    section: t`Background image`,
    widget: "select",
    dashboard: true,
    useRawSeries: true,
    props: {
      options: [
        { name: t`Cover`, value: "cover" },
        { name: t`Contain`, value: "contain" },
        { name: t`Auto`, value: "auto" },
      ],
    },
    getHidden: (_, vizSettings) =>
      vizSettings["card.background_image_custom_size"] ||
      !vizSettings["card.background_image"],

    default: "contain",
  },

  "card.background_image_repeat": {
    title: t`Card background image repeat`,
    section: t`Background image`,
    widget: "select",
    dashboard: true,
    useRawSeries: true,
    props: {
      options: [
        { name: t`Repeat horizontally`, value: "repeat-x" },
        { name: t`Repeat vertically`, value: "repeat-y" },
        { name: t`Repeat`, value: "repeat" },
        { name: t`Space`, value: "space" },
        { name: t`Round`, value: "round" },
        { name: t`No repeat`, value: "no-repeat" },
      ],
    },
    default: "no-repeat",
    getHidden: (_, vizSettings) => !vizSettings["card.background_image"],
  },
  "card.background_image_attachment": {
    title: t`Card background image attachment`,
    section: t`Background image`,
    widget: "select",
    dashboard: true,
    useRawSeries: true,
    props: {
      options: [
        { name: t`Scroll`, value: "scroll" },
        { name: t`Fixed`, value: "fixed" },
        { name: t`Local`, value: "local" },
      ],
    },
    default: "scroll",
    getHidden: (_, vizSettings) => !vizSettings["card.background_image"],
  },
} as const;

export type DashboardSettings = {
  filters_display_mode?: "all" | "active" | "none";
  fullscreen_mode_as_default_in_mobile_view?: boolean;
  two_filters_on_row_in_mobile_view: boolean;
  text_color: string;
  background_color: string;
};

export type DashboardVisualizationSettings = Record<
  keyof DashboardSettings,
  WidgetSetting
>;

export const DASHBOARD_DEFAULT_VISUALIZATION_SETTINGS: DashboardVisualizationSettings =
  {
    filters_display_mode: {
      title: t`Dashboard filters display mode`,
      widget: "select",
      orientation: "vertical",
      default: "all",
      props: {
        options: [
          { name: t`Show all`, value: "all" },
          { name: t`Show active`, value: "active" },
          { name: t`Show none`, value: "none" },
        ],
      },
      dashboardDefaultVisualizationSettingId:
        "dashboard-default-filters-display-mode",
    },

    fullscreen_mode_as_default_in_mobile_view: {
      title: t`Set full screen mode as default (mobile view)`,
      widget: "toggle",
      orientation: "horizontal",
      default: true,
    },

    two_filters_on_row_in_mobile_view: {
      title: t`Two filters on row (mobile view)`,
      widget: "toggle",
      orientation: "horizontal",
      default: true,
      dashboardDefaultVisualizationSettingId:
        "dashboard-default-filters-display-mode",
    },

    background_color: {
      title: t`Dashboard Background Color`,
      widget: "color",
      orientation: "horizontal",
      dashboardDefaultVisualizationSettingId:
        "dashboard-default-background-color",
    },

    text_color: {
      title: t`Dashboard Text Color`,
      widget: "color",
      orientation: "horizontal",
      dashboardDefaultVisualizationSettingId: "dashboard-default-text-color",
    },
  };

export type DashboardDefaultVisualizationSettingsKeys =
  | keyof typeof DASHBOARD_DEFAULT_VISUALIZATION_SETTINGS
  | keyof typeof DASHCARD_VISUALIZATION_SETTINGS
  | VisualizationSettigsKeysOfAllWidgets;
