import React from 'react';
import {t} from "ttag";
import {Link} from "react-router";
import Button from "metabase/core/components/Button/Button";
import Modal from "metabase/components/Modal";
import LogoIcon from "metabase/components/LogoIcon";

export type TFileUploadModalProps = {
  handleModalClose: ()=> void;
  redirectPage?: string;
}

const FileRemovedModal: React.FC<TFileUploadModalProps> = ({
 handleModalClose = () => {},
 redirectPage= undefined,
}) => {
  return (
    <Modal onClose={handleModalClose}>
      <div className="px4 pt4 pb2 text-centered relative">
        <div className="text-brand pb2">
          <LogoIcon height={48} />
        </div>
        <h2
          style={{ fontSize: "1.75em" }}
          className="text-dark"
        >{t`Are you sure that you want to delete table?`}</h2>

      </div>
      <div style={{ display: 'flex', gap: '20px', justifyContent: 'center'}}>
        <Button
          small
          onClick={handleModalClose}
          style={{ height: '50px'}} >
          {t`No, close window`}
        </Button>

        {redirectPage && (
          <Link to={redirectPage}>
            <Button
              danger
              small
              style={{ height: '50px'}} >
              {t`Yes, remove this table`}
            </Button>
          </Link>
        )}
      </div>
    </Modal>
  );
};

export default FileRemovedModal;
