var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var oX,pX,xma,yma,zma,rX,sX,tX,uX,wX,Ama,yX;oX=function(a){switch(arguments.length){case 2:return $CLJS.pV(arguments[0],arguments[1]);case 3:return $CLJS.oV(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};pX=function(a){return["(?:",$CLJS.p.g($CLJS.R.h($CLJS.p,a)),")?"].join("")};xma=new $CLJS.N("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
yma=new $CLJS.N("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.qX=new $CLJS.N("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);zma=new $CLJS.N("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);rX=new $CLJS.N("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
sX=new $CLJS.N("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);tX=new $CLJS.N("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);uX=new $CLJS.N("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);$CLJS.vX=new $CLJS.N("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);
wX=new $CLJS.N("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);Ama=new $CLJS.N("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.xX=new $CLJS.N("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);yX=new $CLJS.N("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);
$CLJS.zX=new $CLJS.N("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.CL.o(null,$CLJS.GG,function(){return $CLJS.ll});$CLJS.kL(yX,$CLJS.ik);$CLJS.CL.o(null,$CLJS.NG,function(){return $CLJS.xl});$CLJS.kL(yX,$CLJS.ik);$CLJS.kL(Ama,$CLJS.St);$CLJS.CL.o(null,$CLJS.vG,function(){return $CLJS.fl});$CLJS.kL(yma,$CLJS.Rt);$CLJS.CL.o(null,$CLJS.tG,function(){return $CLJS.LK});$CLJS.kL(zma,$CLJS.Ll);
var Bma=[":\\d{2}",pX($CLJS.G(["\\.\\d{1,6}"]))].join(""),AX=["\\d{2}:\\d{2}",pX($CLJS.G([Bma]))].join(""),BX=["\\d{4}-\\d{2}-\\d{2}T",AX].join(""),CX=["(?:Z|(?:[+-]",AX,"))"].join(""),Cma=$CLJS.Fi("^\\d{4}-\\d{2}-\\d{2}$"),Dma=$CLJS.Fi(["^",AX,"$"].join("")),Ema=$CLJS.Fi(["^",AX,CX,"$"].join("")),Fma=$CLJS.Fi(["^",BX,"$"].join("")),Gma=$CLJS.Fi(["^",BX,CX,"$"].join(""));$CLJS.kL(tX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mt,new $CLJS.k(null,1,[$CLJS.il,"date string literal"],null),Cma],null));
$CLJS.kL(sX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mt,new $CLJS.k(null,1,[$CLJS.il,"local time string literal"],null),Dma],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mt,new $CLJS.k(null,1,[$CLJS.il,"offset time string literal"],null),Ema],null)],null));
$CLJS.kL(uX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mt,new $CLJS.k(null,1,[$CLJS.il,"local date time string literal"],null),Fma],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mt,new $CLJS.k(null,1,[$CLJS.il,"offset date time string literal"],null),Gma],null)],null));
$CLJS.CL.o(null,$CLJS.uG,function(a){return $CLJS.m($CLJS.pV?$CLJS.pV(uX,a):oX.call(null,uX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Pl,null,$CLJS.TJ,null],null),null):$CLJS.m($CLJS.pV?$CLJS.pV(tX,a):oX.call(null,tX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.PI,null,$CLJS.Pl,null],null),null):$CLJS.m($CLJS.pV?$CLJS.pV(sX,a):oX.call(null,sX,a))?new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.YK,null,$CLJS.Pl,null],null),null):$CLJS.Pl});$CLJS.kL($CLJS.vX,tX);$CLJS.kL(rX,sX);
$CLJS.kL($CLJS.zX,uX);$CLJS.kL(xma,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.zk,$CLJS.vX,rX,$CLJS.zX],null));$CLJS.DX=$CLJS.Fi("^\\d{4}-\\d{2}$");$CLJS.kL($CLJS.xX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mt,new $CLJS.k(null,1,[$CLJS.il,"year-month string literal"],null),$CLJS.DX],null));$CLJS.EX=$CLJS.Fi("^\\d{4}$");$CLJS.kL($CLJS.qX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Mt,new $CLJS.k(null,1,[$CLJS.il,"year string literal"],null),$CLJS.EX],null));
$CLJS.kL(wX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.yL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jk,$CLJS.zL],null)],null)],null));$CLJS.uV($CLJS.ol,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.bk,new $CLJS.k(null,1,[$CLJS.il,"Value :value clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,$CLJS.ol],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,wX],null),$CLJS.Lb],null));