import { t } from "ttag";
import _ from "underscore";
import * as XLSX from "xlsx";
import * as Urls from "metabase/lib/urls";
import api from "metabase/lib/api";
import { getCardKey } from "metabase/visualizations/lib/utils";
import { saveChartImage } from "metabase/visualizations/lib/save-chart-image";
import {
  DashboardId,
  DashCardId,
  Dataset,
  VisualizationSettings,
} from "metabase-types/api";
import { getTitles, multiLevelPivot } from "metabase/lib/data_grid";
import Question from "metabase-lib/Question";

type ConvertedItemValueForExcel = string | number | null;
type DataList = ConvertedItemValueForExcel[][];

type LeftHeaderItem = {
  depth: number;
  hasChildren: boolean;
  hasSubtotal: boolean;
  isCollapsed: boolean;
  maxDepthBelow: number;
  offset: number;
  path: string[];
  value: string;
  span: number;
};

type RowSectionElement = {
  value: string | number;
  rawValue: string | number;
  isSubtotal: boolean;
  isGrandTotal: boolean;
};

export interface DownloadQueryResultsOpts {
  type: string;
  question: Question;
  result: Dataset;
  dashboardId?: DashboardId;
  dashcardId?: DashCardId;
  uuid?: string;
  token?: string;
  params?: Record<string, unknown>;
  visualizationSettings?: VisualizationSettings;
}

type ObjWithStrVals = Record<string, string>;

interface DownloadQueryResultsParams {
  method: string;
  url: string;
  params: URLSearchParams;
}

export const downloadQueryResults =
  (opts: DownloadQueryResultsOpts) => async () => {
    if (opts.type === Urls.exportFormatPng) {
      await downloadChart(opts);
    } else {
      await downloadDataset(opts);
    }
  };

const downloadDataset = async (opts: DownloadQueryResultsOpts) => {
  const type = opts.type;
  if (type === Urls.exportOptionPivotXLSX) {
    const visualizationSettings = opts?.visualizationSettings || {};
    const data = opts.result?.data;
    const cardName =
      (visualizationSettings && visualizationSettings["card.title"]) || "card";

    const fileName = (
      cardName +
      "_pivot_" +
      new Date(Date.now()).toISOString() +
      ".xlsx"
    ).replaceAll(" ", "_");

    const dataOfPivotTableForExcel = getDataOfPivotTableForExcel({
      data,
      visualizationSettings,
    });

    if (dataOfPivotTableForExcel) {
      const { resultTable, tableMergeRange } = dataOfPivotTableForExcel;
      downloadWorkbook({
        data: resultTable,
        mergeRange: tableMergeRange,
        fileName: fileName,
      });
    }
  } else {
    const params = getDatasetParams(opts);
    const response = await getDatasetResponse(params);
    const fileName = getDatasetFileName(response.headers, type);

    const changedTitles = getChangeTitles(opts.visualizationSettings);
    const file = await response.blob();
    openSaveDialog({ fileName, file, type, changedTitles });
  }
};

const downloadChart = async ({ question }: DownloadQueryResultsOpts) => {
  const fileName = getChartFileName(question);
  const chartSelector = `[data-card-key='${getCardKey(question.id())}']`;
  await saveChartImage(chartSelector, fileName);
};

const getDatasetParams = ({
  type,
  question,
  dashboardId,
  dashcardId,
  uuid,
  token,
  params = {},
  result,
  visualizationSettings,
}: DownloadQueryResultsOpts): DownloadQueryResultsParams => {
  const cardId = question.id();
  const isSecureDashboardEmbedding = dashcardId != null && token != null;
  if (isSecureDashboardEmbedding) {
    return {
      method: "GET",
      url: `/api/embed/dashboard/${token}/dashcard/${dashcardId}/card/${cardId}/${type}`,
      params: new URLSearchParams(Urls.extractQueryParams(params)),
    };
  }

  const isDashboard = dashboardId != null && dashcardId != null;
  if (isDashboard) {
    return {
      method: "POST",
      url: `/api/dashboard/${dashboardId}/dashcard/${dashcardId}/card/${cardId}/query/${type}`,
      params: new URLSearchParams({
        parameters: JSON.stringify(result?.json_query?.parameters ?? []),
      }),
    };
  }

  const isPublicQuestion = uuid != null;
  if (isPublicQuestion) {
    return {
      method: "GET",
      url: Urls.publicQuestion(uuid, type),
      params: new URLSearchParams({
        parameters: JSON.stringify(result?.json_query?.parameters ?? []),
      }),
    };
  }

  const isEmbeddedQuestion = token != null;
  if (isEmbeddedQuestion) {
    // For whatever wacky reason the /api/embed endpoint expect params like ?key=value instead
    // of like ?params=<json-encoded-params-array> like the other endpoints do.
    return {
      method: "GET",
      url: Urls.embedCard(token, type),
      params: new URLSearchParams(window.location.search),
    };
  }

  const isSavedQuery = cardId != null;
  if (isSavedQuery) {
    return {
      method: "POST",
      url: `/api/card/${cardId}/query/${type}`,
      params: new URLSearchParams({
        parameters: JSON.stringify(result?.json_query?.parameters ?? []),
      }),
    };
  }

  return {
    url: `/api/dataset/${type}`,
    method: "POST",
    params: new URLSearchParams({
      query: JSON.stringify(_.omit(result?.json_query ?? {}, "constraints")),
      visualization_settings: JSON.stringify(visualizationSettings ?? {}),
    }),
  };
};

const getDatasetResponse = ({
  url,
  method,
  params,
}: DownloadQueryResultsParams) => {
  const requestUrl = new URL(api.basename + url, location.origin);

  if (method === "POST") {
    return fetch(requestUrl.href, { method, body: params });
  } else {
    return fetch(`${requestUrl.href}?${params}`);
  }
};

const getDatasetFileName = (headers: Headers, type: string) => {
  const header = headers.get("Content-Disposition") ?? "";
  const headerContent = decodeURIComponent(header);
  const fileNameMatch = headerContent.match(/filename="(?<fileName>.+)"/);

  return (
    fileNameMatch?.groups?.fileName ||
    `query_result_${new Date().toISOString()}.${type}`
  );
};

const getChartFileName = (question: Question) => {
  const name = question.displayName() ?? t`New question`;
  const date = new Date().toLocaleString();
  return `${name}-${date}.png`;
};

interface OpenSaveDialog {
  fileName: string;
  file: Blob;
  type: string;
  changedTitles: ObjWithStrVals;
}

function openSaveDialog({
  fileName,
  file,
  type,
  changedTitles,
}: OpenSaveDialog) {
  const fileType = file.type;

  const fileReader = new FileReader();
  fileReader.readAsArrayBuffer(file);

  fileReader.onload = async event => {
    const arrayBufferData = event.target?.result;

    if (!(arrayBufferData instanceof ArrayBuffer)) {
      return;
    }

    const workbook = getWorkBook(fileType, arrayBufferData);
    if (workbook) {
      workbook.SheetNames.forEach(sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        if (!worksheet) {
          return;
        }
        const refRange = worksheet["!ref"];
        if (refRange) {
          const range = XLSX.utils.decode_range(refRange);
          for (let colIndex = range.s.c; colIndex <= range.e.c; ++colIndex) {
            const address = XLSX.utils.encode_col(colIndex) + "1";

            const value = changedTitles[colIndex] || worksheet[address].v;

            if (!worksheet[address]) {
              continue;
            }

            worksheet[address].v = value;
            // need for xlsx
            worksheet[address].h = value;
            worksheet[address].w = value;
          }
        }
      });

      const newFile = createBlobFromExcelTable({
        workbook,
        fileType,
        bookType: type as XLSX.BookType,
      });
      downloadData(fileName, newFile);
    } else {
      downloadData(fileName, file);
    }
  };
}
interface CreateBlobFromExcelTable {
  workbook: XLSX.WorkBook;
  fileType: string;
  bookType: XLSX.BookType;
}

function createBlobFromExcelTable({
  workbook,
  fileType,
  bookType,
}: CreateBlobFromExcelTable) {
  const newFile = XLSX.write(workbook, { type: "array", bookType: bookType });
  return new Blob([newFile], {
    type: fileType,
  });
}

function downloadData(fileName: string, data: Blob) {
  const url = URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = url;
  link.target = "_blank";
  link.download = "file";
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();

  URL.revokeObjectURL(url);
  link.remove();
}

function getWorkBook(fileType: string, arrayBufferData: ArrayBuffer) {
  if (
    fileType ===
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    return XLSX.read(arrayBufferData, { type: "buffer" });
  } else if (fileType === "text/csv") {
    const text = new TextDecoder().decode(arrayBufferData);
    return XLSX.read(text, { type: "string" });
  } else {
    return null;
  }
}

interface DownloadWorkBook {
  data: DataList;
  mergeRange: XLSX.Range[];
  fileName: string;
}

function downloadWorkbook({ data, mergeRange, fileName }: DownloadWorkBook) {
  const newWorkbook = XLSX.utils.book_new();

  const workbookSheet = XLSX.utils.aoa_to_sheet(data);

  workbookSheet["!merges"] = mergeRange;

  const workbookColsWidths = [];
  for (let i = 0; i < data[0].length; i++) {
    const firtRowData = (data[0] && data[0][i]) || "";
    const secondRowData = (data[1] && data[1][i]) || "";
    const firstRowColumnWidth = getTextWidth(firtRowData.toString());
    const secondRowColumnWidth = getTextWidth(secondRowData.toString());
    workbookColsWidths.push({
      wpx:
        firstRowColumnWidth > secondRowColumnWidth
          ? firstRowColumnWidth
          : secondRowColumnWidth,
    });
  }
  workbookSheet["!cols"] = workbookColsWidths;

  XLSX.utils.book_append_sheet(newWorkbook, workbookSheet, "result");

  XLSX.writeFile(newWorkbook, fileName);
}

function getTextWidth(text: string) {
  const context = document.createElement("canvas").getContext("2d");
  if (context) {
    context.font = "12px arial";
    const width = context.measureText(text).width * 1.2;
    return width;
  }
  return 40;
}

interface GetTableData {
  rowCount: number;
  columnCount: number;
  getRowSection: (colIdx: number, rowIdx: number) => RowSectionElement[];
}

function getTableData({ rowCount, columnCount, getRowSection }: GetTableData) {
  const tableData = [];
  for (let rowIdx = 0; rowIdx < rowCount; rowIdx++) {
    const rowData = [];
    for (let colIdx = 0; colIdx < columnCount; colIdx++) {
      const rowSection = getRowSection(colIdx, rowIdx);
      const rowSectionValues = rowSection.map(
        (element: RowSectionElement) => element.value,
      );
      rowData.push(...rowSectionValues);
    }
    tableData.push(rowData);
  }
  return tableData;
}

interface GetTopTitlesData {
  topHeaderItems: LeftHeaderItem[];
  leftHeadersColumnsCount: number;
  columnCount: number;
}

function getTopTitlesData({
  topHeaderItems,
  leftHeadersColumnsCount,
  columnCount,
}: GetTopTitlesData) {
  const topTitleTopRow: ConvertedItemValueForExcel[] = [];
  const topTitleBottomRow: ConvertedItemValueForExcel[] = [];

  topHeaderItems.forEach(element => {
    if (element.depth === 0) {
      topTitleTopRow.push(element.value);
    } else {
      topTitleBottomRow.push(element.value);

      if (topTitleBottomRow.length > topTitleTopRow.length) {
        topTitleTopRow.push(null);
      }
    }
  });

  const topTitlesMergeRange: XLSX.Range[] = [];

  const topTitlesList = [topTitleTopRow];
  if (topTitleBottomRow.length > 0) {
    topTitlesList.push(topTitleBottomRow);

    const indent = topTitlesList[0].length / columnCount;
    for (let i = 0; i < topTitlesList[0].length; i += indent) {
      const colStartIdxRange = i + leftHeadersColumnsCount;
      const colEndIdxRange = i + leftHeadersColumnsCount + indent - 1;
      if (colStartIdxRange < colEndIdxRange) {
        topTitlesMergeRange.push({
          s: { r: 0, c: colStartIdxRange },
          e: { r: 0, c: colEndIdxRange },
        });
      }
    }
  }

  return { topTitlesList, topTitlesMergeRange };
}

interface GetLeftTitlesData {
  topLeftCornerTitles: string[];
  topHeaderRows: number;
  leftHeaderItems: LeftHeaderItem[];
}
function getLeftTitlesData({
  topHeaderRows,
  topLeftCornerTitles,
  leftHeaderItems,
}: GetLeftTitlesData) {
  const leftTitlesMergeRange: XLSX.Range[] = [];

  const leftTitlesList: DataList = [topLeftCornerTitles];
  if (topHeaderRows > 1) {
    leftTitlesList.push(
      topLeftCornerTitles.map((_, i) => {
        leftTitlesMergeRange.push({
          s: { r: 0, c: i },
          e: { r: 1, c: i },
        });
        return null;
      }),
    );
  }

  let currentOffset = 0;
  const colCount = topLeftCornerTitles.length;
  let currentRow: ConvertedItemValueForExcel[] = new Array(colCount).fill(null);
  const startRow = topHeaderRows;

  leftHeaderItems.forEach((item: LeftHeaderItem, index) => {
    const nextItem = leftHeaderItems[index + 1];

    if (currentOffset === item.offset) {
      currentRow[item.depth] = item.value;
      const startRowIdxRange = startRow + item.offset;
      const endRowIdxRange = startRow + item.offset + item.span - 1;

      if (startRowIdxRange < endRowIdxRange) {
        leftTitlesMergeRange.push({
          s: { r: startRowIdxRange, c: item.depth },
          e: { r: endRowIdxRange, c: item.depth },
        });
      }
    }

    if (!nextItem || currentOffset < nextItem.offset) {
      let pathLength = 1;
      if (item.path) {
        pathLength = item.path.length;
      }
      if (pathLength < colCount) {
        const rowIdxRange = startRow + item.offset;
        const colStartIdxRange = item.depth;
        const colEndIdxRange = item.depth + colCount - pathLength;

        leftTitlesMergeRange.push({
          s: { r: rowIdxRange, c: colStartIdxRange },
          e: {
            r: rowIdxRange,
            c: colEndIdxRange,
          },
        });
      }
      leftTitlesList.push(currentRow);
      currentOffset = nextItem?.offset || currentOffset + 1;
      currentRow = new Array(colCount).fill(null);
    }
  });
  return { leftTitlesMergeRange, leftTitlesList };
}

interface GetFullTable {
  leftTitlesList: DataList;
  topTitlesList: DataList;
  tableData: DataList;
  topHeaderRows: number;
}

function getFullTable({
  leftTitlesList,
  topTitlesList,
  tableData,
  topHeaderRows,
}: GetFullTable) {
  const resultTable = [];
  const resultRowsCount = leftTitlesList.length;
  let row = [];
  for (let rowIdx = 0; rowIdx < resultRowsCount; rowIdx++) {
    row.push(
      ...leftTitlesList[rowIdx],
      ...(rowIdx <= topTitlesList.length - 1
        ? topTitlesList[rowIdx]
        : tableData[rowIdx - topHeaderRows]),
    );
    resultTable.push(row);
    row = [];
  }
  return resultTable;
}

interface GetDataOfPivotTableForExcel {
  data: any;
  visualizationSettings: VisualizationSettings | undefined;
}

function getDataOfPivotTableForExcel({
  data,
  visualizationSettings,
}: GetDataOfPivotTableForExcel) {
  const pivoted = multiLevelPivot(data, visualizationSettings);

  if (!pivoted) {
    return null;
  }

  const {
    leftHeaderItems,
    topHeaderItems,
    rowCount,
    columnCount,
    rowIndex,
    getRowSection,
    rowIndexes,
    columnIndexes,
    valueIndexes,
  } = pivoted;

  const topLeftCornerTitles = rowIndexes.map((rowIndex: number) =>
    getTitles(data, visualizationSettings, rowIndex),
  ) as string[];

  const topHeaderRows =
    columnIndexes.length + (valueIndexes.length > 1 ? 1 : 0) || 1;
  const leftHeadersColumnsCount = rowIndex[0].length;

  const tableData = getTableData({ rowCount, columnCount, getRowSection });

  const { topTitlesList, topTitlesMergeRange } = getTopTitlesData({
    topHeaderItems,
    leftHeadersColumnsCount,
    columnCount,
  });

  const { leftTitlesList, leftTitlesMergeRange } = getLeftTitlesData({
    leftHeaderItems,
    topHeaderRows,
    topLeftCornerTitles,
  });

  const resultTable = getFullTable({
    leftTitlesList,
    tableData,
    topTitlesList,
    topHeaderRows,
  });

  const tableMergeRange: XLSX.Range[] = [];
  tableMergeRange.push(...topTitlesMergeRange);
  tableMergeRange.push(...leftTitlesMergeRange);

  return { resultTable, tableMergeRange };
}

function getChangeTitles(
  visualizationSettings: VisualizationSettings | undefined,
) {
  const changedTitles: ObjWithStrVals = {};

  if (visualizationSettings) {
    const changedColumnsTitle: ObjWithStrVals = {};
    const columnSettings = visualizationSettings.column_settings;
    const columnsKeysWithSettingsList = Object.keys(columnSettings);

    columnsKeysWithSettingsList.forEach(columnName => {
      const columnTitle = columnSettings[columnName].column_title as string;
      const parsedColumnName = JSON.parse(columnName);
      const columnFieldRef = parsedColumnName[1][1];
      changedColumnsTitle[columnFieldRef] = columnTitle;
    });

    const originalColsList = visualizationSettings?.["table.columns"];

    if (originalColsList) {
      originalColsList.forEach((col, index) => {
        const colFieldRef = col.fieldRef && col.fieldRef[1];
        if (colFieldRef && changedColumnsTitle[colFieldRef]) {
          changedTitles[index] = changedColumnsTitle[colFieldRef];
        }
      });
    }
  }

  return changedTitles;
}
