/* eslint-disable react/prop-types */
import { Component } from "react";
import PropTypes from "prop-types";
import { jt } from "ttag";
import MetabaseSettings from "metabase/lib/settings";
import ExternalLink from "metabase/core/components/ExternalLink";
import SettingHeader from "./SettingHeader";
import SettingInput from "./widgets/SettingInput";
import SettingNumber from "./widgets/SettingNumber";
import SettingPassword from "./widgets/SettingPassword";
import SettingRadio from "./widgets/SettingRadio";
import SettingToggle from "./widgets/SettingToggle";
import SettingSelect from "./widgets/SettingSelect";
import SettingText from "./widgets/SettingText";
import { settingToFormFieldId } from "./../../settings/utils";
import {
  SettingContent,
  SettingEnvVarMessage,
  SettingErrorMessage,
  SettingRoot,
  SettingWarningMessage,
} from "./SettingsSetting.styled";

const SETTING_WIDGET_MAP = {
  string: SettingInput,
  number: SettingNumber,
  password: SettingPassword,
  select: SettingSelect,
  radio: SettingRadio,
  boolean: SettingToggle,
  text: SettingText,
};

export default class SettingsSetting extends Component {
  static propTypes = {
    setting: PropTypes.object.isRequired,
    settingValues: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onChangeSetting: PropTypes.func,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
  };

  renderSetting(singleSetting, widgetProps) {
    const { errorMessage } = this.props;

    let Widget = singleSetting.widget || SETTING_WIDGET_MAP[singleSetting.type];
    if (!Widget) {
      console.warn(
        "No render method for setting type " +
          singleSetting.type +
          ", defaulting to string input.",
      );
      Widget = SettingInput;
    }

    return (
      <SettingRoot
        data-testid={`${singleSetting.key}-setting`}
        key={singleSetting.key}
      >
        {!singleSetting.noHeader && (
          <SettingHeader id={singleSetting.key} setting={singleSetting} />
        )}
        <SettingContent>
          {singleSetting.is_env_setting ? (
            <SettingEnvVarMessage>
              {jt`This has been set by the ${(
                <ExternalLink href={getEnvVarDocsUrl(singleSetting.env_name)}>
                  {singleSetting.env_name}
                </ExternalLink>
              )} environment variable.`}
            </SettingEnvVarMessage>
          ) : (
            <Widget id={singleSetting.key} {...widgetProps} />
          )}
        </SettingContent>
        {errorMessage && (
          <SettingErrorMessage>{errorMessage}</SettingErrorMessage>
        )}
        {singleSetting.warning && (
          <SettingWarningMessage>{singleSetting.warning}</SettingWarningMessage>
        )}
      </SettingRoot>
    );
  }

  render() {
    const { setting, settingValues } = this.props;
    const settingId = settingToFormFieldId(setting);

    const widgetProps = {
      ...setting.getProps?.(setting, settingValues),
      ...setting.props,
      ...this.props,
    };

    if (setting.settings) {
      return setting.settings.map(singleSetting => {
        const settingProps = {
          ...singleSetting,
          value: setting?.value ? setting.value[singleSetting?.key] : null,
        };

        const onChange = inputValue => {
          const currentValue = this.props.setting.originalValue;
          const newValue = {
            ...currentValue,
            [singleSetting.key]: inputValue,
          };
          this.props.onChange(newValue);
        };

        return this.renderSetting(singleSetting, {
          ...widgetProps,
          setting: settingProps,
          onChange,
        });
      });
    }

    return this.renderSetting(setting, { ...widgetProps, id: settingId });
  }
}

const getEnvVarDocsUrl = envName => {
  return MetabaseSettings.docsUrl(
    "configuring-metabase/environment-variables",
    envName?.toLowerCase(),
  );
};
