var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var fla,gla,hla,ila,jla,kla,lla;$CLJS.EV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);fla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);gla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);
$CLJS.FV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.GV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.HV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);
hla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);ila=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);jla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);
kla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.IV=new $CLJS.N("type","temporal-bucketing-option","type/temporal-bucketing-option",1870188464);$CLJS.JV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.KV=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
lla=new $CLJS.N("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var LV=new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.mn,$CLJS.on,$CLJS.ql,$CLJS.Uj,$CLJS.pn,$CLJS.ln,$CLJS.Jj],null),MV=$CLJS.si(LV),mla,ola,VV;$CLJS.kL(jla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.k(null,1,[$CLJS.il,"Valid date extraction unit"],null)],null),MV));var NV=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.xz,$CLJS.Vk,$CLJS.gl,$CLJS.Tj,$CLJS.Jj],null),OV=$CLJS.si(NV);
$CLJS.kL(ila,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.k(null,1,[$CLJS.il,"Valid date truncation unit"],null)],null),OV));$CLJS.PV=$CLJS.oh.j($CLJS.Lg,$CLJS.en.A(),$CLJS.qg.h(NV,LV));mla=$CLJS.si($CLJS.PV);$CLJS.kL($CLJS.HV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.k(null,1,[$CLJS.il,"Valid date bucketing unit"],null)],null),mla));var QV=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.nn,$CLJS.kn],null),RV=$CLJS.si(QV);
$CLJS.kL(hla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.k(null,1,[$CLJS.il,"Valid time extraction unit"],null)],null),RV));var SV=new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.tz,$CLJS.sz,$CLJS.uz,$CLJS.wz],null),TV=$CLJS.si(SV);$CLJS.kL(lla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.k(null,1,[$CLJS.il,"Valid time truncation unit"],null)],null),TV));
$CLJS.nla=$CLJS.oh.j($CLJS.Lg,$CLJS.Im.h($CLJS.Lm.g(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.sz,null,$CLJS.tz,null],null),null)),$CLJS.en.A()),$CLJS.qg.h(SV,QV));ola=$CLJS.si(QV);$CLJS.kL(gla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.k(null,1,[$CLJS.il,"Valid time bucketing unit"],null)],null),ola));$CLJS.UV=$CLJS.oh.j($CLJS.Lg,$CLJS.Im.h($CLJS.Lm.g(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.sz,null,$CLJS.tz,null],null),null)),$CLJS.en.A()),$CLJS.qg.l(SV,NV,$CLJS.G([QV,LV])));
VV=$CLJS.si($CLJS.UV);$CLJS.kL($CLJS.EV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.k(null,1,[$CLJS.il,"Valid datetime bucketing unit"],null)],null),VV));var pla=$CLJS.kf.h(VV,$CLJS.Pj);$CLJS.kL($CLJS.JV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.k(null,1,[$CLJS.il,"Valid temporal bucketing unit"],null)],null),pla));var qla=$CLJS.yz.h(OV,TV);
$CLJS.kL($CLJS.FV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.k(null,1,[$CLJS.il,"Valid datetime truncation unit"],null)],null),qla));$CLJS.WV=$CLJS.yz.h(MV,RV);$CLJS.kL(fla,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.k(null,1,[$CLJS.il,"Valid datetime extraction unit"],null)],null),$CLJS.WV));var XV=$CLJS.kf.h(OV,$CLJS.Jj);$CLJS.kL($CLJS.KV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.k(null,1,[$CLJS.il,"Valid date interval unit"],null)],null),XV));
$CLJS.kL($CLJS.GV,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.k(null,1,[$CLJS.il,"Valid time interval unit"],null)],null),TV));var rla=$CLJS.yz.h(XV,TV);$CLJS.kL($CLJS.oL,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.k(null,1,[$CLJS.il,"Valid datetime interval unit"],null)],null),rla));
$CLJS.kL(kla,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,$CLJS.IV],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jn,$CLJS.JV],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Pj,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),$CLJS.ik],null)],null));