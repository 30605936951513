var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./cljs.reader.js");
'use strict';var DF=function(a,b,c,d,e){this.jb=a;this.za=b;this.M=c;this.Wa=d;this.G=e;this.C=2565220111;this.K=0},EF=new $CLJS.N("tailrecursion.priority-map","not-found","tailrecursion.priority-map/not-found",-436727517);$CLJS.h=DF.prototype;$CLJS.h.ka=function(a,b){return $CLJS.M.h(this.za,b)};$CLJS.h.T=function(a,b,c){return $CLJS.M.j(this.za,b,c)};$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#tailrecursion.priority-map {",", ","}",c,this)};$CLJS.h.N=function(){return this.M};$CLJS.h.ea=function(){return $CLJS.E(this.za)};
$CLJS.h.Qc=function(){if(0===$CLJS.E(this.za))return null;var a=$CLJS.C(this.jb),b=$CLJS.C($CLJS.uc(a));return $CLJS.m(this.Wa)?new $CLJS.S(null,2,5,$CLJS.T,[b,this.za.g?this.za.g(b):this.za.call(null,b)],null):new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.tc(a)],null)};
$CLJS.h.Rc=function(){if(0===$CLJS.E(this.za))throw Error("Can't pop empty priority map");var a=$CLJS.C(this.jb),b=$CLJS.uc(a),c=$CLJS.C(b);a=$CLJS.tc(a);return $CLJS.F.h($CLJS.E(b),1)?new DF($CLJS.Am.h(this.jb,a),$CLJS.Am.h(this.za,c),this.M,this.Wa,null):new DF($CLJS.U.j(this.jb,a,$CLJS.Bm.h(b,c)),$CLJS.Am.h(this.za,c),this.M,this.Wa,null)};
$CLJS.h.Pc=function(){var a=this,b=this;return $CLJS.m(a.Wa)?$CLJS.A(function(){return function e(d){return new $CLJS.yf(null,function(){for(var f=d;;){var g=$CLJS.A(f);if(g){var l=g,n=$CLJS.C(l),q=$CLJS.J(n,0,null),u=$CLJS.J(n,1,null);if(g=$CLJS.A(function(v,x,y,B,H,I,Q){return function ha(aa){return new $CLJS.yf(null,function(){return function(){for(;;){var qa=$CLJS.A(aa);if(qa){if($CLJS.re(qa)){var Ea=$CLJS.$c(qa),kb=$CLJS.E(Ea),lb=$CLJS.Bf(kb);a:for(var Fb=0;;)if(Fb<kb){var Mb=$CLJS.be(Ea,Fb);
Mb=new $CLJS.S(null,2,5,$CLJS.T,[Mb,a.za.g?a.za.g(Mb):a.za.call(null,Mb)],null);lb.add(Mb);Fb+=1}else{Ea=!0;break a}return Ea?$CLJS.Ef($CLJS.Gf(lb),ha($CLJS.ad(qa))):$CLJS.Ef($CLJS.Gf(lb),null)}lb=$CLJS.C(qa);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[lb,a.za.g?a.za.g(lb):a.za.call(null,lb)],null),ha($CLJS.zd(qa)))}return null}}}(v,x,y,B,H,I,Q),null,null)}}(f,n,q,u,l,g,b)(u)))return $CLJS.qg.h(g,e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null}},null,null)}($CLJS.Pc(a.jb))}()):$CLJS.A(function(){return function e(d){return new $CLJS.yf(null,
function(){for(var f=d;;){var g=$CLJS.A(f);if(g){var l=g,n=$CLJS.C(l),q=$CLJS.J(n,0,null),u=$CLJS.J(n,1,null);if(g=$CLJS.A(function(v,x,y,B,H,I,Q){return function ha(aa){return new $CLJS.yf(null,function(qa,Ea,kb){return function(){for(;;){var lb=$CLJS.A(aa);if(lb){if($CLJS.re(lb)){var Fb=$CLJS.$c(lb),Mb=$CLJS.E(Fb),ac=$CLJS.Bf(Mb);a:for(var Tb=0;;)if(Tb<Mb){var Ub=$CLJS.be(Fb,Tb);ac.add(new $CLJS.S(null,2,5,$CLJS.T,[Ub,kb],null));Tb+=1}else{Fb=!0;break a}return Fb?$CLJS.Ef($CLJS.Gf(ac),ha($CLJS.ad(lb))):
$CLJS.Ef($CLJS.Gf(ac),null)}ac=$CLJS.C(lb);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[ac,kb],null),ha($CLJS.zd(lb)))}return null}}}(v,x,y,B,H,I,Q),null,null)}}(f,n,q,u,l,g,b)(u)))return $CLJS.qg.h(g,e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null}},null,null)}($CLJS.Pc(a.jb))}())};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return $CLJS.Hc(this.za,b)};$CLJS.h.wa=function(){return $CLJS.K($CLJS.FF,this.M)};
$CLJS.h.ya=function(a,b){a=this.za.h?this.za.h(b,EF):this.za.call(null,b,EF);if($CLJS.F.h(a,EF))return this;a=this.Wa.g?this.Wa.g(a):this.Wa.call(null,a);var c=this.jb.g?this.jb.g(a):this.jb.call(null,a);return $CLJS.F.h($CLJS.E(c),1)?new DF($CLJS.Am.h(this.jb,a),$CLJS.Am.h(this.za,b),this.M,this.Wa,null):new DF($CLJS.U.j(this.jb,a,$CLJS.Bm.h(c,b)),$CLJS.Am.h(this.za,b),this.M,this.Wa,null)};
$CLJS.h.Z=function(a,b,c){var d=$CLJS.M.j(this.za,b,null);if($CLJS.m(d)){if($CLJS.F.h(d,c))return this;a=this.Wa.g?this.Wa.g(c):this.Wa.call(null,c);d=this.Wa.g?this.Wa.g(d):this.Wa.call(null,d);var e=$CLJS.M.h(this.jb,d);return $CLJS.F.h($CLJS.E(e),1)?new DF($CLJS.U.j($CLJS.Am.h(this.jb,d),a,$CLJS.kf.h($CLJS.M.j(this.jb,a,$CLJS.oi),b)),$CLJS.U.j(this.za,b,c),this.M,this.Wa,null):new DF($CLJS.U.l(this.jb,d,$CLJS.Bm.h($CLJS.M.h(this.jb,d),b),$CLJS.G([a,$CLJS.kf.h($CLJS.M.j(this.jb,a,$CLJS.oi),b)])),
$CLJS.U.j(this.za,b,c),this.M,this.Wa,null)}a=this.Wa.g?this.Wa.g(c):this.Wa.call(null,c);return new DF($CLJS.U.j(this.jb,a,$CLJS.kf.h($CLJS.M.j(this.jb,a,$CLJS.oi),b)),$CLJS.U.j(this.za,b,c),this.M,this.Wa,null)};$CLJS.h.sa=function(a,b){return $CLJS.Ie(this.za,b)};
$CLJS.h.ba=function(){var a=this,b=this;return $CLJS.m(a.Wa)?$CLJS.A(function(){return function e(d){return new $CLJS.yf(null,function(){for(var f=d;;){var g=$CLJS.A(f);if(g){var l=g,n=$CLJS.C(l),q=$CLJS.J(n,0,null),u=$CLJS.J(n,1,null);if(g=$CLJS.A(function(v,x,y,B,H,I,Q){return function ha(aa){return new $CLJS.yf(null,function(){return function(){for(;;){var qa=$CLJS.A(aa);if(qa){if($CLJS.re(qa)){var Ea=$CLJS.$c(qa),kb=$CLJS.E(Ea),lb=$CLJS.Bf(kb);a:for(var Fb=0;;)if(Fb<kb){var Mb=$CLJS.be(Ea,Fb);
Mb=new $CLJS.S(null,2,5,$CLJS.T,[Mb,a.za.g?a.za.g(Mb):a.za.call(null,Mb)],null);lb.add(Mb);Fb+=1}else{Ea=!0;break a}return Ea?$CLJS.Ef($CLJS.Gf(lb),ha($CLJS.ad(qa))):$CLJS.Ef($CLJS.Gf(lb),null)}lb=$CLJS.C(qa);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[lb,a.za.g?a.za.g(lb):a.za.call(null,lb)],null),ha($CLJS.zd(qa)))}return null}}}(v,x,y,B,H,I,Q),null,null)}}(f,n,q,u,l,g,b)(u)))return $CLJS.qg.h(g,e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null}},null,null)}(a.jb)}()):$CLJS.A(function(){return function e(d){return new $CLJS.yf(null,
function(){for(var f=d;;){var g=$CLJS.A(f);if(g){var l=g,n=$CLJS.C(l),q=$CLJS.J(n,0,null),u=$CLJS.J(n,1,null);if(g=$CLJS.A(function(v,x,y,B,H,I,Q){return function ha(aa){return new $CLJS.yf(null,function(qa,Ea,kb){return function(){for(;;){var lb=$CLJS.A(aa);if(lb){if($CLJS.re(lb)){var Fb=$CLJS.$c(lb),Mb=$CLJS.E(Fb),ac=$CLJS.Bf(Mb);a:for(var Tb=0;;)if(Tb<Mb){var Ub=$CLJS.be(Fb,Tb);ac.add(new $CLJS.S(null,2,5,$CLJS.T,[Ub,kb],null));Tb+=1}else{Fb=!0;break a}return Fb?$CLJS.Ef($CLJS.Gf(ac),ha($CLJS.ad(lb))):
$CLJS.Ef($CLJS.Gf(ac),null)}ac=$CLJS.C(lb);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[ac,kb],null),ha($CLJS.zd(lb)))}return null}}}(v,x,y,B,H,I,Q),null,null)}}(f,n,q,u,l,g,b)(u)))return $CLJS.qg.h(g,e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null}},null,null)}(a.jb)}())};$CLJS.h.O=function(a,b){return new DF(this.jb,this.za,b,this.Wa,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
$CLJS.h.call=function(a){switch(arguments.length-1){case 1:return this.g(arguments[1]);case 2:return this.h(arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length-1)].join(""));}};$CLJS.h.apply=function(a,b){return this.call.apply(this,[this].concat($CLJS.Rb(b)))};$CLJS.h.g=function(a){return this.ka(null,a)};$CLJS.h.h=function(a,b){return this.T(null,a,b)};
$CLJS.FF=new DF(function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;a:for(b=$CLJS.A(0<b.length?new $CLJS.z(b.slice(0),0,null):null),d=$CLJS.an;;)if(b)c=$CLJS.D($CLJS.D(b)),d=$CLJS.U.j(d,$CLJS.C(b),$CLJS.Zd(b)),b=c;else break a;return d}(),$CLJS.P,$CLJS.P,$CLJS.Ye,null);$CLJS.M.h($CLJS.r($CLJS.CF),"tailrecursion.priority-map");
$CLJS.dj.D($CLJS.CF,$CLJS.U,"tailrecursion.priority-map",function(a){if($CLJS.oe(a))return $CLJS.oh.h($CLJS.FF,a);throw Error("Priority map literal expects a map for its elements.");});