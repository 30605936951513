var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var FX,GX,HX,JX,KX,LX,MX;FX=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);GX=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
HX=new $CLJS.N("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.IX=new $CLJS.N("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);JX=new $CLJS.N("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);
KX=new $CLJS.N("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);LX=new $CLJS.N("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);MX=new $CLJS.N("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.yV($CLJS.jJ,$CLJS.G([$CLJS.yu,$CLJS.mJ,$CLJS.St,$CLJS.oL]));$CLJS.CL.o(null,$CLJS.IX,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.DL(a);return $CLJS.le(a)?(a=$CLJS.Fz.h(a,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.PI,null,$CLJS.TJ,null],null),null)),$CLJS.F.h($CLJS.E(a),1)?$CLJS.C(a):a):a});$CLJS.nV($CLJS.IX,$CLJS.PL);
for(var NX=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.eK,$CLJS.MK],null)),OX=null,PX=0,QX=0;;)if(QX<PX){var RX=OX.$(null,QX);$CLJS.yV(RX,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.JL],null),$CLJS.St,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.oL],null)]));$CLJS.nV(RX,$CLJS.IX);QX+=1}else{var SX=$CLJS.A(NX);if(SX){var TX=SX;if($CLJS.re(TX)){var UX=$CLJS.$c(TX),Hma=$CLJS.ad(TX),Ima=UX,Jma=$CLJS.E(UX);NX=Hma;OX=Ima;PX=Jma}else{var VX=$CLJS.C(TX);$CLJS.yV(VX,$CLJS.G([new $CLJS.S(null,2,5,
$CLJS.T,[$CLJS.lq,$CLJS.JL],null),$CLJS.St,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.oL],null)]));$CLJS.nV(VX,$CLJS.IX);NX=$CLJS.D(TX);OX=null;PX=0}QX=0}else break}
for(var WX=$CLJS.A(new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.FI,$CLJS.ZH,$CLJS.bK,$CLJS.xK,$CLJS.hK,$CLJS.EI,$CLJS.TK],null)),XX=null,YX=0,ZX=0;;)if(ZX<YX){var Kma=XX.$(null,ZX);$CLJS.yV(Kma,$CLJS.G([$CLJS.yu,$CLJS.fl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.JL],null)],null)]));ZX+=1}else{var $X=$CLJS.A(WX);if($X){var aY=$X;if($CLJS.re(aY)){var bY=$CLJS.$c(aY),Lma=$CLJS.ad(aY),Mma=bY,Nma=$CLJS.E(bY);WX=Lma;XX=Mma;YX=Nma}else{var Oma=$CLJS.C(aY);$CLJS.yV(Oma,$CLJS.G([$CLJS.yu,
$CLJS.fl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.JL],null)],null)]));WX=$CLJS.D(aY);XX=null;YX=0}ZX=0}else break}$CLJS.yV($CLJS.iJ,$CLJS.G([$CLJS.yu,$CLJS.fl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.JL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.JL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.FV],null)]));
for(var cY=$CLJS.A(new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.TK,null,$CLJS.hK,null,$CLJS.xK,null,$CLJS.kK,null,$CLJS.EI,null,$CLJS.ZH,null,$CLJS.FI,null,$CLJS.bK,null],null),null)),dY=null,eY=0,fY=0;;)if(fY<eY){var Pma=dY.$(null,fY);$CLJS.yV(Pma,$CLJS.G([$CLJS.yu,$CLJS.fl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.JL],null)],null)]));fY+=1}else{var gY=$CLJS.A(cY);if(gY){var hY=gY;if($CLJS.re(hY)){var iY=$CLJS.$c(hY),Qma=$CLJS.ad(hY),Rma=iY,Sma=$CLJS.E(iY);
cY=Qma;dY=Rma;eY=Sma}else{var Tma=$CLJS.C(hY);$CLJS.yV(Tma,$CLJS.G([$CLJS.yu,$CLJS.fl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.JL],null)],null)]));cY=$CLJS.D(hY);dY=null;eY=0}fY=0}else break}$CLJS.kL(MX,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Yl,$CLJS.wI,$CLJS.rJ,$CLJS.JJ],null));
$CLJS.AV($CLJS.dK,$CLJS.G([$CLJS.yu,$CLJS.fl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.CJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.JL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ck,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,MX],null)],null)],null)],null)]));
$CLJS.kL(LX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,$CLJS.uL,$CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Yl,new $CLJS.k(null,2,[$CLJS.il,"valid timezone ID",$CLJS.Yu,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.ol);return["invalid timezone ID: ",$CLJS.aj.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.Pe($CLJS.Le,$CLJS.VL.tz.names()))],null));
$CLJS.AV($CLJS.vI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.CJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.JL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.aL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,LX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sF,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,LX],null)],null)],null)],null)]));
$CLJS.nV($CLJS.vI,$CLJS.IX);$CLJS.yV($CLJS.EJ,$CLJS.G([$CLJS.yu,$CLJS.XI]));$CLJS.kL(HX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.zL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Jt,new $CLJS.k(null,1,[$CLJS.il,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Xf(function(b){return $CLJS.PH(a,b)},new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PI,$CLJS.TJ],null))}],null)],null));
$CLJS.kL(FX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.yL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DG,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,HX],null)],null)],null)],null));
$CLJS.uV($CLJS.GI,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.rl,new $CLJS.k(null,1,[$CLJS.il,"valid :absolute-datetime clause"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,$CLJS.GI],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,FX],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Dt,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.rl,new $CLJS.k(null,1,[$CLJS.il,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,4,
5,$CLJS.T,[$CLJS.zk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.vX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.xX],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.qX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,$CLJS.Pj],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.HV],null)],null)],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.rl,new $CLJS.k(null,1,[$CLJS.il,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,$CLJS.nB],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.zX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,$CLJS.Pj],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.EV],null)],null)],null)],null)],null)],null));
$CLJS.CL.o(null,$CLJS.GI,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);a=$CLJS.F.h(b,$CLJS.nB)?$CLJS.F.h(a,$CLJS.Pj)?$CLJS.TJ:$CLJS.m($CLJS.pV($CLJS.HV,a))?$CLJS.PI:$CLJS.TJ:null;if($CLJS.m(a))return a;a="string"===typeof b?$CLJS.m($CLJS.Di($CLJS.DX,b))?$CLJS.PI:$CLJS.m($CLJS.Di($CLJS.EX,b))?$CLJS.PI:null:null;if($CLJS.m(a))return a;b=$CLJS.DL(b);b=$CLJS.le(b)?$CLJS.oh.j($CLJS.oi,$CLJS.tg(function(c){return $CLJS.PH(c,$CLJS.Il)}),b):b;return $CLJS.le(b)&&
$CLJS.F.h($CLJS.E(b),1)?$CLJS.C(b):b});$CLJS.kL(KX,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,$CLJS.nB],null),$CLJS.St],null));
$CLJS.AV($CLJS.sJ,$CLJS.G([$CLJS.yu,$CLJS.TJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uC,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,KX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jn,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.oL],null)],null)],null)],null)]));
$CLJS.yV($CLJS.ZD,$CLJS.G([$CLJS.yu,$CLJS.YK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.RL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.GV],null)]));$CLJS.kL(GX,new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.Yl,$CLJS.gJ,$CLJS.ln,$CLJS.pn,$CLJS.YI,$CLJS.VK,$CLJS.$K,$CLJS.on,$CLJS.mn,$CLJS.kn,$CLJS.nn,$CLJS.JI],null));$CLJS.kL(JX,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Yl,$CLJS.wI,$CLJS.rJ,$CLJS.JJ],null));
$CLJS.AV($CLJS.pK,$CLJS.G([$CLJS.yu,$CLJS.fl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.CJ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.JL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.jn,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,GX],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ck,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,JX],null)],null)],
null)],null)]));