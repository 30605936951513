import React, { ReactNode } from "react";
import {
  LayoutBody,
  LayoutIllustration,
  LayoutRoot,
} from "./FileUploadLayout.styled";

export interface HomeLayoutProps {
  children?: ReactNode;
}

const FileUploadLayout = ({
  children,
}: HomeLayoutProps): JSX.Element => {
  return (
    <LayoutRoot>
      <LayoutBody>{children}</LayoutBody>
    </LayoutRoot>
  );
};

export default FileUploadLayout;
