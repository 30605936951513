import { t } from "ttag";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { changeVizualizationSettings } from "metabase/dashboard/actions";
import { DashboardDefaultVisualizationSettingsKeys } from "metabase/visualizations/lib/settings/defaultSettings";
import { Dashboard } from "metabase-types/api";
import Button from "metabase/core/components/Button";
import { useDispatch } from "metabase/lib/redux";
import {
  DashboardInfoSidebarRoot,
  DashboardInfoSidebarSaveButtonSection,
} from "./DashboardInfoSidebar.styled";

export type ContextVisualizationSettings = Partial<
  Record<DashboardDefaultVisualizationSettingsKeys, unknown>
>;

type AddSettings = (newSettings: Partial<ContextVisualizationSettings>) => void;

const DashboardVisualizationSettingsContext = createContext<
  | { settings: ContextVisualizationSettings; addSettings: AddSettings }
  | undefined
>({ settings: {}, addSettings: () => undefined });

export const useDashboardVisualizationSettings = () => {
  const context = useContext(DashboardVisualizationSettingsContext);
  if (!context) {
    throw new Error("DashboardVisualizationSettingsContext not found");
  }
  return context;
};

interface Props {
  children?: React.ReactNode;
  dashboard: Dashboard;
}

export const DashboardVisualizationSettingsProvider = ({
  dashboard,
  children,
}: Props) => {
  const [settings, setSettings] = useState<ContextVisualizationSettings>(
    dashboard.visualization_settings || {},
  );

  const addSettings: AddSettings = newSettings => {
    setSettings(prevSettings => ({ ...prevSettings, ...newSettings }));
  };

  const [isHaveUnsavedChanges, setIsHaveUnsavedChanges] = useState(false);

  useEffect(() => {
    const settingsKeys = Object.keys(settings);
    const hasUnsavedChanges = settingsKeys.some(
      key =>
        settings[key as DashboardDefaultVisualizationSettingsKeys] !==
        dashboard?.visualization_settings?.[
          key as DashboardDefaultVisualizationSettingsKeys
        ],
    );

    setIsHaveUnsavedChanges(hasUnsavedChanges);
  }, [settings, dashboard.visualization_settings]);

  const dispatch = useDispatch();

  const dispatchChangeVisualizationSettings = useCallback(() => {
    dispatch(changeVizualizationSettings(settings));
    setIsHaveUnsavedChanges(false);
  }, [dispatch, settings]);

  return (
    <DashboardVisualizationSettingsContext.Provider
      value={{ settings, addSettings }}
    >
      {isHaveUnsavedChanges && (
        <DashboardInfoSidebarSaveButtonSection>
          <Button
            name="apply"
            onClick={() => {
              dispatchChangeVisualizationSettings();
            }}
            primary={true}
            small={true}
            style={{ marginBottom: 10, marginTop: 10 }}
          >
            {t`Save`}
          </Button>
        </DashboardInfoSidebarSaveButtonSection>
      )}
      <DashboardInfoSidebarRoot data-testid="sidebar-right">
        {children}
      </DashboardInfoSidebarRoot>
    </DashboardVisualizationSettingsContext.Provider>
  );
};
