/* eslint-disable react/prop-types */
import { Component } from "react";
import cx from "classnames";
import { t } from "ttag";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import _ from "underscore";
import { splice } from "icepick";

import Label from "metabase/components/type/Label";

import { HIDDEN_COLUMNS_SETTING } from "metabase/lib/data_grid";
import { getColumnKey } from "metabase-lib/queries/utils/get-column-key";
import {
  DroppableContainer,
  FieldPartitionColumn,
  EmptyColumnPlaceholder,
} from "./ChartSettingFieldsPartition.styled";

const columnMove = (columns, from, to) => {
  const columnCopy = [...columns];
  columnCopy.splice(to, 0, columnCopy.splice(from, 1)[0]);
  return columnCopy;
};

const columnRemove = (columns, from) => {
  return splice(columns, from, 1);
};

const columnAdd = (columns, to, column) => {
  return splice(columns, to, 0, column);
};

class ChartSettingFieldsPartition extends Component {
  constructor(props) {
    super(props);
  }

  handleEditFormatting = (column, targetElement) => {
    if (column) {
      this.props.onShowWidget(
        {
          id: "column_settings",
          props: {
            initialKey: getColumnKey(column),
          },
        },
        targetElement,
      );
    }
  };

  handleHidden = (field_ref, hide) => {
    const { onChangeSettings, settings } = this.props;
    if (hide) {
      onChangeSettings({
        [HIDDEN_COLUMNS_SETTING]: [
          ...settings[HIDDEN_COLUMNS_SETTING],
          field_ref,
        ],
      });
    } else {
      const hiddenColumns = settings[HIDDEN_COLUMNS_SETTING].filter(
        hidden_field_ref => !_.isEqual(hidden_field_ref, field_ref),
      );
      onChangeSettings({
        [HIDDEN_COLUMNS_SETTING]: hiddenColumns,
      });
    }
  };

  getPartitionType = partitionName => {
    switch (partitionName) {
      case "rows":
      case "columns":
        return "dimension";
      default:
        return "metric";
    }
  };

  handleDragEnd = ({ source, destination }) => {
    if (!source || !destination) {
      return;
    }
    const { value, onChange } = this.props;
    const { droppableId: sourcePartition, index: sourceIndex } = source;
    const { droppableId: destinationPartition, index: destinationIndex } =
      destination;

    if (
      sourcePartition === destinationPartition &&
      sourceIndex !== destinationIndex
    ) {
      onChange({
        ...value,
        [sourcePartition]: columnMove(
          value[sourcePartition],
          sourceIndex,
          destinationIndex,
        ),
      });
    } else if (sourcePartition !== destinationPartition) {
      const column = value[sourcePartition][sourceIndex];
      onChange({
        ...value,
        [sourcePartition]: columnRemove(value[sourcePartition], sourceIndex),
        [destinationPartition]: columnAdd(
          value[destinationPartition],
          destinationIndex,
          column,
        ),
      });
    }
  };

  render() {
    const value = _.mapObject(this.props.value || {}, fieldRefs =>
      fieldRefs
        .map(field_ref =>
          this.props.columns.find(col => _.isEqual(col.field_ref, field_ref)),
        )
        .filter(col => col != null),
    );

    const { getColumnTitle } = this.props;
    return (
      <DragDropContext onDragEnd={this.handleDragEnd}>
        {this.props.partitions.map(({ name: partitionName, title }, index) => {
          const columns = value[partitionName] ?? [];
          const partitionType = this.getPartitionType(partitionName);
          return (
            <div
              className={cx("py2", { "border-top": index > 0 })}
              key={partitionName}
            >
              <Label color="medium">{title}</Label>
              <Droppable droppableId={partitionName} type={partitionType}>
                {(provided, snapshot) => (
                  <DroppableContainer
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    isDragSource={!!snapshot.draggingFromThisWith}
                  >
                    {columns.length === 0 ? (
                      <EmptyColumnPlaceholder>{t`Drag fields here`}</EmptyColumnPlaceholder>
                    ) : (
                      columns.map((col, index) => (
                        <Draggable
                          key={`draggable-${col.display_name}`}
                          draggableId={`draggable-${col.display_name}`}
                          index={index}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="mb1"
                            >
                              <Column
                                key={`${partitionName}-${col.display_name}`}
                                partitionName={partitionName}
                                column={col}
                                index={index}
                                onEditFormatting={this.handleEditFormatting}
                                title={getColumnTitle(col)}
                                handleHidden={this.handleHidden}
                                settings={this.props.settings}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))
                    )}
                    {provided.placeholder}
                  </DroppableContainer>
                )}
              </Droppable>
            </div>
          );
        })}
      </DragDropContext>
    );
  }
}

class Column extends Component {
  constructor(props) {
    super(props);
  }

  handleEditFormatting = target => {
    const { column, onEditFormatting } = this.props;
    onEditFormatting && onEditFormatting(column, target);
  };

  render() {
    const { title, column, handleHidden, settings, partitionName } = this.props;

    let onRemove = undefined;
    let onAdd = undefined;
    let isHide = false;

    if (partitionName === "values") {
      isHide =
        settings[HIDDEN_COLUMNS_SETTING].find(field_ref =>
          _.isEqual(column.field_ref, field_ref),
        ) !== undefined;
      onRemove = isHide
        ? undefined
        : () => handleHidden(column.field_ref, true);
      onAdd = isHide ? () => handleHidden(column.field_ref, false) : undefined;
    }

    return (
      <FieldPartitionColumn
        title={title}
        onEdit={this.handleEditFormatting}
        draggable
        isDisabled={false}
        isHidden={isHide}
        onRemove={onRemove}
        onAdd={onAdd}
      />
    );
  }
}

export default ChartSettingFieldsPartition;
