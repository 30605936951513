import cx from "classnames";
import * as React from "react";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { t } from "ttag";

import type { Location } from "history";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import pptxgen from "pptxgenjs";

import _ from "underscore";
import { Dashboard } from "metabase-types/api";
import { getScrollY } from "metabase/lib/dom";

import EditBar from "metabase/components/EditBar";
import HeaderModal from "metabase/components/HeaderModal";

import DownloadMenu from "metabase/components/DownloadMenu";
import { updateDashboard } from "metabase/dashboard/actions";
import { useDispatch } from "metabase/lib/redux";
import { closeNavbar } from "metabase/redux/app";
import {
  EditWarning,
  HeaderBadges,
  HeaderButtonsContainer,
  HeaderButtonSection,
  HeaderCaption,
  HeaderCaptionContainer,
  HeaderContent,
  HeaderLastEditInfoLabel,
  HeaderRow,
  HeaderRowWrapper,
} from "../DashboardHeaderView.styled";
import { useDashboardTabs } from "../DashboardTabs";
import { DashboardTabs } from "../DashboardTabs/DashboardTabs";

interface DashboardHeaderViewProps {
  editingTitle: string;
  editingSubtitle: string;
  editingButtons: JSX.Element[];
  editWarning: string;
  headerButtons: React.ReactNode[];
  parametersWidget: React.ReactNode[];
  headerClassName: string;
  headerModalMessage: string;
  location: Location;
  isEditing: boolean;
  isEditingInfo: boolean;
  isNavBarOpen: boolean;
  dashboard: Dashboard;
  isBadgeVisible: boolean;
  isLastEditInfoVisible: boolean;
  onHeaderModalDone: () => null;
  onHeaderModalCancel: () => null;
  onLastEditInfoClick: () => null;
  setDashboardAttribute: (prop: string, value: string) => null;
}

interface Item {
  width: number;
  height: number;
  x: number;
  y: number;
  bottom: number;
  top: number;
  left: number;
  right: number;
  item: HTMLElement;
}

type orientationType = "p" | "portrait" | "l" | "landscape";

type ViewPPTType = "dashboard" | "widget";

type WidgetLine = {
  widgets: Item[];
  top: number;
  bottom: number;
};

type PDFGrid = WidgetLine[];

const convert2Canvas = async (elem: HTMLElement) => {
  let scale = 4;
  if (window.innerWidth > 1920) {
    scale = 2;
  }
  if (window.innerWidth > 2600) {
    scale = 1;
  }
  const result = html2canvas(elem as HTMLElement, {
    scale: scale,
    // useCORS: true,
    // width: width,
    // height: height,
  }).then(canvas => {
    return canvas;
  });
  return result;
};

const splitItemsByHeight = (items: Item[]) => {
  const linesArr: PDFGrid = [];

  // group by y
  for (const item of items) {
    let added = false;
    if (linesArr.length) {
      for (const line of linesArr) {
        if (item.top === line.top) {
          // Set height of line
          if (item.bottom > line.bottom) {
            line.bottom = item.bottom;
          }
          line.widgets.push(item);
          added = true;
          break;
        }
      }
    }
    if (!added || !linesArr.length) {
      linesArr.push({
        widgets: [item],
        top: item.top,
        bottom: item.bottom,
      });
    }
  }

  // Next step unite lines
  for (let i = 0; i < linesArr.length; i++) {
    for (let j = 0; j < linesArr.length; j++) {
      if (i === j) {
        continue;
      }

      const line = linesArr[i];
      const line2 = linesArr[j];

      if (line2.top > line.top && line2.top < line.bottom) {
        line.widgets.push(...line2.widgets);
        line2.widgets = [];
        if (line2.bottom > line.bottom) {
          line.bottom = line2.bottom;
        }
      }
    }
  }
  const grid: PDFGrid = linesArr.filter(line => line.widgets.length !== 0);

  return grid;
};

const renderDashboardOnSlidePPT = async (ppt: pptxgen, item: HTMLElement) => {
  const slide = ppt.addSlide();
  const canvas = await convert2Canvas(item);
  const imgData = canvas.toDataURL("image/png");
  const height = item.scrollHeight;
  const width = item.scrollWidth;

  if (height > width) {
    const ratio = (height / width) * (4 / 3);
    const w = 100 / ratio;

    slide.addImage({
      data: imgData,
      w: `${w}%`,
      h: `100%`,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sizing: {
        type: "contain",
      },
    });
  } else {
    const ratio = (width / height) * (3 / 4);
    const h = 100 / ratio;

    slide.addImage({
      data: imgData,
      w: `100%`,
      h: `${h}%`,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sizing: {
        type: "contain",
      },
    });
  }
  return ppt;
};

const renderWidgetsOnSlidesPPT = async (ppt: pptxgen, arr: PDFGrid) => {
  for (const line of arr) {
    for (const widget of line.widgets) {
      const slide = ppt.addSlide();
      const item = widget.item;
      const canvas = await convert2Canvas(item);
      const imgData = canvas.toDataURL("image/png");

      if (widget.height > widget.width) {
        const ratio = (widget.height / widget.width) * (4 / 3);
        const w = 100 / ratio;

        slide.addImage({
          data: imgData,
          w: `${w}%`,
          h: `100%`,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          sizing: {
            type: "contain",
          },
        });
      } else {
        const ratio = (widget.width / widget.height) * (3 / 4);
        const h = 100 / ratio;

        slide.addImage({
          data: imgData,
          w: `100%`,
          h: `${h}%`,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          sizing: {
            type: "contain",
          },
        });
      }
    }
  }
  return ppt;
};

const renderPDF = async (
  doc: jsPDF,
  arr: PDFGrid,
  pixelRatio: number,
  pageHeight: number,
) => {
  const padding = arr[0].top * pixelRatio;
  let offset = 0;

  for (const line of arr) {
    const top = line.top * pixelRatio - offset;
    const bottom = line.bottom * pixelRatio - offset;

    if (top > pageHeight || bottom > pageHeight) {
      doc.addPage();
      // Reset y, beacause we add new page
      offset = line.top * pixelRatio - padding;
    }

    for (const widget of line.widgets) {
      const item = widget.item;
      const canvas = await convert2Canvas(item);
      const imgData = canvas.toDataURL("image/png");

      // If widget bigger then page
      let sizeRatio = 1;
      if (widget.height * pixelRatio > pageHeight - 2 * padding) {
        sizeRatio = (pageHeight - 2 * padding) / (widget.height * pixelRatio);
      }

      doc.addImage(
        imgData,
        "PNG",
        widget.x * pixelRatio,
        widget.y * pixelRatio - offset,
        widget.width * pixelRatio * sizeRatio,
        widget.height * pixelRatio * sizeRatio,
        undefined,
        "FAST",
      );
    }
  }
  return doc;
};

const getRectParams = (elem: HTMLElement, wrapper: HTMLElement) => {
  if (elem) {
    const rect = elem.getBoundingClientRect();
    const body = wrapper.getBoundingClientRect();
    return {
      x: rect.x - body.x,
      y: rect.y - body.y + window.pageYOffset,
      width: rect.width,
      height: rect.height,
      top: rect.top - body.y + window.pageYOffset,
      left: rect.left - body.x,
      right: rect.right - body.x,
      bottom: rect.bottom - body.y + window.pageYOffset,
    };
  } else {
    return {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    };
  }
};

const widthA4mm = 210;
const heightA4mm = 297;
interface TabImage {
  imgData: string;
  height: number;
  width: number;
}

export function DashboardHeaderComponent({
  editingTitle = "",
  editingSubtitle = "",
  editingButtons = [],
  editWarning,
  headerButtons = [],
  headerClassName = "py1 lg-py2 xl-py3 wrapper",
  headerModalMessage,
  location,
  isEditing,
  isNavBarOpen,
  dashboard,
  isLastEditInfoVisible,
  onHeaderModalDone,
  onHeaderModalCancel,
  onLastEditInfoClick,
  setDashboardAttribute,
  parametersWidget = [],
}: DashboardHeaderViewProps) {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [showSubHeader, setShowSubHeader] = useState(true);
  const header = useRef<HTMLDivElement>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [orientation, setOrientation] = useState<orientationType>("portrait");
  const [viewPPT, setViewPPT] = useState<ViewPPTType>("widget");
  const [paramsShow, setParamsShow] = useState<boolean>(false);
  const [tabsImage, setTabsImage] = useState<TabImage[]>([]);
  const obs = useRef<any>();

  const {
    tabs,
    createNewTab,
    deleteTab,
    renameTab,
    selectTab,
    selectedTabId,
    moveTab,
  } = useDashboardTabs({ location });

  const isModalOpened = headerModalMessage != null;

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (isModalOpened) {
      const headerRect = header.current?.getBoundingClientRect();
      if (headerRect) {
        const headerHeight = headerRect.top + getScrollY();
        setHeaderHeight(headerHeight);
      }
    }
  }, [isModalOpened]);

  const handleRadioOrientation = (newOrientation: orientationType) => {
    setOrientation(newOrientation);
  };

  const handleViewPPT = (newView: ViewPPTType) => {
    setViewPPT(newView);
  };

  const changeParamsShow = () => {
    setParamsShow(prev => !prev);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const downloadPDF = async (
    dashboardBody: HTMLElement,
    splitedItemsByHeight: PDFGrid,
  ) => {
    // default params is portrait view
    const pageHeight = orientation === "landscape" ? widthA4mm : heightA4mm;
    const pageWidth = orientation === "landscape" ? heightA4mm : widthA4mm;

    const pixelRatio = pageWidth / dashboardBody.scrollWidth;
    const doc = new jsPDF(orientation);
    const docToRender = await renderPDF(
      doc,
      splitedItemsByHeight,
      pixelRatio,
      pageHeight,
    );
    docToRender.save(`${orientation}-dashboard.pdf`);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const downloadPPT = async (
    dashboardBody: HTMLElement,
    splitedItemsByHeight: PDFGrid,
  ) => {
    const ppt = new pptxgen();
    ppt.layout = "LAYOUT_4x3";

    switch (viewPPT) {
      case "dashboard": {
        const pptRender = await renderDashboardOnSlidePPT(ppt, dashboardBody);
        pptRender.writeFile({ fileName: "Dashboard Presentation.pptx" });
        break;
      }
      case "widget": {
        const pptRender = await renderWidgetsOnSlidesPPT(
          ppt,
          splitedItemsByHeight,
        );
        pptRender.writeFile({ fileName: "Widgets Presentation.pptx" });
        break;
      }
    }
  };

  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  useEffect(() => {
    if (obs && obs.current && tabsImage.length === tabs.length) {
      setLoader(false);
      const ppt = new pptxgen();
      ppt.layout = "LAYOUT_4x3";

      for (const tabImage of tabsImage) {
        const slide = ppt.addSlide();

        const { imgData, height, width } = tabImage;

        if (height > width) {
          const ratio = (height / width) * (4 / 3);
          const w = 100 / ratio;
          slide.addImage({
            data: imgData,
            w: `${w}%`,
            h: `100%`,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sizing: {
              type: "contain",
            },
          });
        } else {
          const ratio = width / height / (4 / 3);
          const h = 100 / ratio;

          slide.addImage({
            data: imgData,
            w: `100%`,
            h: `${h}%`,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sizing: {
              type: "contain",
            },
          });
        }
      }
      ppt.writeFile({ fileName: "tabs.pptx" });
      obs.current.disconnect();
    }
  }, [tabsImage, obs, tabs.length]);

  async function downloadTabsPPT(tabs: any[]) {
    if (tabs.length === 0) {
      console.error("Tabs not find");
      return;
    }

    const dashboard = document
      .querySelector("#Dashboard-Cards-Container")
      ?.querySelector("div")
      ?.querySelector("div");
    if (!dashboard) {
      console.error("Node not find");
      return;
    }

    const getImgfromTabs = (tabs: any[], index: number) => {
      const images: TabImage[] = [];
      return async () => {
        if (document.querySelector("#loading-spinner")) {
          return;
        }

        const paramsNode = document.querySelector(
          "#dashboard-parameters-widget-container",
        ) as HTMLElement;

        if (paramsNode && !paramsShow) {
          paramsNode.style.display = "none";
        }

        const dashboardBody = document.querySelector(
          ".DashboardBody",
        ) as HTMLElement;
        if (!dashboardBody) {
          console.error("Dashboard body not found");
          return [];
        }
        const height = dashboardBody.scrollHeight;
        const width = dashboardBody.scrollWidth;
        const canvas = await convert2Canvas(dashboardBody);
        const imgData = canvas.toDataURL("image/png");
        images.push({ imgData, height, width });
        if (paramsNode) {
          paramsNode.style.display = "block";
        }
        index++;
        if (index < tabs.length) {
          selectTab(tabs[index].id);
        } else {
          setTabsImage(images);
        }
      };
    };
    const getImgFromTabsDebounced = _.debounce(getImgfromTabs(tabs, 0), 2000);

    obs.current = new MutationObserver(getImgFromTabsDebounced);
    getImgFromTabsDebounced();

    obs.current.observe(dashboard, { childList: true, subtree: true });

    selectTab(tabs[0].id);
  }

  const handleDownload = async (type: "pdf" | "ppt" | "tabs") => {
    if (isNavBarOpen) {
      dispatch(closeNavbar());
      await delay(4000);
    }
    setLoader(true);

    try {
      // Find the dashboard container element
      const cards = Array.from(
        document.querySelectorAll(".Card"),
      ) as HTMLElement[];

      // Off sahdows, lib cant render shadows
      cards.forEach(card => {
        card.style.boxShadow = "none";
        card.style.border = "2px solid #f3f3f3";
        card.style.background = "#ffffff";
      });

      const dashboardContainer = document.querySelector(".DashboardGrid");
      if (!dashboardContainer) {
        console.error("Dashboard container not found");
        return;
      }

      const dashboardBody = document.querySelector(
        ".DashboardBody",
      ) as HTMLElement;
      if (!dashboardBody) {
        console.error("Dashboard body not found");
        return;
      }

      const dashCards = Array.from(
        dashboardContainer.querySelectorAll(".DashCard"),
      ) as HTMLElement[];

      window.scrollTo(0, 0);

      setTimeout(async () => {
        if (type === "tabs") {
          await downloadTabsPPT(tabs);
          return;
        }
        // this node is null if no parameters filters
        const paramsNode = document.querySelector(
          "#dashboard-parameters-widget-container",
        ) as HTMLElement;

        if ((!paramsShow || type === "pdf") && paramsNode) {
          paramsNode.style.display = "none";
        }

        // get all widgets
        const dashCardItemsAsRect = [];
        for (let i = 0; i < dashCards.length; i++) {
          dashCardItemsAsRect.push({
            item: dashCards[i],
            ...getRectParams(dashCards[i], dashboardBody),
          });
        }

        //group widgets in lines
        const splitedItemsByHeight = splitItemsByHeight(dashCardItemsAsRect);

        switch (type) {
          case "pdf": {
            await downloadPDF(dashboardBody, splitedItemsByHeight);
            break;
          }
          case "ppt": {
            await downloadPPT(dashboardBody, splitedItemsByHeight);
            break;
          }
        }

        setLoader(false);

        cards.forEach(card => {
          card.style.boxShadow = "";
          card.style.border = "";
          card.style.background = "";
        });
        if (paramsNode) {
          paramsNode.style.display = "block";
        }
      }, 300);
    } catch (error) {
      console.error("Error printing dashboard:", error);
    }
  };

  const _parametersWidget = useMemo(
    () => (
      <HeaderButtonSection
        className="Header-buttonSection"
        isNavBarOpen={isNavBarOpen}
      >
        {parametersWidget}
      </HeaderButtonSection>
    ),
    [isNavBarOpen, parametersWidget],
  );

  const _headerButtons = useMemo(
    () => (
      <HeaderButtonSection
        className="Header-buttonSection"
        isNavBarOpen={isNavBarOpen}
      >
        <DownloadMenu
          key="dashboard-download-menu-button"
          triggerIcon="pdf"
          tooltip={t`Download PDF/PPT`}
          orientation={orientation}
          viewPPT={viewPPT}
          loader={loader}
          handleViewPPT={handleViewPPT}
          handleRadioOrientation={handleRadioOrientation}
          handleDownload={handleDownload}
          paramsShow={paramsShow}
          changeParamsShow={changeParamsShow}
        />
        {headerButtons}
      </HeaderButtonSection>
    ),
    [headerButtons, isNavBarOpen, loader, orientation, handleDownload],
  );

  const handleUpdateCaption = useCallback(
    async (name: string) => {
      await setDashboardAttribute("name", name);
      if (!isEditing) {
        await dispatch(updateDashboard({ attributeNames: ["name"] }));
      }
    },
    [setDashboardAttribute, isEditing, dispatch],
  );

  useEffect(() => {
    const timerId = setTimeout(() => {
      setShowSubHeader(false);
    }, 4000);
    return () => clearTimeout(timerId);
  }, []);

  return (
    <div>
      {isEditing && (
        <EditBar
          title={editingTitle}
          subtitle={editingSubtitle}
          buttons={editingButtons}
        />
      )}
      {editWarning && (
        <EditWarning className="wrapper">
          <span>{editWarning}</span>
        </EditWarning>
      )}
      <HeaderModal
        isOpen={!!headerModalMessage}
        height={headerHeight}
        title={headerModalMessage}
        onDone={onHeaderModalDone}
        onCancel={onHeaderModalCancel}
      />
      <div>
        <HeaderRow
          isNavBarOpen={isNavBarOpen}
          className={cx("QueryBuilder-section", headerClassName)}
          data-testid="dashboard-header"
          ref={header}
        >
          <HeaderRowWrapper>
            <HeaderContent hasSubHeader showSubHeader={showSubHeader}>
              <HeaderCaptionContainer>
                <HeaderCaption
                  key={dashboard.name}
                  initialValue={dashboard.name}
                  placeholder={t`Add title`}
                  isDisabled={!dashboard.can_write}
                  data-testid="dashboard-name-heading"
                  onChange={handleUpdateCaption}
                />
              </HeaderCaptionContainer>
              <HeaderBadges>
                {isLastEditInfoVisible && (
                  <HeaderLastEditInfoLabel
                    item={dashboard}
                    onClick={onLastEditInfoClick}
                    className=""
                  />
                )}
              </HeaderBadges>
            </HeaderContent>
            <HeaderContent
              style={{ display: "flex" }}
              showSubHeader={false}
              hasSubHeader={false}
            >
              {_headerButtons}
            </HeaderContent>
          </HeaderRowWrapper>

          <HeaderButtonsContainer isNavBarOpen={isNavBarOpen}>
            {_parametersWidget}
          </HeaderButtonsContainer>
        </HeaderRow>
        <HeaderRow isNavBarOpen={isNavBarOpen}>
          <DashboardTabs location={location} isEditing={isEditing} />
        </HeaderRow>
      </div>
    </div>
  );
}
