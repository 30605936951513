import { t, jt } from "ttag";

import { Accordion, Box, Flex, Stack, Text, Title } from "metabase/ui";
import MetabaseSettings from "metabase/lib/settings";

import { Icon } from "metabase/core/components/Icon";
import ExternalLink from "metabase/core/components/ExternalLink";
import { PermissionHelpDescription } from "metabase/admin/permissions/components/PermissionHelpDescription";
import { getLimitedPermissionAvailabilityMessage } from "metabase/admin/permissions/constants/messages";
import { getSetting } from "metabase/selectors/settings";
import { useSelector } from "metabase/lib/redux";

export const DataPermissionsHelp = () => {
  const isAdvancedPermissionsFeatureEnabled = useSelector(
    state => getSetting(state, "token-features").advanced_permissions,
  );

  return (
    <Flex direction="column" py="1.375rem" px="1rem">
      <Box px="0.75rem">
        <Title order={4}>{t`Data permissions`}</Title>
        <Text my="1rem">{t`People can be members of multiple groups, and Glarus BI grants them the most permissive level of access across all of a person's groups.`}</Text>
      </Box>

      <Accordion
        chevron={<Icon name="chevrondown" size={12} />}
        defaultValue="database-level"
      >
        <Accordion.Item value="database-level">
          <Accordion.Control>{t`Database levels`}</Accordion.Control>
          <Accordion.Panel>
            <Stack spacing="1rem" py="1rem">
              <PermissionHelpDescription
                icon="check"
                iconColor="success"
                name={t`Unrestricted`}
                description={t`The group can use the query builder to ask questions of any table in the database.`}
              />

              <PermissionHelpDescription
                icon="permissions_limited"
                iconColor="warning"
                name={t`Granular`}
                description={t`The group can only use the query builder to ask questions of specific tables.`}
              />

              <PermissionHelpDescription
                icon="eye_crossed_out"
                iconColor="accent5"
                name={t`No self-service`}
                description={t`The group can't use the query builder or drill through existing questions. They also can't see the data in the Browse data section. They can still view questions based on this data, if they have permissions to the relevant collection.`}
              />

            </Stack>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="schema-table-level">
          <Accordion.Control>{t`Schema and table levels`}</Accordion.Control>
          <Accordion.Panel>
            <Stack spacing="1rem" py="1rem">
              <PermissionHelpDescription
                icon="check"
                iconColor="success"
                name={t`Unrestricted`}
              />

              <PermissionHelpDescription
                icon="eye_crossed_out"
                iconColor="accent5"
                name={t`No self-service`}
                description={t`"Unrestricted" and "No self-service permissions" work like they do for databases, except here they're scoped to individual schemas or tables.`}
              />

            </Stack>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="others">
          <Accordion.Control>{t`Other data permissions`}</Accordion.Control>
          <Accordion.Panel>
            <Stack spacing="1rem" py="1rem">
              <Text>
                {jt`${(
                  <strong>{t`Native query editing:`}</strong>
                )} Extends the "Unrestricted" data permissions level to include access to the native/SQL editor.`}
              </Text>
              
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <Text component="footer" align="center" py="1.5rem" weight={600}>
        {jt`${(
          <ExternalLink
            href={MetabaseSettings.docsUrl("permissions/data")}
          >{t`Learn more`}</ExternalLink>
        )} about data permissions`}
      </Text>
    </Flex>
  );
};
