/* eslint-disable react/prop-types */
import {
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
  useRef,
  useEffect,
} from "react";
import { getIn } from "icepick";
import _ from "underscore";

import Draggable from "react-draggable";
import { Grid, ScrollSync } from "react-virtualized";
import ExplicitSize from "metabase/components/ExplicitSize";
import Ellipsified from "metabase/core/components/Ellipsified";

import { columnLineBreak } from "metabase/visualizations/visualizations/Table";

import { isPositiveInteger } from "metabase/lib/number";
import { isColumnRightAligned } from "metabase/visualizations/lib/table";
import { getScrollBarSize } from "metabase/lib/dom";
import { isFK, isID } from "metabase-lib/types/utils/isa";
import { formatDateStringWithRemoveUnusedDimensionsAndRounding } from "metabase-lib/utils/functions";
import { ResizeHandle } from "../TableInteractive/TableInteractive.styled";
import { CELL_DEFAULT_INDENT } from "../TableInteractive/TableInteractive";
import TableCell from "./TableCell";
import TableFooter from "./TableFooter";
import {
  Root,
  ContentContainer,
  Table,
  TableContainer,
  TableHeaderCellContent,
  SortIcon,
} from "./TableSimple.styled";

function getBoundingClientRectSafe(ref) {
  return ref.current?.getBoundingClientRect?.() ?? {};
}

function getWidthOfText(text, fontSize, fontFamily) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = `${fontSize}px ${fontFamily || "Arial"}`;
  const width = context.measureText(text).width;
  return width;
}

function formatCellValueForSorting(value, column) {
  if (typeof value === "string") {
    if (isID(column) && isPositiveInteger(value)) {
      return parseInt(value, 10);
    }
    // for strings we should be case insensitive
    return value.toLowerCase();
  }
  if (value === null) {
    return undefined;
  }
  return value;
}

const styles = {
  wordWrap: "break-word",
  whiteSpace: "normal",
};

const DEFAULT_COL_WIDTH = 36;
const RESIZE_HANDLE_WIDTH = 5;
const DEFAULT_TITLE_INDENT_VERTICAL = 9;

function TableSimple({
  height,
  card,
  data,
  series,
  settings,
  isPivoted,
  className,
  onVisualizationClick,
  visualizationIsClickable,
  getColumnTitle,
  getColumnLineBreak,
  getExtraDataForClick,
  scrollToColumn,
  width,
  onUpdateVisualizationSettings,
  onActionDismissal,
}) {
  const { rows, cols } = data;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(1);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const firstRowRef = useRef(null);

  const [clickedRow, setClickedRow] = useState(null);
  const [columnsWidthList, setColumnsWidthList] = useState([]);

  const tableHeaderRef = useRef(null);
  const tableBodyRef = useRef(null);
  const tablePinnedBodyRef = useRef(null);

  const isHaveBigCell = cols.some(col => isID(col) || isFK(col));

  const isTableWithVirtualization = settings["card.table_virtualization"];

  const cellIndentVertical = settings["table.cell_auto_indent"]
    ? CELL_DEFAULT_INDENT
    : settings["table.cell_indent_vertical"];
  const maxCellFontSize = isHaveBigCell
    ? settings["table.cell_font_size"] * 1.5
    : settings["table.cell_font_size"];

  const cellHeight = settings["table.cell_auto_height"]
    ? 2 + maxCellFontSize + cellIndentVertical * 2
    : settings["table.cell_height"];

  const isPinMode = settings["table.pin_mode"];
  const pinnedRowsCountSettings = settings["table.pinned_rows_count"];
  const pinnedColumnsCountSettings = settings["table.pinned_columns_count"];

  const pinnedRowsCount =
    pinnedRowsCountSettings > rows.length
      ? rows.length
      : pinnedRowsCountSettings < 1
      ? 1
      : pinnedRowsCountSettings;
  const pinnedColumnsCount =
    pinnedColumnsCountSettings > cols.length
      ? cols.length
      : pinnedColumnsCountSettings < 1
      ? 1
      : pinnedColumnsCountSettings;

  const pinnedColumnWidths = useMemo(
    () =>
      columnsWidthList
        .slice(0, pinnedColumnsCount)
        .reduce((sum, width) => sum + width, 0),
    [columnsWidthList, pinnedColumnsCount],
  );
  const maxPinnedColumnsLeftScroll = pinnedColumnWidths - width;

  const pinnedTableHeight = height - cellHeight * 2 - getScrollBarSize();
  const maxPinnedRowsTopScroll =
    cellHeight * pinnedRowsCount - pinnedTableHeight;

  useLayoutEffect(() => {
    const { height: headerHeight } = getBoundingClientRectSafe(headerRef);
    const { height: footerHeight = 0 } = getBoundingClientRectSafe(footerRef);
    const { height: rowHeight = 0 } = getBoundingClientRectSafe(firstRowRef);
    const currentPageSize = Math.floor(
      (height - headerHeight - footerHeight) / (rowHeight + 1),
    );
    const normalizedPageSize = Math.max(1, currentPageSize);
    if (pageSize !== normalizedPageSize) {
      setPageSize(normalizedPageSize);
    }
  }, [height, pageSize, settings]);

  const setSort = useCallback(
    colIndex => {
      if (sortColumn === colIndex) {
        setSortDirection(direction => (direction === "asc" ? "desc" : "asc"));
      } else {
        setSortColumn(colIndex);
      }
    },
    [sortColumn],
  );

  const checkIsVisualizationClickable = useCallback(
    clickedItem => {
      return (
        onVisualizationClick &&
        visualizationIsClickable &&
        visualizationIsClickable(clickedItem)
      );
    },
    [onVisualizationClick, visualizationIsClickable],
  );

  const limit = getIn(card, ["dataset_query", "query", "limit"]) || undefined;

  const getCellBackgroundColor = settings["table._cell_color_getter"];
  const getCellTextColor = settings["table.table._text_color_getter"];

  const start = pageSize * page; // 0; //  pageSize * page;
  const end = Math.min(rows.length - 1, pageSize * (page + 1) - 1); // rows.length; // Math.min(rows.length - 1, pageSize * (page + 1) - 1);

  const titleIndentVertical = settings["table.title_auto_indent"]
    ? DEFAULT_TITLE_INDENT_VERTICAL
    : settings["table.titile_indent_vertical"];
  const titleIndentHorizontal = settings["table.title_auto_indent"]
    ? 0
    : settings["table.title_indent_left"];
  const titleHeight = settings["table.title_auto_height"]
    ? settings["table.title_font_size"] + titleIndentVertical * 2
    : settings["table.title_height"];
  const headerTextColor = settings["table.header_text_color"];
  const headerBackgroundColor = settings["table.header_background_color"];
  const headerIsTransparent = settings["table.header_transparent"];
  const titleFontSize = settings["table.title_font_size"];
  const titleFontStyle = settings["table.title_font_italic"]
    ? "italic"
    : "normal";
  const titleFontWeight = settings["table.title_font_bold"] ? "bold" : "normal";
  const titleBorderColor = settings["table.grid_color"];

  const isSortIconHidden = settings["table.header_sort_icon_hidden"];
  const sortIconColor = settings["table.header_sort_icon_color"];
  const headerSortedTextColor = settings["table.header_sorted_text_color"];

  const titleHorizontalAligment = settings["table.title_horizontal_alignment"];
  const titleVerticalAligment = settings["table.title_vertical_alignment"];

  const commonHeaderCellStyes = useMemo(
    () => ({
      backgroundColor: headerIsTransparent
        ? "transparent"
        : headerBackgroundColor,
      display: "flex",
      alignItems: titleVerticalAligment,
      justifyContent: titleHorizontalAligment,
      paddingTop: titleIndentVertical,
      paddingLeft: titleIndentHorizontal,
    }),
    [
      headerBackgroundColor,
      headerIsTransparent,
      titleHorizontalAligment,
      titleIndentHorizontal,
      titleIndentVertical,
      titleVerticalAligment,
    ],
  );

  useEffect(
    () => {
      const tableSettingsWidths = settings["table.column_widths"];
      if (settings && data) {
        const newColumnsWidthList = cols.map((col, index) => {
          if (index === 0) {
            return getWidthOfText(col.display_name) * 6;
          }
          return (
            (tableSettingsWidths && tableSettingsWidths[index]) ||
            Math.max(
              getWidthOfText(new String(rows[0][index]), maxCellFontSize) * 1.5,
              getWidthOfText(col.display_name, titleFontSize) * 1.5,
            )
          );
        });

        setColumnsWidthList(newColumnsWidthList);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const headerSettingsWidth = settings["table.column_widths"] || [];

  const handlePreviousPage = useCallback(() => {
    setPage(p => p - 1);
  }, []);

  const handleNextPage = useCallback(() => {
    setPage(p => p + 1);
  }, []);

  const rowIndexes = useMemo(() => {
    let indexes = _.range(0, rows.length);

    if (sortColumn != null) {
      indexes = _.sortBy(indexes, rowIndex => {
        const value = rows[rowIndex][sortColumn];
        const column = cols[sortColumn];
        return formatCellValueForSorting(value, column);
      });
    }

    if (sortDirection === "desc") {
      indexes.reverse();
    }

    return indexes;
  }, [cols, rows, sortColumn, sortDirection]);

  const paginatedRowIndexes = useMemo(
    () => rowIndexes.slice(start, end + 1),
    [rowIndexes, start, end],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getThLineBreakStyles = collIndex => {
    return getColumnLineBreak(collIndex) === columnLineBreak.show ? styles : {};
  };

  const renderColumnHeader = useCallback(
    (col, colIndex) => {
      const iconName = sortDirection === "desc" ? "chevrondown" : "chevronup";
      const onClick = () => setSort(colIndex);
      const style = {
        width: headerSettingsWidth[colIndex] || "auto",
        maxWidth: headerSettingsWidth[colIndex] || "auto",
        ...getThLineBreakStyles(colIndex),
      };
      return (
        <th
          key={colIndex}
          data-testid="column-header"
          style={{
            ...style,
            height: titleHeight,
            padding: 0,
          }}
        >
          <div
            style={{
              ...commonHeaderCellStyes,
              height: "100%",
            }}
          >
            <TableHeaderCellContent
              isSorted={colIndex === sortColumn}
              sortedTextColor={headerSortedTextColor}
              textColor={headerTextColor}
              onClick={onClick}
              isRightAligned={isColumnRightAligned(col)}
              isLeftAligned={true}
            >
              {!isSortIconHidden && (
                <SortIcon name={iconName} color={sortIconColor} />
              )}
              <Ellipsified
                font={{
                  fontSize: titleFontSize,
                  fontStyle: titleFontStyle,
                  fontWeight: titleFontWeight,
                }}
                style={{ ...style, width: "auto" }}
                padding={"0 0.08em 0 0.08em"}
              >
                {getColumnTitle(colIndex)}
              </Ellipsified>
            </TableHeaderCellContent>
          </div>
        </th>
      );
    },
    [
      sortDirection,
      headerSettingsWidth,
      getThLineBreakStyles,
      titleHeight,
      commonHeaderCellStyes,
      sortColumn,
      headerSortedTextColor,
      headerTextColor,
      isSortIconHidden,
      sortIconColor,
      titleFontSize,
      titleFontStyle,
      titleFontWeight,
      getColumnTitle,
      setSort,
    ],
  );

  const getColumnFullWidth = useCallback(
    index => {
      const columnWidthsSetting = settings["table.column_widths"] || [];
      const explicitWidth = columnWidthsSetting[index];
      const calculatedWidth = columnsWidthList[index] || DEFAULT_COL_WIDTH;
      return explicitWidth || calculatedWidth;
    },
    [columnsWidthList, settings],
  );

  const getDisplayColumnWidth = useCallback(
    ({ index: displayIndex }) => {
      return getColumnFullWidth(displayIndex);
    },
    [getColumnFullWidth],
  );

  const recomputeGridSize = () => {
    if (tableHeaderRef && tableHeaderRef) {
      tableHeaderRef.current.recomputeGridSize();
      tableBodyRef.current.recomputeGridSize();
      if (tablePinnedBodyRef) {
        tablePinnedBodyRef.current.recomputeGridSize();
      }
    }
  };

  const columnResizeHandler = useCallback(
    (columnIndex, width) => {
      const columnWidthsSetting = settings["table.column_widths"]
        ? settings["table.column_widths"].slice()
        : [];
      columnWidthsSetting[columnIndex] = Math.max(DEFAULT_COL_WIDTH, width);
      onUpdateVisualizationSettings({
        "table.column_widths": columnWidthsSetting,
      });
      setTimeout(() => recomputeGridSize(), 1);
    },
    [onUpdateVisualizationSettings, settings],
  );

  const renderRow = useCallback(
    (rowIndex, index) => {
      const ref = index === 0 ? firstRowRef : null;
      return (
        <tr
          key={rowIndex}
          ref={ref}
          data-testid="table-row"
          style={{ height: cellHeight }}
        >
          {data.rows[rowIndex].map((value, columnIndex) => (
            <TableCell
              style={{
                maxWidth: headerSettingsWidth[columnIndex] || "auto",
                ...getThLineBreakStyles(columnIndex),
                height: cellHeight,
              }}
              key={`${rowIndex}-${columnIndex}`}
              value={
                typeof value === "string"
                  ? formatDateStringWithRemoveUnusedDimensionsAndRounding(value)
                  : value
              }
              data={data}
              series={series}
              settings={settings}
              rowIndex={rowIndex}
              columnIndex={columnIndex}
              isPivoted={isPivoted}
              getCellBackgroundColor={getCellBackgroundColor}
              getCellTextColor={getCellTextColor}
              getExtraDataForClick={getExtraDataForClick}
              checkIsVisualizationClickable={checkIsVisualizationClickable}
              onVisualizationClick={onVisualizationClick}
              lineBreak={getColumnLineBreak(columnIndex)}
              cellSetClickedRowHandler={setClickedRow}
              clickedRow={clickedRow}
              isTableWithVirtualization={isTableWithVirtualization}
              isHaveBigCell
            />
          ))}
        </tr>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      series,
      settings,
      isPivoted,
      checkIsVisualizationClickable,
      getCellBackgroundColor,
      getExtraDataForClick,
      getColumnLineBreak,
      onVisualizationClick,
      clickedRow,
      isTableWithVirtualization,
    ],
  );

  const gridCellsStyles = useMemo(
    () => ({ height: cellHeight, pointerEvents: "all" }),
    [cellHeight],
  );

  const commonGridStyles = useMemo(
    () => ({
      top: titleHeight,
      left: 0,
      right: 0,
      bottom: 0,
      position: "absolute",
    }),
    [titleHeight],
  );

  const cellRenderer = useCallback(
    ({ key, style, rowIndex, columnIndex }) => {
      const value = data.rows[rowIndexes[rowIndex]][columnIndex];

      return (
        <div style={{ ...style, ...gridCellsStyles }} key={key}>
          <TableCell
            style={{
              maxWidth:
                headerSettingsWidth[columnIndex] ||
                columnsWidthList[columnIndex],
              ...getThLineBreakStyles(columnIndex),
            }}
            key={`${rowIndex}-${columnIndex}`}
            value={
              typeof value === "string"
                ? formatDateStringWithRemoveUnusedDimensionsAndRounding(value)
                : value
            }
            data={data}
            series={series}
            settings={settings}
            rowIndex={rowIndex}
            columnIndex={columnIndex}
            isPivoted={isPivoted}
            getCellBackgroundColor={getCellBackgroundColor}
            getCellTextColor={getCellTextColor}
            getExtraDataForClick={getExtraDataForClick}
            checkIsVisualizationClickable={checkIsVisualizationClickable}
            onVisualizationClick={onVisualizationClick}
            lineBreak={getColumnLineBreak(columnIndex)}
            cellSetClickedRowHandler={setClickedRow}
            clickedRow={clickedRow}
            isTableWithVirtualization={isTableWithVirtualization}
            isHaveBigCell
          />
        </div>
      );
    },
    [
      data,
      rowIndexes,
      gridCellsStyles,
      headerSettingsWidth,
      columnsWidthList,
      getThLineBreakStyles,
      series,
      settings,
      isPivoted,
      getCellBackgroundColor,
      getCellTextColor,
      getExtraDataForClick,
      checkIsVisualizationClickable,
      onVisualizationClick,
      getColumnLineBreak,
      clickedRow,
      isTableWithVirtualization,
    ],
  );

  const tableHeaderRenderer = useCallback(
    ({ key, style, columnIndex }) => {
      const iconName = sortDirection === "desc" ? "chevrondown" : "chevronup";
      const onClick = () => setSort(columnIndex);

      const headerStyle = {
        width: headerSettingsWidth[columnIndex] || "auto",
        maxWidth: headerSettingsWidth[columnIndex] || "auto",
        ...getThLineBreakStyles(columnIndex),
      };

      return (
        <div
          style={{
            ...style,
            ...headerStyle,
            width: columnsWidthList[columnIndex],
            ...commonHeaderCellStyes,
          }}
          key={key}
        >
          <TableHeaderCellContent
            isSorted={columnIndex === sortColumn}
            textColor={headerTextColor}
            onClick={onClick}
            sortedTextColor={headerSortedTextColor}
            isRightAligned={isColumnRightAligned(cols[columnIndex])}
          >
            <Ellipsified
              font={{
                fontSize: titleFontSize,
                fontStyle: titleFontStyle,
                fontWeight: titleFontWeight,
              }}
              padding={"0 0.08em 0 0.08em"}
            >
              {!isSortIconHidden && (
                <SortIcon name={iconName} color={sortIconColor} />
              )}
              {getColumnTitle(columnIndex)}
            </Ellipsified>
          </TableHeaderCellContent>
          <Draggable
            bounds={{ left: RESIZE_HANDLE_WIDTH }}
            axis="x"
            onStop={(e, { x }) => {
              e.stopPropagation();
              setColumnsWidthList(prevState => {
                const newWidths = [...prevState];
                newWidths[columnIndex] = x;
                return newWidths;
              });
              columnResizeHandler(columnIndex, x);
            }}
            position={{ x: getColumnFullWidth(columnIndex), y: 0 }}
          >
            <ResizeHandle
              className="dragged-icon"
              style={{
                zIndex: 99,
                position: "absolute",
                width: RESIZE_HANDLE_WIDTH,
                top: 0,
                bottom: 0,
                left: -RESIZE_HANDLE_WIDTH - 1,
                cursor: "ew-resize",
              }}
            />
          </Draggable>
        </div>
      );
    },
    [
      sortDirection,
      headerSettingsWidth,
      getThLineBreakStyles,
      columnsWidthList,
      commonHeaderCellStyes,
      sortColumn,
      headerTextColor,
      headerSortedTextColor,
      cols,
      titleFontSize,
      titleFontStyle,
      titleFontWeight,
      isSortIconHidden,
      sortIconColor,
      getColumnTitle,
      getColumnFullWidth,
      setSort,
      columnResizeHandler,
    ],
  );

  return isTableWithVirtualization ? (
    <ScrollSync>
      {({ onScroll, scrollLeft, scrollTop }) => {
        const mainGridProps = {};
        if (scrollToColumn >= 0) {
          mainGridProps.scrollToColumn = scrollToColumn;
        } else {
          mainGridProps.scrollLeft = scrollLeft;
        }

        return (
          <ContentContainer>
            <Grid
              ref={tableHeaderRef}
              style={{
                top: 0,
                left: 0,
                right: 0,
                height: titleHeight,
                position: "absolute",
                overflow: "hidden",
                paddingRight: getScrollBarSize(),
              }}
              width={width || 0}
              height={titleHeight}
              rowCount={1}
              rowHeight={titleHeight}
              columnCount={cols.length}
              columnWidth={getDisplayColumnWidth}
              cellRenderer={props => tableHeaderRenderer(props)}
              onScroll={({ scrollLeft }) => onScroll({ scrollLeft })}
              scrollLeft={scrollLeft}
              tabIndex={null}
              scrollToColumn={scrollToColumn}
            />
            <>
              {isPinMode && (
                <Grid
                  className="scroll-hide-all"
                  ref={tablePinnedBodyRef}
                  style={{
                    ...commonGridStyles,
                    zIndex: 2,
                    pointerEvents: "none",
                    overflow: "hidden",
                  }}
                  width={width - getScrollBarSize()}
                  height={pinnedTableHeight}
                  columnCount={pinnedColumnsCount}
                  columnWidth={getDisplayColumnWidth}
                  rowCount={pinnedRowsCount}
                  rowHeight={cellHeight}
                  cellRenderer={props => cellRenderer(props)}
                  scrollTop={
                    scrollTop > maxPinnedRowsTopScroll
                      ? maxPinnedRowsTopScroll
                      : scrollTop
                  }
                  scrollLeft={
                    scrollLeft > maxPinnedColumnsLeftScroll
                      ? maxPinnedColumnsLeftScroll
                      : scrollLeft
                  }
                  tabIndex={null}
                  overscanRowCount={20}
                />
              )}

              <Grid
                className="scrollbar-transparent"
                ref={tableBodyRef}
                style={{
                  ...commonGridStyles,
                  zIndex: 1,
                }}
                width={width}
                height={height - cellHeight}
                columnCount={cols.length}
                columnWidth={getDisplayColumnWidth}
                rowCount={rows.length}
                rowHeight={cellHeight}
                cellRenderer={props => cellRenderer(props)}
                scrollToRow={clickedRow}
                scrollTop={scrollTop}
                onScroll={({ scrollLeft, scrollTop }) => {
                  onActionDismissal();
                  return onScroll({ scrollLeft, scrollTop });
                }}
                {...mainGridProps}
                tabIndex={null}
                overscanRowCount={20}
              />
            </>
          </ContentContainer>
        );
      }}
    </ScrollSync>
  ) : (
    <Root className={className}>
      <ContentContainer>
        <TableContainer className="scroll-show scroll-show--hover">
          <Table
            className="fullscreen-normal-text fullscreen-night-text"
            borderColor={titleBorderColor}
          >
            <thead ref={headerRef}>
              <tr>{cols.map(renderColumnHeader)}</tr>
            </thead>
            <tbody>{paginatedRowIndexes.map(renderRow)}</tbody>
          </Table>
        </TableContainer>
      </ContentContainer>
      {pageSize < rows.length && (
        <TableFooter
          start={start}
          end={end}
          limit={limit}
          total={rows.length}
          onPreviousPage={handlePreviousPage}
          onNextPage={handleNextPage}
          ref={footerRef}
        />
      )}
    </Root>
  );
}

export default ExplicitSize({
  refreshMode: props =>
    props.isDashboard && !props.isEditing ? "debounce" : "throttle",
})(TableSimple);
