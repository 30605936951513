var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.test.check.random.js");require("./clojure.test.check.rose_tree.js");
'use strict';var Yw,Zw,$w,ax,bx,cx,dx,Yba,ex,fx,Zba,$ba,hx,jx,lx,aca,nx,ox,px,bca,sx,cca,dca,tx,vx,wx,zx,eca,Ax,fca,Ex,Fx,gca,hca,Kx,Mx,lca,nca,Ux,Wx,Xx,Yx,qca,gx,xx,qx,ix,by,rx,Bx,rca;$CLJS.Xw=function(a){return a-1};Yw=function(a){if(null!=a&&null!=a.Se)a=a.Se(a);else{var b=Yw[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Yw._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("IRandom.rand-long",a);}return a};
Zw=function(a){if(null!=a&&null!=a.jg)a=a.jg(a);else{var b=Zw[$CLJS.Na(null==a?null:a)];if(null!=b)a=b.g?b.g(a):b.call(null,a);else if(b=Zw._,null!=b)a=b.g?b.g(a):b.call(null,a);else throw $CLJS.Pb("IRandom.rand-double",a);}return a};$w=function(a,b){if(null!=a&&null!=a.kg)a=a.kg(a,b);else{var c=$w[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=$w._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("IRandom.split-n",a);}return a};ax=function(a){return a.root};
bx=function(a,b){this.root=a;this.children=b;this.C=16;this.K=0};cx=function(a,b){return new bx(a,b)};dx=function(a,b,c,d,e){this.M=a;this.f=b;this.Dg=c;this.seed=d;this.next=e;this.C=26083532;this.K=1};Yba=function(a,b){return new dx(null,a,null,b,null)};
ex=function(a,b,c){return $CLJS.Jd(b)?new $CLJS.gf(null,$CLJS.r(b),null,1,null):$CLJS.nf(b,new $CLJS.yf(null,function(){var d=$CLJS.A(c);if(d){var e=ex;var f=$CLJS.C(d);f=a.h?a.h(b,f):a.call(null,b,f);d=e(a,f,$CLJS.zd(d))}else d=null;return d},null,null))};
fx=function(a){return function d(c){return new $CLJS.yf(null,function(){for(var e=c;;){var f=$CLJS.A(e);if(f){var g=f,l=$CLJS.C(g),n=$CLJS.J(l,0,null),q=$CLJS.J(l,1,null);if(f=$CLJS.A(function(u,v,x,y,B,H){return function Y(Q){return new $CLJS.yf(null,function(aa,ha,qa,Ea){return function(){for(;;){var kb=$CLJS.A(Q);if(kb){if($CLJS.re(kb)){var lb=$CLJS.$c(kb),Fb=$CLJS.E(lb),Mb=$CLJS.Bf(Fb);a:for(var ac=0;;)if(ac<Fb){var Tb=$CLJS.be(lb,ac);Tb=$CLJS.U.j(a,Ea,Tb);Mb.add(Tb);ac+=1}else{lb=!0;break a}return lb?
$CLJS.Ef($CLJS.Gf(Mb),Y($CLJS.ad(kb))):$CLJS.Ef($CLJS.Gf(Mb),null)}Mb=$CLJS.C(kb);return $CLJS.nf($CLJS.U.j(a,Ea,Mb),Y($CLJS.zd(kb)))}return null}}}(u,v,x,y,B,H),null,null)}}(e,l,n,q,g,f)(n.children)))return $CLJS.qg.h(f,d($CLJS.zd(e)));e=$CLJS.zd(e)}else return null}},null,null)}($CLJS.rg.j($CLJS.Rm,a,$CLJS.Ai(0,Number.MAX_VALUE)))};Zba=function(a){return $CLJS.qg.h($CLJS.dg(function(b){return gx(b,a)},a),fx($CLJS.Mg(a)))};
$ba=function(a,b){if(4<=$CLJS.E(b)){var c=$CLJS.af($CLJS.E(b),2);return new $CLJS.yf(null,function(){return $CLJS.nf(function(){var d=$CLJS.Sm.j(b,0,c);return hx.h?hx.h(a,d):hx.call(null,a,d)}(),new $CLJS.yf(null,function(){var d=$CLJS.Sm.h(b,c);d=hx.h?hx.h(a,d):hx.call(null,a,d);return new $CLJS.gf(null,d,null,1,null)},null,null))},null,null)}return null};hx=function(a,b){var c=ix(a,b);return cx(c.root,$CLJS.qg.h($ba(a,b),c.children))};
jx=function(a,b){var c=hx(a,b);a=cx(a.A?a.A():a.call(null),$CLJS.Lg);return $CLJS.je(b)?c:cx(c.root,$CLJS.nf(a,c.children))};$CLJS.kx=function(a,b,c,d){this.sd=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};lx=function(a){return new $CLJS.kx(a,null,null,null)};$CLJS.mx=function(a,b,c){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.ul);return a.h?a.h(b,c):a.call(null,b,c)};aca=function(a){return lx(function(){return a})};
nx=function(a,b){b=$CLJS.Qf(b);var c=$CLJS.M.h(b,$CLJS.ul);return lx(function(d,e){d=c.h?c.h(d,e):c.call(null,d,e);return a.g?a.g(d):a.call(null,d)})};ox=function(a,b){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.ul);return lx(function(d,e){d=$CLJS.Ow(d);var f=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);f=c.h?c.h(f,e):c.call(null,f,e);f=b.g?b.g(f):b.call(null,f);f=$CLJS.Qf(f);f=$CLJS.M.h(f,$CLJS.ul);return f.h?f.h(d,e):f.call(null,d,e)})};
px=function(a){return lx(function(b,c){return $CLJS.Mm.j(function(d,e){return $CLJS.mx(d,e,c)},a,$w(b,$CLJS.E(a)))})};$CLJS.nw=function(a,b){return nx(function(c){return qx(a,c)},b)};$CLJS.rw=function(a){return aca(cx(a,$CLJS.Lg))};bca=function(a){return function(b){return nx(rx,lx(function(c,d){return qx(function(e){return $CLJS.mx(a.g?a.g(e):a.call(null,e),c,d)},b)}))}};sx=function(a,b){return ox(a,bca(b))};
cca=function(a){return $CLJS.vi(function(b){return $CLJS.ki.h(0,b)},Yba(function(b){return $CLJS.af(b,2)},a))};dca=function(a){return $CLJS.rg.h(function(b){return a-b},cca(a))};tx=function(a,b,c){a=Zw(a);return $CLJS.$e(Math.floor(b+(a*(1+c)-a*b)))};$CLJS.ux=function(a){return lx(function(b,c){var d=a.g?a.g(c):a.call(null,c);return $CLJS.mx(d,b,c)})};vx=function(a,b){b=$CLJS.Qf(b);var c=$CLJS.M.h(b,$CLJS.ul);return lx(function(d){return c.h?c.h(d,a):c.call(null,d,a)})};
wx=function(a,b){return $CLJS.ux(function(c){return vx(a.g?a.g(c):a.call(null,c),b)})};zx=function(a,b){return lx(function(c){c=tx(c,a,b);return xx(function(d){return d>=a&&d<=b},yx(c))})};$CLJS.pw=function(a){return sx(zx(0,$CLJS.E(a)-1),function(b){return $CLJS.Td(a,b)})};eca=function(a,b){return $CLJS.E($CLJS.vi(function(c){return c<=b},$CLJS.zd(ex($CLJS.Cm,0,a))))};
Ax=function(a){var b=$CLJS.ug($CLJS.Im.h($CLJS.cf,$CLJS.C),a),c=$CLJS.R.h($CLJS.Cm,$CLJS.rg.h($CLJS.C,b));return lx(function(d,e){return $CLJS.mx(ox(zx(0,c-1),function(f){var g=eca($CLJS.rg.h($CLJS.C,b),f.root);return nx(function(l){return cx(l.root,new $CLJS.yf(null,function(){return $CLJS.qg.h(function(){return function u(q){return new $CLJS.yf(null,function(){for(;;){var v=$CLJS.A(q);if(v){if($CLJS.re(v)){var x=$CLJS.$c(v),y=$CLJS.E(x),B=$CLJS.Bf(y);a:for(var H=0;;)if(H<y){var I=$CLJS.be(x,H);
I=$CLJS.mx($CLJS.Zd($CLJS.Td(b,I)),d,e);B.add(I);H+=1}else{x=!0;break a}return x?$CLJS.Ef($CLJS.Gf(B),u($CLJS.ad(v))):$CLJS.Ef($CLJS.Gf(B),null)}B=$CLJS.C(v);return $CLJS.nf($CLJS.mx($CLJS.Zd($CLJS.Td(b,B)),d,e),u($CLJS.zd(v)))}return null}},null,null)}($CLJS.Ai(0,g))}(),l.children)},null,null))},$CLJS.Zd($CLJS.Td(b,g)))}),d,e)})};$CLJS.ow=function(a){var b=$CLJS.Mg(a);return nx(function(c){return qx(b,c)},zx(0,$CLJS.E(b)-1))};
fca=function(a,b,c,d,e){c=$CLJS.Qf(c);var f=$CLJS.M.h(c,Bx),g=$CLJS.M.h(c,$CLJS.Cx);for(c=g;;){if(0===c)throw function(){var q=new $CLJS.k(null,3,[$CLJS.vl,a,$CLJS.ul,b,$CLJS.Cx,g],null);return f.g?f.g(q):f.call(null,q)}();var l=$CLJS.Ow(d);d=$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);var n=$CLJS.mx(b,d,e);if($CLJS.m(function(){var q=n.root;return a.g?a.g(q):a.call(null,q)}()))return xx(a,n);d=l;e+=1;--c}};
$CLJS.jw=function(a){return ox($CLJS.ux(function(b){return zx(0,b)}),function(b){return nx(function(c){return jx($CLJS.X,c)},px($CLJS.ng(b.root,a)))})};
Ex=function(a,b,c,d,e,f,g,l,n,q){var u=$CLJS.Tc($CLJS.Lg),v=$CLJS.Tc($CLJS.oi),x=e;e=f;for(var y=0;;){if($CLJS.F.h(n,y)&&$CLJS.E(u)<l)throw function(){var I=new $CLJS.k(null,3,[$CLJS.ul,d,$CLJS.Cx,n,$CLJS.Dx,g],null);return q.g?q.g(I):q.call(null,I)}();if($CLJS.F.h(n,y)||$CLJS.F.h($CLJS.E(u),g))return jx(function(){return function(){function I(Y){var aa=null;if(0<arguments.length){aa=0;for(var ha=Array(arguments.length-0);aa<ha.length;)ha[aa]=arguments[aa+0],++aa;aa=new $CLJS.z(ha,0,null)}return Q.call(this,
aa)}function Q(Y){return $CLJS.oh.h(a,Y)}I.v=0;I.B=function(Y){Y=$CLJS.A(Y);return Q(Y)};I.l=Q;return I}()}(u,v,x,e,y),function(){var I=x,Q=$CLJS.Vc(u);return c.h?c.h(I,Q):c.call(null,I,Q)}());f=$CLJS.Ow(x);var B=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);B=$CLJS.mx(d,B,e);var H=B.root;H=b.g?b.g(H):b.call(null,H);null!=$CLJS.tm(v,H)?(e+=1,y+=1,x=f):(y=$CLJS.Uc(u,B),v=$CLJS.Uc(v,H),u=y,x=f,y=0)}};Fx=function(a,b){var c=$CLJS.je(b);return c?c:$CLJS.R.h($CLJS.Ru,$CLJS.rg.h(a,b))};
gca=function(a,b){var c=$CLJS.ae(b),d=$CLJS.Mg(b);b=$CLJS.E(b);var e=b-1;return $CLJS.oh.h(c,$CLJS.C($CLJS.Sb(function(f,g){var l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);f=$CLJS.Ow(f);var n=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);var q=tx(n,g,e);n=$CLJS.T;q=new $CLJS.S(null,2,5,$CLJS.T,[g,q],null);g=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l=$CLJS.U.l(l,q,l.g?l.g(g):l.call(null,g),$CLJS.G([g,l.g?l.g(q):l.call(null,q)]));return new $CLJS.S(null,2,5,n,[l,f],null)},new $CLJS.S(null,2,5,$CLJS.T,[d,a],null),
$CLJS.Ai(0,b))))};
$CLJS.Ix=function(a,b,c,d,e,f){f=$CLJS.Qf(f);var g=$CLJS.M.h(f,$CLJS.Dx),l=$CLJS.M.h(f,$CLJS.Gx),n=$CLJS.M.h(f,$CLJS.Hx),q=$CLJS.M.j(f,$CLJS.Cx,10),u=$CLJS.M.j(f,Bx,function(H){return $CLJS.zj("Couldn't generate enough distinct elements!",H)}),v=$CLJS.m(d)?gca:function(H,I){return I},x=$CLJS.m(g)?g:$CLJS.m(l)?l:1;if($CLJS.m(g)){var y=function(H){return $CLJS.F.h(g,$CLJS.E(H))};return lx(function(H,I){return xx($CLJS.m(c)?$CLJS.Jm.h(y,function(Q){return Fx(b,Q)}):y,Ex(a,b,v,e,H,I,g,x,q,u))})}var B=
$CLJS.m(l)?l:0;y=$CLJS.m(n)?function(H){return B<=$CLJS.E(H)&&$CLJS.E(H)<=n}:function(H){return B<=$CLJS.E(H)};return ox($CLJS.m(n)?zx(B,n):$CLJS.ux(function(H){return zx(B,B+H)}),function(H){var I=H.root;return lx(function(Q,Y){return xx($CLJS.m(c)?$CLJS.Jm.h(y,function(aa){return Fx(b,aa)}):y,Ex(a,b,v,e,Q,Y,I,x,q,u))})})};
hca=function(a,b,c,d){for(var e=function(){var g=$CLJS.ka($CLJS.ra(b,64-a));return 0===c?Math.abs(g):g}();;){if(c<=e&&e<=d)return e;var f=-e;if(c<=f&&f<=d)return f;e=function(){var g=e;if(!$CLJS.Zf(e)){var l=0>e?$CLJS.Eu:$CLJS.Xw;return l.g?l.g(g):l.call(null,g)}return g}()/2}};Kx=function(a,b){return $CLJS.ux(function(c){c=1<c?c:1;return nx(function(d){var e=d.root;d=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return yx(hca(d,e,a,b))},$CLJS.Jx.l($CLJS.G([zx(1,54>c?c:54),ica])))})};
$CLJS.sw=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.ok);b=$CLJS.M.h(b,$CLJS.Vl);var c=$CLJS.m(a)?a:jca,d=$CLJS.m(b)?b:Lx;return $CLJS.qw.h(function(e){return c<=e&&e<=d},0>=c&&0<=d?Kx(c,d):0>d?$CLJS.nw(function(e){return d+e},Kx(c-d,0)):$CLJS.nw(function(e){return c+e},Kx(0,d-c)))};Mx=function(a){if(0===a)return-1023;var b=Math.abs(a);a=Math.floor(Math.log(b)*Math.LOG2E);b*=Math.pow(2,-a);return 1>b?a-1:2<=b?a+1:a};
lca=function(a,b){function c(l,n){return $CLJS.ux(function(q){var u=1<<$CLJS.af(200<q?200:q,8);return 0>=l&&0<=n?zx(function(){var v=-u;return l>v?l:v}(),n<u?n:u):0>n?zx(function(){var v=n-u;return l>v?l:v}(),n):zx(l,function(){var v=l+u;return n<v?n:v}())})}if(null==a&&null==b)return $CLJS.Jx.l($CLJS.G([c(-1023,1023),$CLJS.ow(new $CLJS.S(null,2,5,$CLJS.T,[1,-1],null))]));var d=$CLJS.m(a)?a:kca,e=$CLJS.m(b)?b:Nx,f=function(){var l=Mx(d);return-1023>l?-1023:l}(),g=function(){var l=Mx(e);return-1023>
l?-1023:l}();return 0<=d?$CLJS.Jx.l($CLJS.G([c(f,g),$CLJS.rw(1)])):0>=e?$CLJS.Jx.l($CLJS.G([c(g,f),$CLJS.rw(-1)])):$CLJS.nw(function(l){var n=$CLJS.J(l,0,null),q=$CLJS.J(l,1,null);return 0>q&&f<n||0<q&&g<n?new $CLJS.S(null,2,5,$CLJS.T,[n,-q],null):l},$CLJS.Jx.l($CLJS.G([c(-1023,g>f?g:f),$CLJS.ow(new $CLJS.S(null,2,5,$CLJS.T,[1,-1],null))])))};
nca=function(a,b){var c=$CLJS.m(a)?$CLJS.m(b)?function(e){return a<=e&&e<=b}:function(e){return a<=e}:$CLJS.m(b)?function(e){return e<=b}:null,d=$CLJS.nw(function(e){var f=$CLJS.J(e,0,null),g=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);e=$CLJS.J(e,1,null)/Math.pow(2,52)+1;var l=e*Math.pow(2,g)*f;var n=(n=null==c)?n:c.g?c.g(l):c.call(null,l);if($CLJS.m(n))return l;f=Ox(g,f);g=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);g=$CLJS.m(a)?g>a?g:a:g;f=$CLJS.m(b)?f<b?f:b:f;e=g+(f-g)*(e-1);e=e<f?e:f;return e>g?e:g},$CLJS.Jx.l($CLJS.G([lca(a,
b),mca])));return $CLJS.m(c)?$CLJS.qw.h(c,d):d};
$CLJS.Tx=function(a){var b=$CLJS.Qf(a),c=$CLJS.M.j(b,$CLJS.Px,!0);a=$CLJS.M.j(b,$CLJS.Qx,!0);var d=$CLJS.M.h(b,$CLJS.ok);b=$CLJS.M.h(b,$CLJS.Vl);var e=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[95,nca(d,b)],null)],null);e=(null==d?null==b||0<=b:null==b?0>=d:0>=d&&0<=b)?$CLJS.kf.l(e,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.rw(0)],null),$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.rw(-0)],null)])):e;b=$CLJS.m($CLJS.m(c)?null==b:c)?$CLJS.kf.h(e,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.rw(Rx)],
null)):e;c=$CLJS.m($CLJS.m(c)?null==d:c)?$CLJS.kf.h(b,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.rw(Sx)],null)):b;a=$CLJS.m(a)?$CLJS.kf.h(c,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.rw(oca)],null)):c;return $CLJS.F.h(1,$CLJS.E(a))?$CLJS.Zd($CLJS.C(a)):Ax(a)};Ux=function(a){return wx(function(b){return $CLJS.$e(Math.pow(b,.6))},a)};Wx=function(a,b,c){return $CLJS.pw(new $CLJS.S(null,12,5,$CLJS.T,[Vx,$CLJS.zw,a,b,c,pca,$CLJS.uw,$CLJS.xw,$CLJS.yw,$CLJS.Cw,$CLJS.Dw,$CLJS.Ew],null))};
Xx=function(a){if("number"===typeof a){var b=isNaN(a);a=$CLJS.m(b)?b:$CLJS.F.h(Sx,a)||$CLJS.F.h(Rx,a);a=$CLJS.Gb(a)}else a=!0;return a};Yx=function(a){return $CLJS.pw(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.mw.g(a),$CLJS.jw(a),$CLJS.lw.g($CLJS.qw.h(Xx,a)),wx(function(b){return $CLJS.af(b,2)},$CLJS.kw.h($CLJS.qw.h(Xx,a),a))],null))};qca=function(a){return lx(function(b,c){var d=$CLJS.Ow(b);b=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.mx(a.g?a.g(b):a.call(null,b),d,c)})};
$CLJS.$x=function(a,b){return $CLJS.ux(function(c){return sx(zx(0,$CLJS.$e(Math.pow(c,1.1))),function(d){return qca(function(e){e=Zx(d,e);var f=vx(c,b);return $CLJS.Sb(function(g,l){return sx(zx(0,10),function(n){return 0===n?f:vx(l,a.g?a.g(g):a.call(null,g))})},f,e)})})})};
$CLJS.Sw.prototype.kg=$CLJS.Ma(53,function(a,b){switch(b){case 0:return $CLJS.Lg;case 1:return new $CLJS.S(null,1,5,$CLJS.T,[this],null);default:a=b-1;var c=this.state;for(b=$CLJS.Tc($CLJS.Lg);;){if($CLJS.F.h(a,$CLJS.E(b)))return $CLJS.Vc($CLJS.If(b,new $CLJS.Sw(this.gamma,c)));var d=this.gamma.add(c);c=this.gamma.add(d);var e=$CLJS.Rw(c);d=new $CLJS.Sw(e,$CLJS.Qw(d));b=$CLJS.Uc(b,d)}}});
$CLJS.Sw.prototype.jg=$CLJS.Ma(52,function(){var a=this.Se(null);a=$CLJS.Kw(a,11);return $CLJS.Hw*(a.Ba>>>0)+$CLJS.Qba*a.ra});$CLJS.Sw.prototype.Se=$CLJS.Ma(51,function(){return $CLJS.Qw(this.state.add(this.gamma))});var ay={};bx.prototype.$=function(a,b){if($CLJS.F.h(b,0))return this.root;if($CLJS.F.h(b,1))return this.children;throw Error("Index out of bounds in rose tree");};bx.prototype.Ma=function(a,b,c){return $CLJS.F.h(b,0)?this.root:$CLJS.F.h(b,1)?this.children:c};$CLJS.h=dx.prototype;
$CLJS.h.toString=function(){return $CLJS.hd(this)};$CLJS.h.N=function(){return this.M};$CLJS.h.Ja=function(){return this.Pa(null)};$CLJS.h.wa=function(){return $CLJS.yd};$CLJS.h.ad=function(){return this.seed!==ay};$CLJS.h.Na=function(a,b){a=this.Ia(null);var c=this.f.g?this.f.g(a):this.f.call(null,a);for(a=b.h?b.h(a,c):b.call(null,a,c);;){if($CLJS.Jd(a))return $CLJS.r(a);c=this.f.g?this.f.g(c):this.f.call(null,c);a=b.h?b.h(a,c):b.call(null,a,c)}};
$CLJS.h.Oa=function(a,b,c){a=this.Ia(null);for(c=b.h?b.h(c,a):b.call(null,c,a);;){if($CLJS.Jd(c))return $CLJS.r(c);a=this.f.g?this.f.g(a):this.f.call(null,a);c=b.h?b.h(c,a):b.call(null,c,a)}};$CLJS.h.Ia=function(){ay===this.seed&&(this.seed=this.f.g?this.f.g(this.Dg):this.f.call(null,this.Dg));return this.seed};$CLJS.h.Pa=function(){null==this.next&&(this.next=new dx(null,this.f,this.Ia(null),ay,null));return this.next};$CLJS.h.ba=function(){return this};
$CLJS.h.O=function(a,b){return b===this.M?this:new dx(b,this.f,this.Dg,this.seed,this.next)};$CLJS.h.ga=function(a,b){return $CLJS.nf(b,this)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,$CLJS.Ni,"("," ",")",c,this)};gx=function gx(a,b){return new $CLJS.yf(null,function(){var d=$CLJS.A(b);if(d)if(0===a)var e=$CLJS.zd(b);else{e=$CLJS.C(d);var f=a-1;d=$CLJS.zd(d);f=gx.h?gx.h(f,d):gx.call(null,f,d);e=$CLJS.nf(e,f)}else e=null;return e},null,null)};
xx=function xx(a,b){return cx(b.root,$CLJS.rg.h(function(d){return xx.h?xx.h(a,d):xx.call(null,a,d)},$CLJS.ug(function(d){d=d.root;return a.g?a.g(d):a.call(null,d)},b.children)))};qx=function qx(a,b){return cx(function(){var d=b.root;return a.g?a.g(d):a.call(null,d)}(),$CLJS.rg.h(function(d){return qx.h?qx.h(a,d):qx.call(null,a,d)},b.children))};
ix=function ix(a,b){return $CLJS.A(b)?cx($CLJS.R.h(a,$CLJS.rg.h(ax,b)),$CLJS.rg.h(function(d){return ix.h?ix.h(a,d):ix.call(null,a,d)},Zba($CLJS.jg(Infinity,b)))):cx(a.A?a.A():a.call(null),$CLJS.Lg)};by=function by(a,b){return cx($CLJS.R.h(a,$CLJS.rg.h(ax,b)),$CLJS.rg.h(function(d){return by.h?by.h(a,d):by.call(null,a,d)},fx(b)))};rx=function rx(a){var c=a.root,d=c.children;return cx(c.root,$CLJS.qg.h($CLJS.rg.h(rx,a.children),d))};$CLJS.Gx=new $CLJS.N(null,"min-elements","min-elements",949370780);
Bx=new $CLJS.N(null,"ex-fn","ex-fn",-284925510);rca=new $CLJS.N(null,"max-tries-or-opts","max-tries-or-opts",-609860571);$CLJS.Qx=new $CLJS.N(null,"NaN?","NaN?",-1917767651);$CLJS.Hx=new $CLJS.N(null,"max-elements","max-elements",433034073);$CLJS.Dx=new $CLJS.N(null,"num-elements","num-elements",1960422107);$CLJS.Cx=new $CLJS.N(null,"max-tries","max-tries",-1824441792);$CLJS.Px=new $CLJS.N(null,"infinite?","infinite?",-2017886608);var yx,sca,Vx,tca,ica,Lx,jca,Rx,Sx,Nx,kca,oca,dy,mca,Ox,ey,uca,gy,vca,wca,hy,iy,jy,pca,xca,ky,Zx;$CLJS.iw={};$CLJS.h=$CLJS.kx.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "gen":return this.sd;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#clojure.test.check.generators.Generator{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ul,this.sd],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.ul],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=236843149^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.sd,b.sd)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.ul,null],null),null),b)?$CLJS.Am.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new $CLJS.kx(this.sd,this.F,$CLJS.Rf($CLJS.Am.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "gen":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.ul,b):$CLJS.O.call(null,$CLJS.ul,b))?new $CLJS.kx(c,this.F,this.m,null):new $CLJS.kx(this.sd,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.ul,this.sd)],null),this.m))};
$CLJS.h.O=function(a,b){return new $CLJS.kx(this.sd,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};yx=function yx(a){return cx(a,$CLJS.rg.h(yx,dca(a)))};sca=new $CLJS.k(null,2,[Bx,function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.Cx);return $CLJS.zj(["Couldn't satisfy such-that predicate after ",$CLJS.p.g(b)," tries."].join(""),a)},$CLJS.Cx,10],null);
$CLJS.qw=function qw(a){switch(arguments.length){case 2:return qw.h(arguments[0],arguments[1]);case 3:return qw.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.qw.h=function(a,b){return $CLJS.qw.j(a,b,10)};
$CLJS.qw.j=function(a,b,c){if($CLJS.Ae(c))c=new $CLJS.k(null,1,[$CLJS.Cx,c],null);else if(!$CLJS.oe(c))throw $CLJS.zj("Bad argument to such-that!",new $CLJS.k(null,1,[rca,c],null));var d=$CLJS.cn.l($CLJS.G([sca,c]));return lx(function(e,f){return fca(a,b,d,e,f)})};$CLJS.qw.v=3;$CLJS.uw=$CLJS.ow(new $CLJS.S(null,2,5,$CLJS.T,[!1,!0],null));$CLJS.Jx=function Jx(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Jx.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)};
$CLJS.Jx.l=function(a){return nx(function(b){return by($CLJS.Rm,b)},px(a))};$CLJS.Jx.v=0;$CLJS.Jx.B=function(a){return this.l($CLJS.A(a))};$CLJS.cy=$CLJS.ux(function(a){return zx(0,a)});Vx=$CLJS.ux(function(a){return zx(-a,a)});tca=$CLJS.nw(function(a){return-1*a},$CLJS.cy);$CLJS.nw($CLJS.Eu,$CLJS.cy);$CLJS.nw($CLJS.Xw,tca);
$CLJS.mw=function mw(a){switch(arguments.length){case 1:return mw.g(arguments[0]);case 2:return mw.h(arguments[0],arguments[1]);case 3:return mw.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.mw.g=function(a){return ox($CLJS.ux(function(b){return zx(0,b)}),function(b){return nx(function(c){return jx($CLJS.Rm,c)},px($CLJS.ng(b.root,a)))})};$CLJS.mw.h=function(a,b){return $CLJS.R.h($CLJS.Jx,$CLJS.ng(b,a))};
$CLJS.mw.j=function(a,b,c){return ox(zx(b,c),function(d){return nx(function(e){return xx(function(f){return $CLJS.E(f)>=b&&$CLJS.E(f)<=c},jx($CLJS.Rm,e))},px($CLJS.ng(d.root,a)))})};$CLJS.mw.v=3;$CLJS.lw=function lw(a){switch(arguments.length){case 1:return lw.g(arguments[0]);case 2:return lw.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.lw.g=function(a){return $CLJS.lw.h(a,$CLJS.P)};
$CLJS.lw.h=function(a,b){return $CLJS.Ix($CLJS.oi,$CLJS.Ye,!1,!1,a,b)};$CLJS.lw.v=2;$CLJS.kw=function kw(a){switch(arguments.length){case 2:return kw.h(arguments[0],arguments[1]);case 3:return kw.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.kw.h=function(a,b){return $CLJS.kw.j(a,b,$CLJS.P)};$CLJS.kw.j=function(a,b,c){return $CLJS.Ix($CLJS.P,$CLJS.C,!1,!1,$CLJS.Jx.l($CLJS.G([a,b])),c)};$CLJS.kw.v=3;
ica=lx(function(a){return cx(Yw(a),$CLJS.Lg)});Lx=$CLJS.R.h($CLJS.Dm,$CLJS.ng(53,2))-1;jca=-Lx;$CLJS.zw=$CLJS.sw($CLJS.P);Rx=Number.POSITIVE_INFINITY;Sx=Number.NEGATIVE_INFINITY;Nx=Number.MAX_VALUE;kca=-Nx;oca=Number.NaN;
dy=function dy(a){return 32>=a?zx(0,function(){switch($CLJS.$e(a)){case 32:return 4294967295;case 31:return 2147483647;default:return(1<<a)-1}}()):$CLJS.nw(function(c){var d=$CLJS.J(c,0,null);c=$CLJS.J(c,1,null);return 4294967296*d+c},$CLJS.Jx.l($CLJS.G([function(){var c=a-32;return dy.g?dy.g(c):dy.call(null,c)}(),dy.g?dy.g(32):dy.call(null,32)])))};
mca=$CLJS.nw(function(a){for(var b=0,c=Math.pow(2,52);;){if(1>a)return b*c;var d=a/2;c/=2;b=2*b+(a&1);a=d}},$CLJS.ux(function(a){return ox(zx(0,52>a?a:52),function(b){return dy(b.root)})}));
Ox=function Ox(a,b){return 0>b?(b=-b,b=Ox.h?Ox.h(a,b):Ox.call(null,a,b),a=$CLJS.J(b,0,null),b=$CLJS.J(b,1,null),new $CLJS.S(null,2,5,$CLJS.T,[-b,-a],null)):$CLJS.F.h(-1023,a)?new $CLJS.S(null,2,5,$CLJS.T,[0,(1*Math.pow(2,52)-1)*Math.pow(2,-1074)],null):new $CLJS.S(null,2,5,$CLJS.T,[1*Math.pow(2,a),(1*Math.pow(2,52)-1)*Math.pow(2,a-51)],null)};$CLJS.ww=$CLJS.Tx($CLJS.P);$CLJS.vw=$CLJS.nw($CLJS.Fu,zx(0,255));ey=$CLJS.nw($CLJS.Fu,zx(32,126));
$CLJS.fy=$CLJS.nw($CLJS.Fu,$CLJS.pw(new $CLJS.S(null,3,5,$CLJS.T,[zx(48,57),zx(65,90),zx(97,122)],null)));uca=$CLJS.nw($CLJS.Fu,$CLJS.pw(new $CLJS.S(null,2,5,$CLJS.T,[zx(65,90),zx(97,122)],null)));gy=$CLJS.ow(new $CLJS.S(null,7,5,$CLJS.T,"*+!-_?.".split(""),null));vca=Ax(new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[14,$CLJS.fy],null),new $CLJS.S(null,2,5,$CLJS.T,[7,gy],null),new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.rw(":")],null)],null));
wca=Ax(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[2,uca],null),new $CLJS.S(null,2,5,$CLJS.T,[1,gy],null)],null));hy=$CLJS.nw($CLJS.dv,$CLJS.mw.g($CLJS.vw));iy=$CLJS.nw($CLJS.dv,$CLJS.mw.g(ey));$CLJS.Bw=$CLJS.nw($CLJS.dv,$CLJS.mw.g($CLJS.fy));
jy=$CLJS.nw(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);b=$CLJS.dv.g($CLJS.nf(b,a));return $CLJS.Ou($CLJS.Ou(b,/:{2,}/,":"),/:$/,"")},$CLJS.qw.h(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);a=$CLJS.J(a,0,null);b=$CLJS.m(a)?(b="+"===b||"-"===b)?!/[^0-9]/.test(a):b:a;return!$CLJS.ye(b)},$CLJS.Jx.l($CLJS.G([wca,$CLJS.mw.g(vca)]))));$CLJS.xw=Ux($CLJS.nw($CLJS.Oi,jy));
$CLJS.yw=Ux($CLJS.nw(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.Oi.h(b,a)},$CLJS.Jx.l($CLJS.G([jy,jy]))));$CLJS.Cw=Ax(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[100,Ux($CLJS.nw($CLJS.Ui,jy))],null),new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.rw($CLJS.Vu)],null)],null));$CLJS.Dw=Ux($CLJS.nw(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.Ui.h(b,a)},$CLJS.Jx.l($CLJS.G([jy,jy]))));
pca=$CLJS.nw(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return b/a},$CLJS.Jx.l($CLJS.G([Vx,$CLJS.nw($CLJS.Eu,$CLJS.cy)])));
$CLJS.Ew=function(a){return nx(function(b){return cx(b.root,$CLJS.Lg)},a)}($CLJS.nw(function(a){function b(d){return(a.g?a.g(d):a.call(null,d)).toString(16)}var c=(((a.g?a.g(15):a.call(null,15))&3)+8).toString(16);return $CLJS.Iu([$CLJS.p.g(b(0)),$CLJS.p.g(b(1)),$CLJS.p.g(b(2)),$CLJS.p.g(b(3)),$CLJS.p.g(b(4)),$CLJS.p.g(b(5)),$CLJS.p.g(b(6)),$CLJS.p.g(b(7)),"-",$CLJS.p.g(b(8)),$CLJS.p.g(b(9)),$CLJS.p.g(b(10)),$CLJS.p.g(b(11)),"-4",$CLJS.p.g(b(12)),$CLJS.p.g(b(13)),$CLJS.p.g(b(14)),"-",$CLJS.p.g(c),
$CLJS.p.g(b(16)),$CLJS.p.g(b(17)),$CLJS.p.g(b(18)),"-",$CLJS.p.g(b(19)),$CLJS.p.g(b(20)),$CLJS.p.g(b(21)),$CLJS.p.g(b(22)),$CLJS.p.g(b(23)),$CLJS.p.g(b(24)),$CLJS.p.g(b(25)),$CLJS.p.g(b(26)),$CLJS.p.g(b(27)),$CLJS.p.g(b(28)),$CLJS.p.g(b(29)),$CLJS.p.g(b(30))].join(""))},$CLJS.mw.h(zx(0,15),31)));xca=Wx($CLJS.ww,$CLJS.vw,hy);$CLJS.Aw=Wx($CLJS.ww,ey,iy);Wx($CLJS.Tx(new $CLJS.k(null,1,[$CLJS.Qx,!1],null)),$CLJS.vw,hy);Wx($CLJS.Tx(new $CLJS.k(null,1,[$CLJS.Qx,!1],null)),ey,iy);ky=Math.log(2);
Zx=function Zx(a,b){if(2>=a)return new $CLJS.S(null,1,5,$CLJS.T,[a],null);var d=Math.log(a);b=$CLJS.Ow(b);var e=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);d=$CLJS.$e(Math.exp(Zw(e)*(d-ky)+ky));e=$CLJS.af(a,d);return 1<d&&1<e?$CLJS.nf(d,Zx.h?Zx.h(e,b):Zx.call(null,e,b)):new $CLJS.S(null,1,5,$CLJS.T,[a],null)};$CLJS.yca=$CLJS.$x(Yx,xca);$CLJS.tw=$CLJS.$x(Yx,$CLJS.Aw);