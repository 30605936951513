import { connect } from "react-redux";
import { State } from "metabase-types/store";
import FileUploadLayout from "metabase/components/uploadFile/fileUpload/components/FileUploadLayout";

const mapStateToProps = (state: State) => ({
  showIllustration: state.settings.values["show-lighthouse-illustration"],
  currentUser: state.currentUser,
});

export default connect(mapStateToProps)(FileUploadLayout);
