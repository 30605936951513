var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Hoa,c0,Ioa,Joa,Loa,Koa;$CLJS.Z_=function(a,b){return $CLJS.R.j($CLJS.xa,a,b)};Hoa=function(a){var b=new $CLJS.rb,c=$CLJS.Sz;$CLJS.Sz=new $CLJS.gd(b);try{var d=$CLJS.Sz,e=$CLJS.Gb($CLJS.$A(d)),f=$CLJS.Sz;$CLJS.Sz=e?$CLJS.RA(d):d;try{d=$CLJS.bB;$CLJS.bB=!0;try{$CLJS.dB(a)}finally{$CLJS.bB=d}$CLJS.F.h(0,$CLJS.Zz($CLJS.Sz,$CLJS.bA))||$CLJS.Qc($CLJS.Sz,"\n");$CLJS.Yz()}finally{$CLJS.Sz=f}$CLJS.Oz($CLJS.p.g(b))}finally{$CLJS.Sz=c}};
$CLJS.$_=function(a){var b=new $CLJS.rb,c=$CLJS.Ab;$CLJS.Ab=function(d){return b.append(d)};try{Hoa(a)}finally{$CLJS.Ab=c}return $CLJS.p.g(b)};$CLJS.a0=function(a){return $CLJS.qe(a)&&1<$CLJS.E(a)&&$CLJS.C(a)instanceof $CLJS.N&&$CLJS.oe($CLJS.Zd(a))&&$CLJS.Ie($CLJS.Zd(a),$CLJS.IG)};$CLJS.b0=function(a){return $CLJS.a0(a)?$CLJS.Ku(a,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.NL],null)):null};
c0=function(a,b,c){var d=$CLJS.Ie(a,b)?$CLJS.Nm.j(a,b,function(e){return $CLJS.qe(e)&&$CLJS.F.h($CLJS.C(e),$CLJS.Lj)?$CLJS.Mg($CLJS.kg(1,e)):new $CLJS.S(null,1,5,$CLJS.T,[e],null)}):a;return $CLJS.Ie(a,b)?$CLJS.RN(d,$CLJS.Pf([b,c])):d};Ioa=function(a){var b=$CLJS.li(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$P,$CLJS.mR],null));b=d0.g?d0.g(b):d0.call(null,b);return $CLJS.gW($CLJS.U.l(c0($CLJS.Am.l(a,$CLJS.$P,$CLJS.G([$CLJS.mR])),$CLJS.bS,$CLJS.lZ),$CLJS.vz,$CLJS.dZ,$CLJS.G([$CLJS.iZ,b])))};
Joa=function(a){return $CLJS.Mm.h(Ioa,a)};Loa=function(a){return $CLJS.m(a)?$CLJS.U.j($CLJS.Nm.j($CLJS.xe(a)?new $CLJS.k(null,1,[$CLJS.e0,a],null):a,$CLJS.e0,function(b){return $CLJS.Mm.h(function(c){return $CLJS.U.j($CLJS.cG(c,$CLJS.oG),$CLJS.vz,$CLJS.iW)},b)}),$CLJS.vz,Koa):null};
$CLJS.f0=function(a){if($CLJS.F.h($CLJS.vz.g(a),$CLJS.NZ))return a;var b=$CLJS.Tk.g(a);b=b instanceof $CLJS.N?b.S:null;switch(b){case "native":return $CLJS.cn.l($CLJS.G([new $CLJS.k(null,2,[$CLJS.vz,$CLJS.NZ,$CLJS.iZ,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.cn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.vz,$CLJS.JZ],null),$CLJS.RN($CLJS.VQ.g(a),new $CLJS.k(null,1,[$CLJS.wO,$CLJS.VQ],null))]))],null)],null),$CLJS.Am.l(a,$CLJS.Tk,$CLJS.G([$CLJS.VQ]))]));case "query":return $CLJS.cn.l($CLJS.G([new $CLJS.k(null,
2,[$CLJS.vz,$CLJS.NZ,$CLJS.iZ,d0($CLJS.wO.g(a))],null),$CLJS.Am.l(a,$CLJS.Tk,$CLJS.G([$CLJS.wO]))]));default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}};$CLJS.g0=function(a){return"string"===typeof a&&(a=$CLJS.Ei(/^card__(\d+)$/,a),$CLJS.m(a))?($CLJS.J(a,0,null),a=$CLJS.J(a,1,null),$CLJS.qL(a)):null};$CLJS.h0=new $CLJS.N("lib","source","lib/source",-434086550);$CLJS.i0=new $CLJS.N("metadata","segment","metadata/segment",-1286738015);
$CLJS.j0=new $CLJS.N("metadata","table","metadata/table",-882499405);$CLJS.Moa=new $CLJS.N(null,"num-stages","num-stages",1426797535);$CLJS.k0=new $CLJS.N(null,"tables","tables",1334623052);$CLJS.l0=new $CLJS.N(null,"table-id","table-id",-766649466);$CLJS.e0=new $CLJS.N(null,"columns","columns",1998437288);$CLJS.Noa=new $CLJS.N("metadata","database","metadata/database",-924828824);$CLJS.m0=new $CLJS.N(null,"table","table",-564943036);$CLJS.n0=new $CLJS.N("metadata","card","metadata/card",-1039333889);
Koa=new $CLJS.N("metadata","results","metadata/results",330329298);var d0;
d0=function d0(a){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.mR),d=$CLJS.M.h(a,$CLJS.PP);c=$CLJS.m(c)?d0.g?d0.g(c):d0.call(null,c):$CLJS.Lg;d=Loa(d);var e=$CLJS.E(c)-1;c=$CLJS.m($CLJS.m(d)?!(0>e):d)?$CLJS.Qu(c,new $CLJS.S(null,2,5,$CLJS.T,[e,$CLJS.pZ],null),d):c;d=$CLJS.m($CLJS.VQ.g(a))?$CLJS.JZ:$CLJS.DZ;a=$CLJS.cn.l($CLJS.G([new $CLJS.k(null,1,[$CLJS.vz,d],null),$CLJS.Am.l(a,$CLJS.mR,$CLJS.G([$CLJS.PP]))]));a=$CLJS.A($CLJS.WO.g(a))?$CLJS.Nm.j(a,$CLJS.WO,Joa):a;a=c0(a,$CLJS.KO,$CLJS.OZ);return $CLJS.kf.h(c,a)};
$CLJS.o0=RegExp(" id$","i");