var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.card.js");require("./metabase.lib.column_group.js");require("./metabase.lib.common.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.filter.js");require("./metabase.lib.join.js");require("./metabase.lib.limit.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.metric.js");require("./metabase.lib.native.js");require("./metabase.lib.normalize.js");require("./metabase.lib.order_by.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.segment.js");require("./metabase.lib.stage.js");require("./metabase.lib.table.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.shared.util.namespaces.js");
'use strict';var Mta=function(a,b){$CLJS.Sb(function(c,d){return a.g?a.g(d):a.call(null,d)},null,b)},t8=function(a,b){return $CLJS.Vc($CLJS.Sb(function(c,d){return $CLJS.m(a.g?a.g(d):a.call(null,d))?$CLJS.Uc(c,d):c},$CLJS.Tc($CLJS.Lg),b))},u8=function(a,b){if(b=$CLJS.A(b)){var c=$CLJS.A(b);b=$CLJS.C(c);c=$CLJS.D(c);return $CLJS.A(c)?(c=u8($CLJS.M.h(a,b),c),$CLJS.je(c)?$CLJS.Am.h(a,b):$CLJS.U.j(a,b,c)):$CLJS.Am.h(a,b)}return a},Nta=function(a,b,c,d){return function q(f,g,l,n){var u=$CLJS.A(g);g=$CLJS.C(u);
u=$CLJS.D(u);var v=$CLJS.Ke(f,g);return $CLJS.m(v)?u?$CLJS.U.j(f,g,q($CLJS.uc(v),u,l,n)):$CLJS.U.j(f,g,$CLJS.R.j(l,$CLJS.uc(v),n)):f}(a,b,c,d)},Ota=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Nta(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.z(b.slice(3),0,null):null)},Pta=function(a,b){return $CLJS.C($CLJS.eg(function(c){if($CLJS.m($CLJS.m(c)?b:c)){var d=$CLJS.AF.g(c);c=$CLJS.BF.g(c);d=$CLJS.m($CLJS.Ei(d,b))?$CLJS.Ou(b,d,c):null}else d=
null;return d},a))},v8=function(a){a=$CLJS.vH(a);var b=$CLJS.PF(a);b||($CLJS.m(a)?(b=$CLJS.m(a)?!$CLJS.Ie($CLJS.r($CLJS.BH),$CLJS.vH(a).toLowerCase()):null,b=$CLJS.Gb(b)):b=null);return $CLJS.m(b)?a:Pta($CLJS.Pc($CLJS.r($CLJS.xH)),a)},Qta=function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);return $CLJS.m(a)?new $CLJS.k(null,4,[$CLJS.vz,$CLJS.tL,$CLJS.QJ,b instanceof $CLJS.N?$CLJS.ui(b):b,$CLJS.Lk,c,$CLJS.hl,$CLJS.Sm.h(a,2)],null):null},w8=function(a,b){if(null!=a&&null!=a.oh)a=a.oh(a,b);else{var c=
w8[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=w8._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("MetadataProvider.metrics",a);}return a},x8=function(a){return $CLJS.a0(a)?$CLJS.Ku(a,new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.IG],null)):null},Rta=function(a,b,c,d){var e=$CLJS.F.h($CLJS.QQ,$CLJS.C(b))?$CLJS.b3(d,$CLJS.b0(c)):d;return Ota(a,b,function(f){return $CLJS.Mg(function(){return function n(l){return new $CLJS.yf(null,function(){for(;;){var q=$CLJS.A(l);
if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Bf(v);a:for(var y=0;;)if(y<v){var B=$CLJS.be(u,y);B=$CLJS.F.h(x8(B),x8(c))?e:B;x.add(B);y+=1}else{u=!0;break a}return u?$CLJS.Ef($CLJS.Gf(x),n($CLJS.ad(q))):$CLJS.Ef($CLJS.Gf(x),null)}x=$CLJS.C(q);return $CLJS.nf($CLJS.F.h(x8(x),x8(c))?e:x,n($CLJS.zd(q)))}return null}},null,null)}(f)}())})},y8=function(a,b,c){var d=$CLJS.Ku(a,b);if($CLJS.m(d)){var e=x8(c),f=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.C(b),$CLJS.$d(b)],null);c=$CLJS.J(f,0,null);
f=$CLJS.J(f,1,null);d=$CLJS.oh.j($CLJS.Lg,$CLJS.Lm.g($CLJS.Im.h($CLJS.pi([e]),x8)),d);d=$CLJS.F.h(b,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.yO],null))&&$CLJS.Wf(function(g){return $CLJS.a0(g)&&$CLJS.F.h($CLJS.C(g),$CLJS.AG)},d)?null:d;if($CLJS.A(d))return $CLJS.Qu(a,b,d);if($CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.WO,$CLJS.lZ],null),new $CLJS.S(null,2,5,$CLJS.T,[c,f],null)))throw $CLJS.zj($CLJS.WH("Cannot remove the final join condition"),new $CLJS.k(null,1,[$CLJS.lZ,$CLJS.Ku(a,b)],null));return $CLJS.F.h(new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.WO,$CLJS.yO],null),new $CLJS.S(null,2,5,$CLJS.T,[c,f],null))?$CLJS.q_.D(a,$CLJS.ie(b),$CLJS.Am,f):u8(a,b)}return a},Sta=function(a,b){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.iZ);b=0>b?$CLJS.E(a)+b:b;return b+1<$CLJS.E(a)?b+1:null},z8=function(a,b,c,d){a=$CLJS.f0(a);var e=$CLJS.Qf(a);a=$CLJS.M.h(e,$CLJS.iZ);b=$CLJS.m(b)?b:-1;var f=$CLJS.W0(e,b);f=!($CLJS.A($CLJS.OP.g(f))||$CLJS.A($CLJS.HP.g(f)));c=$CLJS.X0(e,b,$CLJS.Nm,$CLJS.G([c,function(g){return $CLJS.kf.h($CLJS.Mg(g),$CLJS.kW.g(d))}]));
return f?$CLJS.Nm.R($CLJS.f6(c,b,function(g){return $CLJS.r_.j($CLJS.Am.l(g,$CLJS.$R,$CLJS.G([$CLJS.yO])),$CLJS.WO,function(l){return $CLJS.Mm.h(function(n){return $CLJS.Am.h(n,$CLJS.yO)},l)})}),$CLJS.iZ,$CLJS.Im.h(function(g){return $CLJS.oh.h($CLJS.Lg,g)},$CLJS.Sm),0,$CLJS.V0(a,b)+1):c},A8=function(a){var b=$CLJS.FH(a);b=$CLJS.m($CLJS.jk.g(b))?null:$CLJS.DG.g(b);return $CLJS.m(b)?$CLJS.eW(a,$CLJS.U,$CLJS.G([$CLJS.jk,b])):a},Tta=function(a){if($CLJS.F.h($CLJS.vz.g($CLJS.W0(a,-1)),$CLJS.JZ))return null;
try{return $CLJS.b1.h(a,a)}catch(c){if(c instanceof Error){a=c;var b=$CLJS.YF($CLJS.Xu);$CLJS.m($CLJS.eG("metabase.lib.metadata.calculation",b))&&(a instanceof Error?$CLJS.fG("metabase.lib.metadata.calculation",b,$CLJS.VB.l($CLJS.G([$CLJS.VH("Error calculating display name for query: {0}",$CLJS.G([$CLJS.TZ(a)]))])),a):$CLJS.fG("metabase.lib.metadata.calculation",b,$CLJS.VB.l($CLJS.G([a,$CLJS.VH("Error calculating display name for query: {0}",$CLJS.G([$CLJS.TZ(a)]))])),null));return null}throw c;}},
Uta=function(a,b){a=$CLJS.n2(a);b=$CLJS.m(b)?b:$CLJS.xz;return 0===a?$CLJS.WH("Now"):0>a?$CLJS.VH("{0} {1} ago",$CLJS.G([Math.abs(a),$CLJS.G1.h(Math.abs(a),b).toLowerCase()])):$CLJS.VH("{0} {1} from now",$CLJS.G([a,$CLJS.G1.h(a,b).toLowerCase()]))},Vta=function(a){var b=new $CLJS.S(null,9,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.CV,$CLJS.dm,$CLJS.UI,$CLJS.WH("Is")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.DK,$CLJS.UI,$CLJS.WH("Is not")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.gm,$CLJS.UI,$CLJS.WH("Greater than")],
null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.em,$CLJS.UI,$CLJS.WH("Less than")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.KI,$CLJS.UI,$CLJS.WH("Between")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.pm,$CLJS.UI,$CLJS.WH("Greater than or equal to")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.qm,$CLJS.UI,$CLJS.WH("Less than or equal to")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.yI,$CLJS.UI,$CLJS.WH("Is empty")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.oJ,$CLJS.UI,$CLJS.WH("Not empty")],null)],null);return $CLJS.e2(a,
$CLJS.Sj)?b:$CLJS.e2(a,$CLJS.Kj)?b:$CLJS.e2(a,$CLJS.aI)?new $CLJS.S(null,8,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.CV,$CLJS.dm,$CLJS.UI,$CLJS.WH("Is")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.DK,$CLJS.UI,$CLJS.WH("Is not")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.LI,$CLJS.UI,$CLJS.WH("Is empty")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.aJ,$CLJS.UI,$CLJS.WH("Not empty")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.LJ,$CLJS.UI,$CLJS.WH("Contains")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.wJ,$CLJS.UI,
$CLJS.WH("Does not contain")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.vJ,$CLJS.UI,$CLJS.WH("Starts with")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.nK,$CLJS.UI,$CLJS.WH("Ends with")],null)],null):$CLJS.e2(a,$CLJS.Il)?new $CLJS.S(null,7,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.CV,$CLJS.DK,$CLJS.UI,$CLJS.WH("Excludes")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.dm,$CLJS.UI,$CLJS.WH("Is")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.em,$CLJS.UI,$CLJS.WH("Before")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.gm,
$CLJS.UI,$CLJS.WH("After")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.KI,$CLJS.UI,$CLJS.WH("Between")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.yI,$CLJS.UI,$CLJS.WH("Is empty")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.oJ,$CLJS.UI,$CLJS.WH("Not empty")],null)],null):$CLJS.e2(a,$CLJS.jl)?new $CLJS.S(null,8,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.CV,$CLJS.dm,$CLJS.UI,$CLJS.WH("Is")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.DK,$CLJS.UI,$CLJS.WH("Is not")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.VI,$CLJS.UI,
$CLJS.WH("Inside")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.gm,$CLJS.UI,$CLJS.WH("Greater than")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.em,$CLJS.UI,$CLJS.WH("Less than")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.KI,$CLJS.UI,$CLJS.WH("Between")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.pm,$CLJS.UI,$CLJS.WH("Greater than or equal to")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.qm,$CLJS.UI,$CLJS.WH("Less than or equal to")],null)],null):$CLJS.e2(a,$CLJS.Bl)?new $CLJS.S(null,9,5,$CLJS.T,[new $CLJS.k(null,
2,[$CLJS.CV,$CLJS.dm,$CLJS.UI,$CLJS.WH("Equal to")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.DK,$CLJS.UI,$CLJS.WH("Not equal to")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.gm,$CLJS.UI,$CLJS.WH("Greater than")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.em,$CLJS.UI,$CLJS.WH("Less than")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.KI,$CLJS.UI,$CLJS.WH("Between")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.pm,$CLJS.UI,$CLJS.WH("Greater than or equal to")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.qm,$CLJS.UI,
$CLJS.WH("Less than or equal to")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.yI,$CLJS.UI,$CLJS.WH("Is empty")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.oJ,$CLJS.UI,$CLJS.WH("Not empty")],null)],null):$CLJS.e2(a,$CLJS.Pl)?new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.CV,$CLJS.dm,$CLJS.UI,$CLJS.WH("Is")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.DK,$CLJS.UI,$CLJS.WH("Is not")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.LJ,$CLJS.UI,$CLJS.WH("Contains")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.wJ,
$CLJS.UI,$CLJS.WH("Does not contain")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.yI,$CLJS.UI,$CLJS.WH("Is null")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.oJ,$CLJS.UI,$CLJS.WH("Not null")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.LI,$CLJS.UI,$CLJS.WH("Is empty")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.aJ,$CLJS.UI,$CLJS.WH("Not empty")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.vJ,$CLJS.UI,$CLJS.WH("Starts with")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.nK,$CLJS.UI,$CLJS.WH("Ends with")],null)],null):
$CLJS.e2(a,$CLJS.Wk)?new $CLJS.S(null,6,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.CV,$CLJS.dm,$CLJS.UI,$CLJS.WH("Is")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.DK,$CLJS.UI,$CLJS.WH("Is not")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.yI,$CLJS.UI,$CLJS.WH("Is null")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.oJ,$CLJS.UI,$CLJS.WH("Not null")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.LI,$CLJS.UI,$CLJS.WH("Is empty")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.aJ,$CLJS.UI,$CLJS.WH("Not empty")],null)],null):$CLJS.e2(a,
$CLJS.xl)?new $CLJS.S(null,3,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.CV,$CLJS.dm,$CLJS.UI,$CLJS.WH("Is")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.yI,$CLJS.UI,$CLJS.WH("Is empty")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.oJ,$CLJS.UI,$CLJS.WH("Not empty")],null)],null):new $CLJS.S(null,4,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.CV,$CLJS.dm,$CLJS.UI,$CLJS.WH("Is")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.DK,$CLJS.UI,$CLJS.WH("Is not")],null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.yI,$CLJS.UI,$CLJS.WH("Is null")],
null),new $CLJS.k(null,2,[$CLJS.CV,$CLJS.oJ,$CLJS.UI,$CLJS.WH("Not null")],null)],null)},Wta=function(a){return $CLJS.r2.g(a)},Xta=function(a,b){var c=$CLJS.oh.j($CLJS.P,$CLJS.Cn(function(e){var f=$CLJS.hW(e);f=$CLJS.R0.h?$CLJS.R0.h(f,b):$CLJS.R0.call(null,f,b);return $CLJS.m(f)?new $CLJS.S(null,2,5,$CLJS.T,[f,e],null):null}),a);c=$CLJS.rg.h(c,b);var d=$CLJS.wW($CLJS.Km.h($CLJS.i2,$CLJS.j2),c);if($CLJS.m(d))return d;c=$CLJS.C(c);if($CLJS.m(c))return c;c=$CLJS.wW($CLJS.i2,a);if($CLJS.m(c))return c;
c=$CLJS.wW($CLJS.j2,a);return $CLJS.m(c)?c:$CLJS.C(a)},Yta=function(a){return"string"===typeof a?(0,$CLJS.Ca)($CLJS.Ou(a,/ id$/i,"")):null},Zta=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Yi(function(){return v8(b)});var d=new $CLJS.Yi(function(){return v8(a)});return $CLJS.F.h(a,b)||$CLJS.F.h(a,$CLJS.r(c))||$CLJS.F.h($CLJS.r(d),b)||$CLJS.F.h($CLJS.r(d),$CLJS.r(c))}return c}return c},$ta=function(a,b,c){var d=$CLJS.b1.h($CLJS.F.h($CLJS.vz.g(b),$CLJS.NZ)?b:a,
b),e=$CLJS.m(c)?Yta($CLJS.b1.h(a,c)):null,f=Zta(d,e);return function(){var g=function(){if($CLJS.m(d)){if($CLJS.m(e)){var l=$CLJS.Gb($CLJS.Di(/id/i,e));return l?(l=!f)?[$CLJS.p.g(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.m(g)?g:$CLJS.m(d)?d:$CLJS.m(e)?e:"source"}()},B8=function(a){return $CLJS.a0(a)&&$CLJS.F.h($CLJS.C(a),$CLJS.FQ)},aua=function(a,b,c,d){return function l(f,g){try{if(B8(g)&&$CLJS.ye($CLJS.R0.j?$CLJS.R0.j(a,g,d):$CLJS.R0.call(null,a,g,d)))return $CLJS.Y3(g,c);throw $CLJS.Z;
}catch(q){if(q instanceof Error){var n=q;if(n===$CLJS.Z)return $CLJS.e_(l,f,g);throw n;}throw q;}}($CLJS.Lg,b)},bua=function(a,b,c,d,e){b=aua(a,b,c,e);return function n(g,l){try{if($CLJS.qe(l)&&4===$CLJS.E(l))try{var q=$CLJS.Td(l,2);if(B8(q))try{var u=$CLJS.Td(l,3);if(B8(u)){var v=$CLJS.Td(l,3),x=$CLJS.Td(l,2),y=$CLJS.Td(l,0),B=$CLJS.Td(l,1),H=$CLJS.Ie($CLJS.FH(x),$CLJS.nQ),I=$CLJS.Ie($CLJS.FH(v),$CLJS.nQ);if(H||I){if(H&&I){var Q=$CLJS.eW(x,$CLJS.Am,$CLJS.G([$CLJS.nQ])),Y=$CLJS.eW(v,$CLJS.Am,$CLJS.G([$CLJS.nQ])),
aa=null==($CLJS.R0.j?$CLJS.R0.j(a,Q,d):$CLJS.R0.call(null,a,Q,d));var ha=aa?$CLJS.R0.j?$CLJS.R0.j(a,Y,d):$CLJS.R0.call(null,a,Y,d):aa;return $CLJS.m(ha)?new $CLJS.S(null,4,5,$CLJS.T,[y,B,x,Y],null):new $CLJS.S(null,4,5,$CLJS.T,[y,B,Q,v],null)}return l}return $CLJS.m($CLJS.R0.j?$CLJS.R0.j(a,v,d):$CLJS.R0.call(null,a,v,d))?new $CLJS.S(null,4,5,$CLJS.T,[y,B,$CLJS.Y3(x,c),v],null):new $CLJS.S(null,4,5,$CLJS.T,[y,B,x,$CLJS.Y3(v,c)],null)}throw $CLJS.Z;}catch(Ea){if(Ea instanceof Error){var qa=Ea;if(qa===
$CLJS.Z)throw $CLJS.Z;throw qa;}throw Ea;}else throw $CLJS.Z;}catch(Ea){if(Ea instanceof Error){qa=Ea;if(qa===$CLJS.Z)throw $CLJS.Z;throw qa;}throw Ea;}else throw $CLJS.Z;}catch(Ea){if(Ea instanceof Error){qa=Ea;if(qa===$CLJS.Z)return $CLJS.e_(n,g,l);throw qa;}throw Ea;}}($CLJS.Lg,b)},cua=function(a,b){var c=$CLJS.d3();Mta(c,b);return c(a)},dua=function(a,b,c){var d=$CLJS.W0(a,b);b=$CLJS.O5(a,b,d);var e=$CLJS.A($CLJS.ug($CLJS.Hb,function v(q,u){try{if($CLJS.qe(u)&&1<=$CLJS.E(u))try{var x=$CLJS.Sm.j(u,
0,1);if($CLJS.qe(x)&&1===$CLJS.E(x))try{var y=$CLJS.Td(x,0);if($CLJS.O(y,$CLJS.FQ))return new $CLJS.S(null,1,5,$CLJS.T,[u],null);throw $CLJS.Z;}catch(H){if(H instanceof Error){var B=H;if(B===$CLJS.Z)throw $CLJS.Z;throw B;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){B=H;if(B===$CLJS.Z)throw $CLJS.Z;throw B;}throw H;}else throw $CLJS.Z;}catch(H){if(H instanceof Error){B=H;if(B===$CLJS.Z)return $CLJS.tZ(v,q,u);throw B;}throw H;}}($CLJS.Lg,$CLJS.lZ.g(c))));e=Xta(b,e);var f=cua($ta(a,
c,e),$CLJS.rg.h($CLJS.xP,$CLJS.WO.g(d))),g=$CLJS.Mm.h($CLJS.hW,b),l=$CLJS.Mm.h($CLJS.hW,$CLJS.o5($CLJS.V3(a,$CLJS.iZ.g(c))));return $CLJS.Y3($CLJS.Nm.j(c,$CLJS.lZ,function(q){return $CLJS.Mm.h(function(u){return bua(a,u,f,g,l)},q)}),f)},eua=function(a){return $CLJS.lZ.g(a)},fua=function(a){return $CLJS.yO.g(a)},gua=function(a){return $CLJS.M.j(a,$CLJS.XK,$CLJS.yQ)},hua=function(a,b){return $CLJS.U.j(a,$CLJS.XK,b)},C8=function(a){a=$CLJS.fj(function(d){return $CLJS.j2(d)?$CLJS.b4:$CLJS.i2(d)?$CLJS.a4:
$CLJS.oz},a);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.b4);var c=$CLJS.M.h(b,$CLJS.a4);b=$CLJS.M.h(b,$CLJS.oz);return $CLJS.qg.l(a,c,$CLJS.G([b]))},D8=function(){return new $CLJS.k(null,3,[$CLJS.vz,$CLJS.jY,$CLJS.CV,$CLJS.dm,$CLJS.UI,$CLJS.WH("Equal to")],null)},iua=function(a,b,c){var d=$CLJS.Ek.g(c);return $CLJS.m(d)?$CLJS.wW(function(e){e=$CLJS.Qf(e);var f=$CLJS.M.h(e,$CLJS.c4);return $CLJS.i2(e)&&$CLJS.F.h(f,d)},$CLJS.O5(a,b,$CLJS.W0(a,b))):null},jua=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.h0);
switch(b instanceof $CLJS.N?b.S:null){case "source/implicitly-joinable":return new $CLJS.k(null,2,[$CLJS.f4,$CLJS.Kqa,$CLJS.kR,$CLJS.kR.g(a)],null);case "source/joins":return new $CLJS.k(null,2,[$CLJS.f4,$CLJS.Lqa,$CLJS.nQ,$CLJS.P5(a)],null);default:return new $CLJS.k(null,1,[$CLJS.f4,$CLJS.Jqa],null)}},kua=function(a){return $CLJS.Mm.h(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.U.l(c,$CLJS.vz,$CLJS.g4,$CLJS.G([$CLJS.h4,b]))},$CLJS.fj(jua,a))},lua=function(a){return $CLJS.h4.g(a)},
mua=function(a){return $CLJS.e0.g(a)},nua=function(a,b){if($CLJS.A(a)){var c=$CLJS.J(b,0,null);$CLJS.J(b,1,null);var d=$CLJS.J(b,2,null),e=$CLJS.RQ.g($CLJS.FH(d));return $CLJS.Mm.h(function(f){return $CLJS.F.h($CLJS.CV.g(f),c)?$CLJS.r_.j($CLJS.U.j(f,$CLJS.C0,!0),$CLJS.e0,function(g){var l=$CLJS.Mm.h($CLJS.hW,g),n=function(){var q=$CLJS.eW(d,$CLJS.Am,$CLJS.G([$CLJS.RQ]));return $CLJS.R0.h?$CLJS.R0.h(q,l):$CLJS.R0.call(null,q,l)}();return $CLJS.m(n)?$CLJS.Mm.j(function(q,u){q=$CLJS.F.h(q,n)?$CLJS.U.j(u,
$CLJS.C0,!0):u;return null!=e?$CLJS.Z7(q,e):q},l,g):g}):f},a)}return null},oua=function(a,b,c){var d=$CLJS.W0(a,b);a=$CLJS.O5(a,b,d);var e=$CLJS.kG(c);return $CLJS.Xf(function(f){return $CLJS.F.h($CLJS.kG($CLJS.V.g(f)),e)},a)},pua=function(a,b){var c=$CLJS.Nm.D(a,$CLJS.QQ,$CLJS.cg($CLJS.kf,$CLJS.Lg),b);return $CLJS.qe($CLJS.yO.g(a))?$CLJS.Nm.D(c,$CLJS.yO,$CLJS.kf,$CLJS.gW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.AG,$CLJS.P,$CLJS.b0(b)],null))):c},qua=function(a,b){return function e(d){return new $CLJS.yf(null,
function(){for(var f=d;;)if(f=$CLJS.A(f)){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q);$CLJS.F.h($CLJS.h0.g(u),$CLJS.y0)&&(u=$CLJS.hW(u),n.add(u));q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}n=$CLJS.C(f);if($CLJS.F.h($CLJS.h0.g(n),$CLJS.y0))return $CLJS.nf($CLJS.hW(n),e($CLJS.zd(f)));f=$CLJS.zd(f)}else return null},null,null)}($CLJS.a1(a,b,$CLJS.W0(a,b)))},rua=function(a){var b=$CLJS.$P.g($CLJS.C($CLJS.iZ.g(a)));
return $CLJS.m(b)?$CLJS.Rf(w8($CLJS.s0(a),b)):null},sua=function(a){a=$CLJS.Qf(a);var b=$CLJS.M.h(a,$CLJS.DG);return $CLJS.Xf(function(c){return $CLJS.PH(b,c)},$CLJS.UL)},tua=function(a,b){var c=x8(b);return function g(e,f){try{if($CLJS.qe(f)&&3===$CLJS.E(f))try{if($CLJS.F.h($CLJS.IG.g($CLJS.Td(f,1)),c)){var l=$CLJS.Td(f,0);return $CLJS.U.j(f,0,$CLJS.V7.g?$CLJS.V7.g(l):$CLJS.V7.call(null,l))}throw $CLJS.Z;}catch(n){if(n instanceof Error){l=n;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw n;}else throw $CLJS.Z;
}catch(n){if(n instanceof Error){l=n;if(l===$CLJS.Z)return $CLJS.e_(g,e,f);throw l;}throw n;}}($CLJS.Lg,a)},F8=function(a,b){var c=$CLJS.Ai(0,$CLJS.E(E8.h(a,b)));return $CLJS.qg.l(new $CLJS.S(null,6,5,$CLJS.T,[new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.$R],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.HP],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.OZ],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.yO],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.OP],null),new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.QQ],null)],null),function(){return function f(e){return new $CLJS.yf(null,
function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);q.add(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WO,v,$CLJS.yO],null));u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);return $CLJS.nf(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WO,q,$CLJS.yO],null),f($CLJS.zd(g)))}return null}},null,null)}(c)}(),$CLJS.G([function(){return function f(e){return new $CLJS.yf(null,
function(){for(;;){var g=$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u);q.add(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WO,v,$CLJS.lZ],null));u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);return $CLJS.nf(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WO,q,$CLJS.lZ],null),f($CLJS.zd(g)))}return null}},null,null)}(c)}()]))},G8=function(a,b,c){var d=$CLJS.J(c,0,null),e=$CLJS.J(c,1,
null);e=$CLJS.Qf(e);var f=$CLJS.M.h(e,$CLJS.RQ),g=$CLJS.M.h(e,$CLJS.QR),l=$CLJS.J(c,2,null);return $CLJS.C($CLJS.wW(function(n){$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);$CLJS.J(n,0,null);$CLJS.J(n,1,null);n=$CLJS.J(n,2,null);return $CLJS.F.h($CLJS.C(n),d)&&$CLJS.F.h($CLJS.RQ.g($CLJS.Zd(n)),f)&&$CLJS.F.h($CLJS.QR.g($CLJS.Zd(n)),g)&&$CLJS.F.h($CLJS.$d(n),l)},$CLJS.dg($CLJS.Rm,$CLJS.$R.g($CLJS.W0(a,b)))))},uua=function(a,b,c,d){c=G8(a,b,c);return $CLJS.m(c)?$CLJS.X0(a,b,$CLJS.q_,$CLJS.G([new $CLJS.S(null,
4,5,$CLJS.T,[$CLJS.$R,c,2,1],null),$CLJS.Im.h(function(e){return $CLJS.aG($CLJS.$f($CLJS.Cb),e)},$CLJS.cn),d])):a},vua=function(a,b,c){c=G8(a,b,c);return $CLJS.m(c)?$CLJS.X0(a,b,y8,$CLJS.G([new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.$R],null),$CLJS.Ku($CLJS.W0(a,b),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$R,c],null))])):a},J8=function(a,b,c,d,e,f){var g=$CLJS.X0(a,b,f,$CLJS.G([d,e])),l=x8(e);return $CLJS.ki.h(a,g)?$CLJS.C($CLJS.A($CLJS.ug($CLJS.Hb,function(n,q){try{if($CLJS.qe(q)&&1===$CLJS.E(q))try{var u=$CLJS.Td(q,
0);if($CLJS.O(u,$CLJS.QQ))return new $CLJS.S(null,1,5,$CLJS.T,[function(){var I=$CLJS.b0(e);I=H8.R?H8.R(g,b,c,$CLJS.AG,I):H8.call(null,g,b,c,$CLJS.AG,I);return I8.D?I8.D(I,b,c,l):I8.call(null,I,b,c,l)}()],null);throw $CLJS.Z;}catch(I){if(I instanceof Error)if(n=I,n===$CLJS.Z)try{u=$CLJS.Td(q,0);if($CLJS.O(u,$CLJS.OP))return new $CLJS.S(null,1,5,$CLJS.T,[function(){var Q=H8.R?H8.R(g,b,c,$CLJS.OP,l):H8.call(null,g,b,c,$CLJS.OP,l);return I8.D?I8.D(Q,b,c,l):I8.call(null,Q,b,c,l)}()],null);throw $CLJS.Z;
}catch(Q){if(Q instanceof Error){var v=Q;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw Q;}else throw n;else throw I;}else throw $CLJS.Z;}catch(I){if(I instanceof Error)if(n=I,n===$CLJS.Z)try{if($CLJS.qe(q)&&1===$CLJS.E(q))try{var x=$CLJS.Td(q,0);if($CLJS.O(x,$CLJS.HP))return new $CLJS.S(null,1,5,$CLJS.T,[I8.D?I8.D(g,b,c,l):I8.call(null,g,b,c,l)],null);throw $CLJS.Z;}catch(Q){if(Q instanceof Error)if(v=Q,v===$CLJS.Z)try{x=$CLJS.Td(q,0);if($CLJS.O(x,$CLJS.yO))return new $CLJS.S(null,1,5,$CLJS.T,[I8.D?
I8.D(g,b,c,l):I8.call(null,g,b,c,l)],null);throw $CLJS.Z;}catch(Y){if(Y instanceof Error){var y=Y;if(y===$CLJS.Z)throw $CLJS.Z;throw y;}throw Y;}else throw v;else throw Q;}else throw $CLJS.Z;}catch(Q){if(Q instanceof Error)if(v=Q,v===$CLJS.Z)try{if($CLJS.qe(q)&&3===$CLJS.E(q))try{var B=$CLJS.Td(q,0);if($CLJS.O(B,$CLJS.WO))try{var H=$CLJS.Td(q,2);if($CLJS.O(H,$CLJS.yO))return new $CLJS.S(null,1,5,$CLJS.T,[I8.D?I8.D(g,b,c,l):I8.call(null,g,b,c,l)],null);throw $CLJS.Z;}catch(Y){if(Y instanceof Error){y=
Y;if(y===$CLJS.Z)throw $CLJS.Z;throw y;}throw Y;}else throw $CLJS.Z;}catch(Y){if(Y instanceof Error){y=Y;if(y===$CLJS.Z)throw $CLJS.Z;throw y;}throw Y;}else throw $CLJS.Z;}catch(Y){if(Y instanceof Error){y=Y;if(y===$CLJS.Z)return new $CLJS.S(null,1,5,$CLJS.T,[g],null);throw y;}throw Y;}else throw v;else throw Q;}else throw n;else throw I;}}($CLJS.Lg,d)))):g},H8=function(a,b,c,d,e){var f=$CLJS.W0(a,b),g=$CLJS.sg(function(l){var n=$CLJS.Ku(f,l);return $CLJS.m(n)?$CLJS.xe(n)?$CLJS.eg(function(q){return $CLJS.C($CLJS.A($CLJS.ug($CLJS.Hb,
function y(v,x){try{if($CLJS.qe(x)&&3===$CLJS.E(x))try{var B=$CLJS.Td(x,0);if($CLJS.F.h(B,d))try{var H=$CLJS.Td(x,2);if($CLJS.F.h(H,e))return new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[l,q],null)],null);throw $CLJS.Z;}catch(Q){if(Q instanceof Error){var I=Q;if(I===$CLJS.Z)throw $CLJS.Z;throw I;}throw Q;}else throw $CLJS.Z;}catch(Q){if(Q instanceof Error){I=Q;if(I===$CLJS.Z)throw $CLJS.Z;throw I;}throw Q;}else throw $CLJS.Z;}catch(Q){if(Q instanceof Error){I=Q;if(I===$CLJS.Z)return $CLJS.tZ(y,
v,x);throw I;}throw Q;}}($CLJS.Lg,q))))},n):null:null},$CLJS.G([F8(a,b)]));return $CLJS.Sb(function(l,n){var q=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);return J8(l,b,c,q,n,y8)},a,g)},I8=function(a,b,c,d){b=Sta(c,b);if($CLJS.m(b)){var e=$CLJS.W0(c,b);c=$CLJS.Xf(function(f){f=$CLJS.Qf(f);var g=$CLJS.M.h(f,$CLJS.h0),l=$CLJS.M.h(f,$CLJS.F1);return $CLJS.F.h($CLJS.v0,g)&&$CLJS.F.h(d,l)?$CLJS.B0.g(f):null},$CLJS.O5(c,b,e));return $CLJS.m(c)?H8(a,b,a,$CLJS.FQ,c):a}return a},K8=function(a,b,c,d,e){var f=$CLJS.kW.g(c),
g=$CLJS.W0(a,b);c=$CLJS.wW(function(q){var u=$CLJS.Ku(g,q);if($CLJS.m(u)){var v=x8(f);return $CLJS.m($CLJS.Xf($CLJS.Im.j($CLJS.pi([v]),$CLJS.IG,$CLJS.Zd),u))?q:null}return null},F8(a,b));var l=(d=$CLJS.F.h($CLJS.WK,d))?$CLJS.kW.g(e):null;e=d?function(q,u,v){return Rta(q,u,v,l)}:y8;var n=$CLJS.F.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.HP],null),c);a=d&&n&&$CLJS.F.h($CLJS.C(f),$CLJS.C(l))&&$CLJS.F.h($CLJS.$d(f),$CLJS.$d(l))?uua(a,b,f,$CLJS.li($CLJS.Zd(l),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.QR,$CLJS.RQ],
null))):n?vua(a,b,f):a;return $CLJS.m(c)?J8(a,b,a,c,f,e):a},wua=function(a){return $CLJS.Nm.D(a,$CLJS.iZ,$CLJS.kf,new $CLJS.k(null,1,[$CLJS.vz,$CLJS.DZ],null))},xua=function(a){if($CLJS.F.h(1,$CLJS.E($CLJS.iZ.g(a))))throw $CLJS.zj($CLJS.WH("Cannot drop the only stage"),new $CLJS.k(null,1,[$CLJS.iZ,$CLJS.iZ.g(a)],null));return $CLJS.Nm.j(a,$CLJS.iZ,$CLJS.Im.h($CLJS.Mg,$CLJS.ti))},L8=function L8(a){switch(arguments.length){case 2:return L8.h(arguments[0],arguments[1]);case 3:return L8.j(arguments[0],
arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};L8.h=function(a,b){return L8.j(a,null,b)};L8.j=function(a,b,c){b=$CLJS.m(b)?b:-1;c=$CLJS.kW.g(c);return $CLJS.X0(a,b,$CLJS.Nm,$CLJS.G([$CLJS.OZ,$CLJS.cg($CLJS.kf,$CLJS.Lg),c]))};L8.v=3;var M8=function M8(a){switch(arguments.length){case 1:return M8.g(arguments[0]);case 2:return M8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
M8.g=function(a){return M8.h(a,null)};M8.h=function(a,b){return $CLJS.Rf($CLJS.OZ.g($CLJS.W0(a,$CLJS.m(b)?b:-1)))};M8.v=2;var N8=function N8(a){switch(arguments.length){case 1:return N8.g(arguments[0]);case 2:return N8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};N8.g=function(a){return N8.h(a,-1)};
N8.h=function(a,b){var c=$CLJS.W0(a,b);a=$CLJS.O5(a,b,c);return $CLJS.Rf($CLJS.oh.j($CLJS.Lg,$CLJS.Cn(function(d){var e=$CLJS.Rf($CLJS.Mm.h(function(f){return $CLJS.U.j(f,$CLJS.vz,$CLJS.jY)},Vta(d)));return $CLJS.m(e)?$CLJS.U.j(d,$CLJS.r2,e):null}),a))};N8.v=2;var O8=function O8(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return O8.l(arguments[0],arguments[1],2<c.length?new $CLJS.z(c.slice(2),0,null):null)};
O8.l=function(a,b,c){return $CLJS.gW($CLJS.oh.h(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CV.g(a),$CLJS.P,$CLJS.kW.g(b)],null),$CLJS.rg.h($CLJS.kW,c)))};O8.v=2;O8.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};var P8=function P8(a){switch(arguments.length){case 2:return P8.h(arguments[0],arguments[1]);case 3:return P8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
P8.h=function(a,b){return P8.j(a,-1,b)};P8.j=function(a,b,c){var d=$CLJS.Ie(c,$CLJS.xP)?c:dua(a,b,c);return $CLJS.X0(a,b,$CLJS.Nm,$CLJS.G([$CLJS.WO,function(e){return $CLJS.kf.h($CLJS.Mg(e),d)}]))};P8.v=3;var E8=function E8(a){switch(arguments.length){case 1:return E8.g(arguments[0]);case 2:return E8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};E8.g=function(a){return E8.h(a,-1)};
E8.h=function(a,b){return $CLJS.Rf($CLJS.M.h($CLJS.W0(a,b),$CLJS.WO))};E8.v=2;var Q8=function Q8(a){switch(arguments.length){case 1:return Q8.g(arguments[0]);case 2:return Q8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};Q8.g=function(a){return Q8.h(a,-1)};Q8.h=function(a){a=$CLJS.U3($CLJS.s0(a));a=$CLJS.xF.g(a);return t8($CLJS.bg($CLJS.Ie,a),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.yQ,$CLJS.nO,$CLJS.oR,$CLJS.JR],null))};Q8.v=2;
var R8=function R8(a){switch(arguments.length){case 2:return R8.h(arguments[0],arguments[1]);case 3:return R8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};R8.h=function(a,b){return R8.j(a,-1,b)};R8.j=function(a,b){return C8($CLJS.N5(a,b,$CLJS.W0(a,b),new $CLJS.k(null,1,[$CLJS.o1,!1],null)))};R8.v=3;
var S8=function S8(a){switch(arguments.length){case 3:return S8.j(arguments[0],arguments[1],arguments[2]);case 4:return S8.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};S8.j=function(a,b,c){return S8.D(a,-1,b,c)};S8.D=function(a,b,c){return C8($CLJS.N5(a,b,c,new $CLJS.k(null,1,[$CLJS.o1,!1],null)))};S8.v=4;
var T8=function T8(a){switch(arguments.length){case 3:return T8.j(arguments[0],arguments[1],arguments[2]);case 4:return T8.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};T8.j=function(a,b,c){return T8.D(a,-1,b,c)};
T8.D=function(){return new $CLJS.S(null,6,5,$CLJS.T,[D8(),new $CLJS.k(null,3,[$CLJS.vz,$CLJS.jY,$CLJS.CV,$CLJS.gm,$CLJS.UI,$CLJS.WH("Greater than")],null),new $CLJS.k(null,3,[$CLJS.vz,$CLJS.jY,$CLJS.CV,$CLJS.em,$CLJS.UI,$CLJS.WH("Less than")],null),new $CLJS.k(null,3,[$CLJS.vz,$CLJS.jY,$CLJS.CV,$CLJS.pm,$CLJS.UI,$CLJS.WH("Greater than or equal to")],null),new $CLJS.k(null,3,[$CLJS.vz,$CLJS.jY,$CLJS.CV,$CLJS.qm,$CLJS.UI,$CLJS.WH("Less than or equal to")],null),new $CLJS.k(null,3,[$CLJS.vz,$CLJS.jY,
$CLJS.CV,$CLJS.DK,$CLJS.UI,$CLJS.WH("Not equal to")],null)],null)};T8.v=4;var U8=function U8(a){switch(arguments.length){case 2:return U8.h(arguments[0],arguments[1]);case 3:return U8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};U8.h=function(a,b){return U8.j(a,-1,b)};U8.j=function(a,b,c){c=$CLJS.wW($CLJS.j2,$CLJS.O5(a,b,c));return $CLJS.m(c)?(a=iua(a,b,c),$CLJS.m(a)?O8.l(D8(),a,$CLJS.G([c])):null):null};U8.v=3;
var V8=function V8(a){switch(arguments.length){case 2:return V8.h(arguments[0],arguments[1]);case 3:return V8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};V8.h=function(a,b){return V8.j(a,-1,b)};V8.j=function(a,b,c){for(;;)if($CLJS.F.h($CLJS.DH(c),$CLJS.jW))c=$CLJS.hW(c);else return z8(a,b,$CLJS.OP,c)};V8.v=3;
var W8=function W8(a){switch(arguments.length){case 1:return W8.g(arguments[0]);case 2:return W8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};W8.g=function(a){return W8.h(a,-1)};W8.h=function(a,b){return $CLJS.Rf($CLJS.OP.g($CLJS.W0(a,b)))};W8.v=2;
var X8=function X8(a){switch(arguments.length){case 1:return X8.g(arguments[0]);case 2:return X8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};X8.g=function(a){return X8.h(a,-1)};
X8.h=function(a,b){var c=function(){var f=$CLJS.xF.g($CLJS.U3($CLJS.s0(a)));return $CLJS.m(f)?f:$CLJS.oi}(),d=$CLJS.W0(a,b),e=$CLJS.O5(a,b,d);return $CLJS.Rf($CLJS.oh.j($CLJS.Lg,$CLJS.Im.j($CLJS.tg(function(f){f=$CLJS.tW.g(f);var g=null==f;return g?g:c.g?c.g(f):c.call(null,f)}),$CLJS.Cn(function(f){f=$CLJS.Qf(f);var g=$CLJS.M.h(f,$CLJS.pW),l=$CLJS.M.h(f,$CLJS.mW);if($CLJS.Gb(g))return f;if($CLJS.F.h(l,$CLJS.Wt))return $CLJS.U.j(f,$CLJS.e0,e);g=$CLJS.Rf(t8(function(n){return $CLJS.f2(l,n)},e));return $CLJS.m(g)?
$CLJS.U.j(f,$CLJS.e0,g):null}),$CLJS.rg.g(function(f){return $CLJS.U.j(f,$CLJS.vz,$CLJS.oW)})),$CLJS.vW))};X8.v=2;var Y8=function Y8(a){switch(arguments.length){case 1:return Y8.g(arguments[0]);case 2:return Y8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
Y8.g=function(a){if($CLJS.Gb($CLJS.pW.g(a)))return $CLJS.gW(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.CV.g(a),$CLJS.P],null));var b=$CLJS.CV.g(a);b=$CLJS.xa.h?$CLJS.xa.h("aggregation operator %s requires an argument",b):$CLJS.xa.call(null,"aggregation operator %s requires an argument",b);throw $CLJS.zj(b,new $CLJS.k(null,1,[$CLJS.Mqa,a],null));};Y8.h=function(a,b){return $CLJS.gW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CV.g(a),$CLJS.P,$CLJS.kW.g(b)],null))};Y8.v=2;
var Z8=function Z8(a){switch(arguments.length){case 2:return Z8.h(arguments[0],arguments[1]);case 3:return Z8.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};Z8.h=function(a,b){return Z8.j(a,-1,b)};Z8.j=function(a,b,c){c=$CLJS.hW($CLJS.de(c)?c.h?c.h(a,b):c.call(null,a,b):c);return z8(a,b,$CLJS.HP,c)};Z8.v=3;
var $8=function $8(a){switch(arguments.length){case 1:return $8.g(arguments[0]);case 2:return $8.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$8.g=function(a){return $8.h(a,-1)};$8.h=function(a,b){return $CLJS.Rf($CLJS.HP.g($CLJS.W0(a,b)))};$8.v=2;
var a9=function a9(a){switch(arguments.length){case 1:return a9.g(arguments[0]);case 2:return a9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};a9.g=function(a){return a9.h(a,-1)};
a9.h=function(a,b){var c=function(){var f=$CLJS.W0(a,b);return function n(l){return new $CLJS.yf(null,function(){for(;;){var q=$CLJS.A(l);if(q){if($CLJS.re(q)){var u=$CLJS.$c(q),v=$CLJS.E(u),x=$CLJS.Bf(v);a:for(var y=0;;)if(y<v){var B=$CLJS.be(u,y);B=$CLJS.U.j(B,$CLJS.l5,$CLJS.hW(B));x.add(B);y+=1}else{u=!0;break a}return u?$CLJS.Ef($CLJS.Gf(x),n($CLJS.ad(q))):$CLJS.Ef($CLJS.Gf(x),null)}x=$CLJS.C(q);return $CLJS.nf($CLJS.U.j(x,$CLJS.l5,$CLJS.hW(x)),n($CLJS.zd(q)))}return null}},null,null)}($CLJS.O5(a,
b,f))}(),d=$CLJS.oh.j($CLJS.P,$CLJS.Lo(function(f,g){var l=$CLJS.rg.h($CLJS.l5,c);g=$CLJS.R0.j?$CLJS.R0.j(a,g,l):$CLJS.R0.call(null,a,g,l);return $CLJS.m(g)?new $CLJS.S(null,2,5,$CLJS.T,[g,f],null):null}),$8.h(a,b)),e=$CLJS.Rf(c);return null==e?null:$CLJS.oh.j($CLJS.Lg,$CLJS.rg.g(function(f){var g=$CLJS.l5.g(f);g=d.g?d.g(g):d.call(null,g);f=$CLJS.m(g)?$CLJS.U.j(f,$CLJS.k1,g):f;return $CLJS.Am.h(f,$CLJS.l5)}),e)};a9.v=2;
var b9=function b9(a){switch(arguments.length){case 3:return b9.j(arguments[0],arguments[1],arguments[2]);case 4:return b9.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};b9.j=function(a,b,c){return b9.D(a,-1,b,c)};
b9.D=function(a,b,c,d){b=$CLJS.m(b)?b:-1;if($CLJS.m(oua(a,b,c)))throw $CLJS.zj("Expression name conflicts with a column in the same query stage",new $CLJS.k(null,1,[$CLJS.lS,c],null));return $CLJS.X0(a,b,pua,$CLJS.G([$CLJS.b3($CLJS.kW.g(d),c)]))};b9.v=4;var c9=function c9(a){switch(arguments.length){case 1:return c9.g(arguments[0]);case 2:return c9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};c9.g=function(a){return c9.h(a,-1)};
c9.h=function(a,b){return $CLJS.Rf($CLJS.QQ.g($CLJS.W0(a,b)))};c9.v=2;var d9=function d9(a){switch(arguments.length){case 2:return d9.h(arguments[0],arguments[1]);case 3:return d9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};d9.h=function(a,b){return d9.j(a,-1,b)};
d9.j=function(a,b,c){var d=$CLJS.oh.h($CLJS.P,$CLJS.dg(function(f,g){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.b0(g),f],null)},c9.h(a,b))),e=$CLJS.W0(a,b);a=$CLJS.O5(a,b,e);return $CLJS.Rf(t8(function(f){return $CLJS.Gb(c)||$CLJS.ki.h($CLJS.h0.g(f),$CLJS.y0)||$CLJS.M.h(d,$CLJS.V.g(f))<c},a))};d9.v=3;
var e9=function e9(a){switch(arguments.length){case 1:return e9.g(arguments[0]);case 2:return e9.h(arguments[0],arguments[1]);case 3:return e9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};e9.g=function(a){return function(b,c){return e9.j(b,c,a)}};e9.h=function(a,b){return e9.j(a,-1,b)};
e9.j=function(a,b,c){var d=$CLJS.Rf($CLJS.Mm.h($CLJS.hW,c));c=function(){return null==d?null:$CLJS.oh.j(d,$CLJS.Lm.g(function(e){return $CLJS.R0.h?$CLJS.R0.h(e,d):$CLJS.R0.call(null,e,d)}),qua(a,b))}();return $CLJS.X0(a,b,$CLJS.dW,$CLJS.G([$CLJS.yO,c]))};e9.v=3;var f9=function f9(a){switch(arguments.length){case 1:return f9.g(arguments[0]);case 2:return f9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};f9.g=function(a){return f9.h(a,-1)};
f9.h=function(a,b){return $CLJS.yO.g($CLJS.W0(a,b))};f9.v=2;var g9=function g9(a){switch(arguments.length){case 1:return g9.g(arguments[0]);case 2:return g9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};g9.g=function(a){return g9.h(a,-1)};
g9.h=function(a,b){var c=f9.h(a,b),d=$CLJS.je(c)?$CLJS.ag(!0):function(e){var f=$CLJS.hW(e);return $CLJS.ye($CLJS.Xf(function(g){var l=$CLJS.P0.h(f,g);return $CLJS.m(l)?l:$CLJS.P0.h(A8(f),A8(g))},c))};return $CLJS.Mm.h(function(e){return $CLJS.U.j(e,$CLJS.C0,d(e))},$CLJS.N5(a,b,$CLJS.W0(a,b),new $CLJS.k(null,3,[$CLJS.r1,!1,$CLJS.w1,!1,$CLJS.o1,!1],null)))};g9.v=2;
var h9=function h9(a){switch(arguments.length){case 1:return h9.g(arguments[0]);case 2:return h9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};h9.g=function(a){return h9.h(a,$CLJS.GJ)};h9.h=function(a,b){a=$CLJS.U7.g(a);b=$CLJS.m(b)?b:$CLJS.GJ;return $CLJS.U.j($CLJS.Mg(a),0,b)};h9.v=2;
var i9=function i9(a){switch(arguments.length){case 2:return i9.h(arguments[0],arguments[1]);case 3:return i9.j(arguments[0],arguments[1],arguments[2]);case 4:return i9.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};i9.h=function(a,b){return i9.D(a,-1,b,null)};i9.j=function(a,b,c){return i9.D(a,-1,b,c)};
i9.D=function(a,b,c,d){b=$CLJS.m(b)?b:-1;var e=function(){var f=$CLJS.U7.g(c);return $CLJS.m(d)?$CLJS.U.j($CLJS.Mg(f),0,d):f}();return $CLJS.X0(a,b,$CLJS.Nm,$CLJS.G([$CLJS.$R,function(f){return $CLJS.kf.h($CLJS.Mg(f),e)}]))};i9.v=4;var j9=function j9(a){switch(arguments.length){case 1:return j9.g(arguments[0]);case 2:return j9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};j9.g=function(a){return j9.h(a,-1)};
j9.h=function(a,b){return $CLJS.Rf($CLJS.M.h($CLJS.W0(a,b),$CLJS.$R))};j9.v=2;var k9=function k9(a){switch(arguments.length){case 1:return k9.g(arguments[0]);case 2:return k9.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};k9.g=function(a){return k9.h(a,-1)};
k9.h=function(a,b){function c(g){return $CLJS.Xf(function(l){var n=$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);var q=$CLJS.hW(g),u=$CLJS.P0.h(q,l);l=$CLJS.m(u)?u:$CLJS.P0.h(q,A8(l));return $CLJS.m(l)?n:null},d)}var d=$CLJS.dg(function(g,l){$CLJS.J(l,0,null);$CLJS.J(l,1,null);l=$CLJS.J(l,2,null);return new $CLJS.S(null,2,5,$CLJS.T,[g,l],null)},j9.h(a,b)),e=$CLJS.Rf($CLJS.c8(a,b)),f=$CLJS.Rf($CLJS.g8.h(a,b));e=$CLJS.m($CLJS.m(e)?e:f)?$CLJS.qg.h(e,f):function(){var g=$CLJS.W0(a,b);return $CLJS.O5(a,b,g)}();
e=$CLJS.Rf(e);return null==e?null:$CLJS.oh.j($CLJS.Lg,$CLJS.Im.h($CLJS.tg(sua),$CLJS.rg.g(function(g){var l=c(g);return $CLJS.m(l)?$CLJS.U.j(g,$CLJS.l1,l):g})),e)};k9.v=2;var l9=function l9(a){switch(arguments.length){case 2:return l9.h(arguments[0],arguments[1]);case 3:return l9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};l9.h=function(a,b){return l9.j(a,-1,b)};l9.j=function(a,b,c){return K8(a,b,c,$CLJS.ota,null)};
l9.v=3;var m9=function m9(a){switch(arguments.length){case 3:return m9.j(arguments[0],arguments[1],arguments[2]);case 4:return m9.D(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};m9.j=function(a,b,c){return m9.D(a,-1,b,c)};m9.D=function(a,b,c,d){return K8(a,b,c,$CLJS.WK,d)};m9.v=4;$CLJS.yua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(V8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.n9=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Y8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.zua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(mua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Aua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(W8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Bua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(X8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Cua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(nua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.o9=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.b6,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Dua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.Q5,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Eua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.b8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Fua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Z8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Gua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(a9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Hua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Iua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(lua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Jua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(kua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Kua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Qta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Lua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(b9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Mua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(c9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Nua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(d9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Oua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(f9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Pua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(e9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Qua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(g9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Rua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(L8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Sua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(M8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Tua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(N8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Uua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Wta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Vua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(O8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Wua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Q8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Xua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(P8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Yua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.s8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Zua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(R8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.$ua=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(T8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.ava=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(S8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.bva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(eua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.cva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(fua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.dva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(gua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.eva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(E8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.fva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(U8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.gva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.r8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.hva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(hua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.iva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.q8,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.jva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.e1,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.kva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Tta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.lva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(rua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.mva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.hta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.nva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.ita,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.ova=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.Q7,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.pva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.R7,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.qva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(tua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.rva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(i9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.sva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(h9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.tva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(j9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.uva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(k9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.p9=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.T3,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.vva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.p5,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.wva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.hW,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.xva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(l9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.yva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(m9,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.zva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(wua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Ava=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(xua,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Bva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.G1,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Cva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.o2,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Dva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h(Uta,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.q9=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.a6,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Eva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.$7,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();
$CLJS.Fva=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.z(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.R.h($CLJS.Z7,c)}a.v=0;a.B=function(c){c=$CLJS.A(c);return b(c)};a.l=b;return a}();