import { t } from "ttag";
import { WidgetSettings } from "metabase-types/api/widgets/widget";

type FunnelSettingsKeys = "graph.legend_position" | "funnel.type";

export type FunnelSettings = WidgetSettings<FunnelSettingsKeys>;

export const FUNNEL_SETTINGS: FunnelSettings = {
  "graph.legend_position": {
    section: t`Display`,
    title: t`Legend position`,
    widget: "segmentedControl",
    props: {
      options: [
        { name: t`Top`, value: "top" },
        { name: t`Bottom`, value: "bottom" },
        { name: t`Right`, value: "right" },
        { name: t`Left`, value: "left" },
      ],
    },
    default: "top",
    getHidden: (series, vizSettings) => vizSettings["funnel.type"] === "funnel",
  },
  "funnel.type": {
    title: t`Funnel type`,
    section: t`Display`,
    widget: "select",
    props: {
      options: [
        { name: t`Funnel`, value: "funnel" },
        { name: t`Bar chart`, value: "bar" },
      ],
    },
    getDefault: series => (series.length > 1 ? "bar" : "funnel"),
    useRawSeries: true,
  },
};
