var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var Cla,Dla,Ela,Fla,Gla,Hla,Ila,sW,Jla,Kla,Lla,Mla,Nla,Ola,Pla,Qla,uW,Rla;$CLJS.mW=new $CLJS.N(null,"supported-field","supported-field",-2061545519);Cla=new $CLJS.N("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.nW=new $CLJS.N("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Dla=new $CLJS.N("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Ela=new $CLJS.N("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.oW=new $CLJS.N("mbql.aggregation","operator","mbql.aggregation/operator",-1481602310);$CLJS.pW=new $CLJS.N(null,"requires-column?","requires-column?",1623131448);$CLJS.qW=new $CLJS.N(null,"column-name","column-name",551523580);Fla=new $CLJS.N("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.rW=new $CLJS.N(null,"display-info","display-info",-816930907);
Gla=new $CLJS.N("mbql.clause","max","mbql.clause/max",1615385533);Hla=new $CLJS.N("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);Ila=new $CLJS.N("mbql.clause","median","mbql.clause/median",-1026386338);sW=new $CLJS.N("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);Jla=new $CLJS.N("mbql.clause","min","mbql.clause/min",2074448531);Kla=new $CLJS.N("mbql.clause","percentile","mbql.clause/percentile",2064253651);
Lla=new $CLJS.N("mbql.clause","stddev","mbql.clause/stddev",659381076);Mla=new $CLJS.N("mbql.clause","var","mbql.clause/var",809434920);Nla=new $CLJS.N("mbql.clause","share","mbql.clause/share",-37921592);Ola=new $CLJS.N("mbql.clause","count","mbql.clause/count",-454225910);Pla=new $CLJS.N("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.tW=new $CLJS.N(null,"driver-feature","driver-feature",1905324248);Qla=new $CLJS.N("mbql.clause","avg","mbql.clause/avg",1826863173);
uW=new $CLJS.N("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);Rla=new $CLJS.N("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.AV($CLJS.lD,$CLJS.G([$CLJS.yu,$CLJS.fl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AG,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.LL],null)],null)],null)],null)]));$CLJS.AV($CLJS.NJ,$CLJS.G([$CLJS.yu,$CLJS.fl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.AG,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.LL],null)],null)],null)],null)]));
$CLJS.yV($CLJS.DJ,$CLJS.G([$CLJS.yu,$CLJS.LK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null)]));$CLJS.yV($CLJS.NI,$CLJS.G([$CLJS.yu,$CLJS.fl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.LL],null)],null)]));$CLJS.yV($CLJS.fK,$CLJS.G([$CLJS.yu,$CLJS.fl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.KL],null)],null)]));
$CLJS.yV($CLJS.Vl,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.QL],null)],null)]));$CLJS.nV($CLJS.Vl,$CLJS.PL);$CLJS.yV($CLJS.vK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null)]));$CLJS.nV($CLJS.vK,$CLJS.PL);$CLJS.yV($CLJS.ok,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.QL],null)],null)]));$CLJS.nV($CLJS.ok,$CLJS.PL);
$CLJS.kL(sW,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lj,new $CLJS.k(null,1,[$CLJS.il,"valid percentile"],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Jt,new $CLJS.k(null,1,[$CLJS.il,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.yV($CLJS.nJ,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,sW],null)]));$CLJS.nV($CLJS.nJ,$CLJS.PL);
$CLJS.yV($CLJS.ZJ,$CLJS.G([$CLJS.yu,$CLJS.LK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.KL],null)],null)]));$CLJS.yV($CLJS.AK,$CLJS.G([$CLJS.yu,$CLJS.LK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null)]));$CLJS.yV($CLJS.tK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null)]));
$CLJS.yV($CLJS.RK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null)]));$CLJS.nV($CLJS.tK,$CLJS.PL);$CLJS.nV($CLJS.RK,$CLJS.PL);$CLJS.yV($CLJS.OK,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.KL],null)],null)]));$CLJS.nV($CLJS.OK,$CLJS.PL);
$CLJS.yV($CLJS.RI,$CLJS.G([$CLJS.yu,$CLJS.LK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.$l,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,$CLJS.SL],null)],null)]));$CLJS.kL(uW,new $CLJS.S(null,17,5,$CLJS.T,[$CLJS.zk,Qla,Ola,Hla,Dla,Cla,Gla,Ila,Jla,Kla,Nla,Lla,Rla,Pla,Ela,Mla,$CLJS.Lb],null));$CLJS.kL($CLJS.nW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kl,new $CLJS.k(null,1,[$CLJS.ok,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lq,uW],null)],null));
$CLJS.vW=new $CLJS.S(null,10,5,$CLJS.T,[new $CLJS.k(null,4,[$CLJS.CV,$CLJS.lD,$CLJS.pW,!1,$CLJS.tW,$CLJS.gI,$CLJS.rW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Count of rows"),$CLJS.qW,$CLJS.WH("Count"),$CLJS.XG,$CLJS.WH("Total number of rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.tK,$CLJS.mW,$CLJS.Sl,$CLJS.pW,!0,$CLJS.tW,$CLJS.gI,$CLJS.rW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Sum of ..."),$CLJS.qW,$CLJS.WH("Sum"),$CLJS.XG,$CLJS.WH("Sum of all the values of a column.")],
null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.DJ,$CLJS.mW,$CLJS.Sl,$CLJS.pW,!0,$CLJS.tW,$CLJS.gI,$CLJS.rW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Average of ..."),$CLJS.qW,$CLJS.WH("Average"),$CLJS.XG,$CLJS.WH("Average of all the values of a column")],null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.vK,$CLJS.mW,$CLJS.Sl,$CLJS.pW,!0,$CLJS.tW,$CLJS.FJ,$CLJS.rW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Median of ..."),$CLJS.qW,$CLJS.WH("Median"),$CLJS.XG,$CLJS.WH("Median of all the values of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.NI,$CLJS.mW,$CLJS.Wt,$CLJS.pW,!0,$CLJS.tW,$CLJS.gI,$CLJS.rW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Number of distinct values of ..."),$CLJS.qW,$CLJS.WH("Distinct values"),$CLJS.XG,$CLJS.WH("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.RK,$CLJS.mW,$CLJS.Sl,$CLJS.pW,!0,$CLJS.tW,$CLJS.gI,$CLJS.rW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Cumulative sum of ..."),
$CLJS.qW,$CLJS.WH("Sum"),$CLJS.XG,$CLJS.WH("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.k(null,4,[$CLJS.CV,$CLJS.NJ,$CLJS.pW,!1,$CLJS.tW,$CLJS.gI,$CLJS.rW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Cumulative count of rows"),$CLJS.qW,$CLJS.WH("Count"),$CLJS.XG,$CLJS.WH("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.AK,$CLJS.mW,$CLJS.Sl,$CLJS.pW,
!0,$CLJS.tW,$CLJS.lK,$CLJS.rW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Standard deviation of ..."),$CLJS.qW,$CLJS.WH("SD"),$CLJS.XG,$CLJS.WH("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.ok,$CLJS.mW,$CLJS.Dk,$CLJS.pW,!0,$CLJS.tW,$CLJS.gI,$CLJS.rW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Minimum of ..."),$CLJS.qW,$CLJS.WH("Min"),$CLJS.XG,$CLJS.WH("Minimum value of a column")],
null)}],null),new $CLJS.k(null,5,[$CLJS.CV,$CLJS.Vl,$CLJS.mW,$CLJS.Dk,$CLJS.pW,!0,$CLJS.tW,$CLJS.gI,$CLJS.rW,function(){return new $CLJS.k(null,3,[$CLJS.UI,$CLJS.WH("Maximum of ..."),$CLJS.qW,$CLJS.WH("Max"),$CLJS.XG,$CLJS.WH("Maximum value of a column")],null)}],null)],null);
$CLJS.kL(Fla,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.dm,$CLJS.oW],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.CV,$CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Yl],null),$CLJS.rg.g($CLJS.CV),$CLJS.vW)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.mW,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Lt,$CLJS.Ck],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.pW,$CLJS.ik],null),new $CLJS.S(null,2,
5,$CLJS.T,[$CLJS.tW,$CLJS.Ck],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.rW,$CLJS.de],null)],null));